// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section[data-testid="add-issues-dialog"] {
    min-width: 540px;
    max-width: 1512px;
    min-height: 400px;
    max-height: 962px;
}`, "",{"version":3,"sources":["webpack://./src/components/backlog/add-issues-dialog.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;AACrB","sourcesContent":["section[data-testid=\"add-issues-dialog\"] {\n    min-width: 540px;\n    max-width: 1512px;\n    min-height: 400px;\n    max-height: 962px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
