import React from "react";

export function FileIcon() {
  return (
    <svg width="48" height="64" viewBox="0 0 48 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2699_111288)">
        <rect width="48" height="64" fill="white" fillOpacity="0.01" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 0.5H32.007C32.4775 0.500118 32.9432 0.595111 33.3762 0.779302C33.8092 0.963492 34.2006 1.2331 34.527 1.572L46.521 14.022C47.1494 14.6745 47.5003 15.5452 47.5 16.451V60C47.5 60.9283 47.1312 61.8185 46.4749 62.4749C45.8185 63.1312 44.9283 63.5 44 63.5H4C3.07174 63.5 2.1815 63.1312 1.52513 62.4749C0.868749 61.8185 0.5 60.9283 0.5 60V4C0.5 3.07174 0.868749 2.1815 1.52513 1.52513C2.1815 0.868749 3.07174 0.5 4 0.5Z"
          fill="white"
          stroke="#091E43"
          strokeOpacity="0.14"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 22.667H25.528C25.7919 22.6668 26.0533 22.7188 26.297 22.8201C26.5407 22.9213 26.7619 23.0698 26.948 23.257L30.855 27.195C30.9479 27.2886 31 27.4151 31 27.547V29.167C31 29.2996 30.9473 29.4268 30.8536 29.5205C30.7598 29.6143 30.6326 29.667 30.5 29.667H26C25.4696 29.667 24.9609 29.4563 24.5858 29.0812C24.2107 28.7061 24 28.1974 24 27.667V25H19.333V39H28.667V30.84H31V39C31.0001 39.3064 30.9399 39.6099 30.8227 39.8931C30.7055 40.1763 30.5337 40.4336 30.317 40.6503C30.1004 40.8671 29.8432 41.039 29.56 41.1563C29.2769 41.2736 28.9735 41.334 28.667 41.334H19.333C19.0265 41.334 18.7231 41.2736 18.44 41.1563C18.1569 41.039 17.8996 40.8671 17.683 40.6503C17.4663 40.4336 17.2945 40.1763 17.1773 39.8931C17.0601 39.6099 16.9999 39.3064 17 39V25C17 24.3812 17.2458 23.7878 17.6833 23.3503C18.1208 22.9128 18.7143 22.667 19.333 22.667H24Z"
          fill="#44546F"
        />
      </g>
      <defs>
        <clipPath id="clip0_2699_111288">
          <rect width="48" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
