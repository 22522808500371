import React from "react";

import { ChangelogEntry } from "./ChangelogEntry";
import { ChangelogItem } from "./index";

const date = new Date("2024-11-12");

function November2024() {
  return (
    <ChangelogEntry title="November 2024 update: Enhanced backlog management" date={date}>
      <p>We’ve added new features to make it easier to add and organize items in your Planning Poker game backlog:</p>
      <br />
      <h5>&#x2795; Quick add</h5>
      <p>
        You can now quickly add individual items to the game backlog using the new Add issue to the backlog option. This
        update helps you manage your backlog more efficiently by letting you add additional items with a single click.
      </p>
      <h5>&#x1F504; Sort and organize</h5>
      <p>
        Our new Order by option lets you sort items in your game backlog columns by variables like Jira rank, priority,
        and status.
      </p>
      <br />
      <p>
        For more details, please refer to the{" "}
        <a
          href="https://appfire.atlassian.net/wiki/spaces/PP/pages/1450869099/Release+notes+November+2024"
          rel="noreferrer"
          target="_blank"
        >
          release notes.
        </a>{" "}
        Happy planning and estimating!
      </p>
    </ChangelogEntry>
  );
}

export default {
  Component: November2024,
  date,
} as ChangelogItem;
