enum EVENT_CATEGORY {
  USE = "use",
  CONFIGURE = "configure",
}

export const EVENT_CATEGORIES_MAPPING: Record<string, EVENT_CATEGORY> = {
  VoteEdited: EVENT_CATEGORY.USE,
  ReplayClicked: EVENT_CATEGORY.USE,
  PersonalVoteProvided: EVENT_CATEGORY.USE,
  NewGameClicked: EVENT_CATEGORY.USE,
  IssueSelected: EVENT_CATEGORY.USE,
  GameReopened: EVENT_CATEGORY.USE,
  GameRemoved: EVENT_CATEGORY.USE,
  GameJoined: EVENT_CATEGORY.USE,
  GameFinished: EVENT_CATEGORY.USE,
  GameCloned: EVENT_CATEGORY.USE,
  FinalEstimateSaved: EVENT_CATEGORY.USE,
  EstimateTabOpened: EVENT_CATEGORY.USE,
  EstimateTabClicked: EVENT_CATEGORY.USE,
  EditIssueClicked: EVENT_CATEGORY.USE,
  DashboardOpened: EVENT_CATEGORY.USE,
  CardsRevealed: EVENT_CATEGORY.USE,
  BacklogEdited: EVENT_CATEGORY.USE,
  StartGameClicked: EVENT_CATEGORY.USE,
  GameEdited: EVENT_CATEGORY.USE,
  GlobalConfigOpened: EVENT_CATEGORY.CONFIGURE,
  GlobalConfigChanged: EVENT_CATEGORY.CONFIGURE,
  AsyncConfigChanged: EVENT_CATEGORY.CONFIGURE,
};
