import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { GameState, ItemsBacklogProps } from "@appfire/poker-core";
import Tooltip from "@atlaskit/tooltip";
import { AkEditorInfoIcon } from "@fuegokit/fuegoicons-react";
import { Box, Heading } from "@fuegokit/react";

import { IssueSelected, IssuesEstimatedHidden } from "../../../analytics/ampli";
import { useRecordEvent } from "../../../analytics/hooks/events/useRecordEvent";
import { useApplicationContext } from "../../../providers/ApplicationContextProvider";
import { useJiraDataStore } from "../../../providers/JiraDataStoreProvider";
import { useUpdateGameConfiguration } from "../../../services/firebase";
import { JiraIssue } from "../../../types";
import { buildJQLClause, DEFAULT_COLUMNS, FIELD } from "../../../utils/backlog";
import { AddIssuesDialog } from "../../backlog/AddIssuesDialog";
import { createColumn } from "../../backlog/headers";
import { IssuesTable } from "../../backlog/IssuesTable";
import { QuickAddIssueInput } from "../../backlog/QuickAddIssueInput";
import { SwitchElement } from "../../shared/SwitchElement";
import { useGameIssues } from "../GameIssuesProvider";
import { useGameData } from "../GameProvider";
import { EstimatedIssuesHeader } from "./EstimatedIssuesHeader";

const HeaderContainer = styled.div`
  padding-bottom: 12px;
  padding-left: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export function GameBacklog({ items, activeItem, selectActiveItem }: ItemsBacklogProps<JiraIssue>) {
  const { project, statuses } = useApplicationContext();
  const { isLoading } = useGameIssues();
  const { game, gameId, isGameAdmin } = useGameData();
  const { estimatedIssues } = useGameIssues();
  const [rowSelection, setRowSelection] = useState({});
  const { fields } = useJiraDataStore();
  const backlog = useMemo(() => game.configuration.backlog || [], [game.configuration.backlog]);
  const [jql, setJql] = useState(game.configuration.jql || buildJQLClause(project?.name, statuses));
  const recordIssueSelectedEvent = useRecordEvent(IssueSelected);
  const recordHideIssuesToggledEvent = useRecordEvent(IssuesEstimatedHidden);

  const updateGameConfig = useUpdateGameConfiguration(gameId);
  const isGameFinished = useMemo(() => {
    return game.state === GameState.FINISHED;
  }, [game.state]);

  const columns = useMemo(() => {
    const columnsIds = [
      FIELD.GAME_ESTIMATION,
      ...DEFAULT_COLUMNS.filter((column) => column !== FIELD.ESTIMATE),
      ...(game.configuration.backlogColumnIds ?? []),
    ];
    return [
      ...columnsIds.map((columnId) =>
        createColumn({
          columnId,
          fields: fields!,
          estimationFieldId: game.configuration.estimationFieldId,
          isLoading,
        }),
      ),
    ];
  }, [fields, game.configuration.backlogColumnIds, game.configuration.estimationFieldId, isLoading]);

  const onAddMoreIssues = useCallback(
    (newIssues: string[]) => {
      void updateGameConfig({ backlog: [...backlog, ...newIssues], jql });
    },
    [backlog, updateGameConfig, jql],
  );

  const addIssuesDialogColumnsIds = useMemo(
    () => [...DEFAULT_COLUMNS, ...(game.configuration.backlogColumnIds ?? [])],
    // Stringified to prevent unnecessary updates connected to Firebase object reference changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(game.configuration.backlogColumnIds)],
  );

  const onIssueClick = useCallback(
    (item: JiraIssue) => {
      if (isGameAdmin && !isGameFinished) {
        void selectActiveItem(item);
        recordIssueSelectedEvent({ game_id: gameId, issue_id: item.id });
      }
    },
    [isGameAdmin, isGameFinished, selectActiveItem, recordIssueSelectedEvent, gameId],
  );

  useEffect(() => setRowSelection({ [String(game.activeItemId)]: true }), [game.activeItemId]);

  const hideEstimatedIssues = useMemo(
    () => Boolean(game.configuration.hideEstimatedIssues),
    [game.configuration.hideEstimatedIssues],
  );
  const toggleHideEstimatedIssues = useCallback(() => {
    const areEstimatedIssuesHidden = !hideEstimatedIssues;
    void updateGameConfig({ hideEstimatedIssues: areEstimatedIssuesHidden });
    recordHideIssuesToggledEvent({ estimated_hidden: areEstimatedIssuesHidden });

    if (!activeItem || !estimatedIssues[activeItem.key]) return;

    const firstUnestimatedItem = items.find((item) => !estimatedIssues[item.key]);
    if (firstUnestimatedItem) {
      selectActiveItem(firstUnestimatedItem);
    }
  }, [
    activeItem,
    estimatedIssues,
    hideEstimatedIssues,
    items,
    recordHideIssuesToggledEvent,
    selectActiveItem,
    updateGameConfig,
  ]);

  return (
    <div>
      <HeaderContainer>
        <Box display="flex" alignItems="center">
          <Heading variant="h500">Backlog</Heading>
          <EstimatedIssuesHeader />
          <Box display="flex" alignItems="center" ml={2}>
            <SwitchElement
              id="hide-estimated-issues-switch"
              label="Hide estimated"
              value={hideEstimatedIssues}
              onToggle={toggleHideEstimatedIssues}
            />
            <Tooltip content="Hide issues estimated during this game" position="top">
              <AkEditorInfoIcon />
            </Tooltip>
          </Box>
        </Box>
        {isGameAdmin && !isGameFinished && (
          <Box
            display="flex"
            sx={{
              gap: 2,
            }}
          >
            <QuickAddIssueInput
              columnsIds={addIssuesDialogColumnsIds}
              backlog={backlog}
              addIssueToBacklog={(issueKey) => onAddMoreIssues([issueKey])}
            />
            <AddIssuesDialog
              appearance={"default"}
              addMoreIssues={onAddMoreIssues}
              disabledIssueKeys={backlog}
              estimationFieldId={game.configuration.estimationFieldId}
              columnsIds={addIssuesDialogColumnsIds}
              jql={jql}
              onJqlChange={setJql}
            />
          </Box>
        )}
      </HeaderContainer>
      <IssuesTable
        isLoading={isLoading}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        columns={columns}
        issues={items}
        disabledItemIds={[]}
        fields={fields!}
        onIssueClick={onIssueClick}
        isWindowVirtualizer
      />
    </div>
  );
}
