import React, { useCallback, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { IconButton } from "@atlaskit/button/new";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import DocumentsIcon from "@atlaskit/icon/glyph/documents";
import FeedbackIcon from "@atlaskit/icon/glyph/feedback";
import NotificationAllIcon from "@atlaskit/icon/glyph/notification-all";
import QuestionIcon from "@atlaskit/icon/glyph/question";
import { ModalTransition } from "@atlaskit/modal-dialog";
import { Box, Text } from "@fuegokit/react";

import { useApplicationContext } from "../providers/ApplicationContextProvider";
import { useShouldShowChangelog } from "../utils/changelog";
import { getSupportURL } from "../utils/feedback";
import { ChangelogModal } from "./changlog/ChangelogModal";

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 4px 0;
`;

function Dropdown() {
  const { hostBaseUrl, addonKey } = useApplicationContext();

  const openSupportUrl = useCallback(() => {
    void getSupportURL(addonKey, hostBaseUrl).then((supportURL) =>
      window.open(supportURL, "_blank", "noreferrer noopener"),
    );
  }, [addonKey, hostBaseUrl]);

  const navigate = useNavigate();
  const navigateToChangelogModal = useCallback(() => navigate("/changelog"), [navigate]);

  const getShouldShowChangelog = useShouldShowChangelog();
  useEffect(() => {
    void (async () => {
      const shouldShowChangelog = await getShouldShowChangelog();

      if (shouldShowChangelog) {
        navigateToChangelogModal();
      }
    })();
  }, [getShouldShowChangelog, navigateToChangelogModal]);

  return (
    <>
      <DropdownMenu<HTMLButtonElement>
        placement="bottom-end"
        trigger={({ triggerRef, ...props }) => (
          <IconButton {...props} icon={QuestionIcon} appearance="subtle" label="Menu" ref={triggerRef} />
        )}
      >
        <DropdownItemGroup>
          <DropdownItem elemBefore={<FeedbackIcon label="Contact us" size="medium" />} onClick={openSupportUrl}>
            Contact us
          </DropdownItem>
          <DropdownItem
            elemBefore={<NotificationAllIcon label="What's new" size="medium" />}
            onClick={navigateToChangelogModal}
          >
            What&apos;s new
          </DropdownItem>
          <DropdownItem
            href="https://apps.appf.re/poker/doc/intro"
            rel="noopener noreferrer"
            target="_blank"
            elemBefore={<DocumentsIcon label="DocumentsIcon" size="medium" />}
          >
            Documentation
          </DropdownItem>
        </DropdownItemGroup>
      </DropdownMenu>
      <ModalTransition>
        <Routes>
          <Route path="/changelog" element={<ChangelogModal />} />
        </Routes>
      </ModalTransition>
    </>
  );
}

export function Header() {
  return (
    <Box
      display="flex"
      marginBottom={3}
      justifyContent="space-between"
      borderBottom="1px solid"
      borderColor="border.default"
    >
      <Box>
        <LogoContainer className="header">
          <img src="/icon/planning-poker-logo.png" width="28px"></img>
          <Text
            sx={{
              fontSize: 3,
              color: "text.default",
              fontWeight: "semibold",
            }}
          >
            Planning Poker
          </Text>
        </LogoContainer>
      </Box>
      <Dropdown />
    </Box>
  );
}
