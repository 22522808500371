import React, { useEffect, useState } from "react";

import { getJiraContext } from "../../../services/jira-api";
import { getErrorMessage } from "../../../utils";
import { ErrorMessage } from "../ErrorMessage";

function useDisplayUrl() {
  const [displayUrl, setDisplayUrl] = useState<string>("");

  useEffect(() => {
    getJiraContext()
      .then((context) => setDisplayUrl(context.url.displayUrl))
      .catch((error) => console.error("Failed to get Jira context " + getErrorMessage(error)));
  });

  return displayUrl;
}

export function UnlicensedMessage() {
  const displayUrl = useDisplayUrl();
  const addonKey =
    process.env.ENVIRONMENT === "staging" ? "agile.estimation.3.0_private_staging" : "agile.estimation.3.0_private";
  const umpLink = `${displayUrl}/plugins/servlet/upm#manage/${addonKey}`;
  return (
    <ErrorMessage
      title="Invalid License"
      text={
        <div>
          We can’t validate your Planning Poker app license. Please check
          <a href={umpLink} target="_top">
            <strong> Manage apps </strong>
          </a>
          screen if the license key is correct. <br />
          If you do not have a license yet you can buy one or start a free 30 day trial. <br />
        </div>
      }
    />
  );
}
