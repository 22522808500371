import React from "react";
import styled from "styled-components";

import { EstimationHeaderProps, ItemState } from "@appfire/poker-core";

import { JiraIssue } from "../../../types";
import { CommonActions } from "./CommonAdminActions";
import { FinalEstimationActions } from "./FinalEstimationAdminActions";
import { PersonalEstimationActions } from "./PersonalEstimationAdminActions";

const AdminActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 8px;
`;

export function AdminActions({
  revealVotes,
  nextItemOrFinish,
  removeActiveItem,
  votedParticipantsCount,
  isLastItem,
  activeItemState,
  finalEstimate,
  setFinalEstimate,
  saveFinalEstimate,
  resetVotes,
  activeItem,
}: Readonly<EstimationHeaderProps<JiraIssue>>) {
  return (
    <AdminActionsContainer>
      {activeItemState === ItemState.PERSONAL_ESTIMATION ? (
        <PersonalEstimationActions revealVotes={revealVotes} votedParticipantsCount={votedParticipantsCount} />
      ) : (
        <FinalEstimationActions
          finalEstimate={finalEstimate}
          setFinalEstimate={setFinalEstimate}
          saveFinalEstimate={saveFinalEstimate}
          resetVotes={resetVotes}
          activeItem={activeItem}
        />
      )}
      <CommonActions
        nextItemOrFinish={nextItemOrFinish}
        removeActiveItem={removeActiveItem}
        isLastItem={isLastItem}
        activeItem={activeItem}
      />
    </AdminActionsContainer>
  );
}
