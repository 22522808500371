import React from "react";
import styled from "styled-components";

import Avatar from "@atlaskit/avatar";
import Tooltip from "@atlaskit/tooltip";
import { AkCheckIcon, AkRbTimeIcon } from "@fuegokit/fuegoicons-react";
import { Box } from "@fuegokit/react";

import "./cards.css";

import { UserData } from "../../../types/common";

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 3px;
  padding: 0;
  padding-bottom: 0;
  margin-bottom: 15px;
  box-sizing: border-box;

  height: 100%;
  width: 26px;
  position: relative;
  overflow: hidden;
  gap: 8px;
`;

interface UnrevealedCardProps {
  className?: string;
  participantDetails?: UserData;
  voted: boolean;
}

export function UnrevealedCard({ participantDetails, voted, className }: Readonly<UnrevealedCardProps>) {
  return (
    <Box className={className}>
      <div className="card-inner">
        <Card>
          {participantDetails && (
            <Tooltip content={<span data-private>{participantDetails.displayName}</span>} position="top">
              <span data-private>
                <Avatar src={participantDetails.avatarUrl} size="small" />
              </span>
            </Tooltip>
          )}

          {voted ? <AkCheckIcon size={24} /> : <AkRbTimeIcon size={24} />}
        </Card>
      </div>
    </Box>
  );
}
