import React from "react";

import { Checkbox } from "@atlaskit/checkbox";
import Skeleton from "@atlaskit/skeleton";
import Tooltip from "@atlaskit/tooltip";
import { createColumnHelper } from "@tanstack/react-table";

import { JiraField, JiraIssue } from "../../types";
import { upperCaseFirstLetter } from "../../utils";
import { CUSTOM_HEADERS_TITLES, FIELD } from "../../utils/backlog";
import { CUSTOM_RENDERERS, FieldRenderer } from "./FieldRenderer";

const columnHelper = createColumnHelper<JiraIssue>();

function Loader({
  children,
  loading,
  size,
}: React.PropsWithChildren<{
  loading: boolean;
  size?: number;
}>) {
  const width = size ? `${size}px` : "100%";
  return <>{loading ? <Skeleton borderRadius={3} height="20px" width={width} isShimmering /> : children}</>;
}

const getSelectedRowsModel = (ids: string[]) =>
  ids.reduce<Record<string, boolean>>((ids, key) => {
    ids[key] = true;
    return ids;
  }, {});

export const getCheckboxColumn = ({
  isLoading,
  size = 40,
  disabledItemIds = [],
  disabledTooltipMessage = "",
  hideCheckbox = false,
}: {
  isLoading: boolean;
  size?: number;
  disabledItemIds: string[];
  disabledTooltipMessage?: string;
  hideCheckbox?: boolean;
}) => {
  return columnHelper.display({
    id: "select",
    size,
    header: ({ table }) => {
      const { rows, rowsById } = table.getRowModel();
      const { getIsAllRowsSelected, setRowSelection } = table;
      const allDisabledSelected = rows.every((r) => disabledItemIds.includes(r.id)) && rows.length > 0;
      const isSomeSelected = Object.keys(table.getState().rowSelection).length > 0;
      const onToggleAllRowsHandler = () => {
        if (getIsAllRowsSelected()) {
          setRowSelection((prev) => getSelectedRowsModel(Object.keys(prev).filter((id) => !rowsById[id])));
        } else {
          setRowSelection((prev) => ({
            ...prev,
            ...getSelectedRowsModel(rows.map((r) => r.id).filter((id) => !disabledItemIds.includes(id))),
          }));
        }
      };

      return (
        <Tooltip
          content={!isSomeSelected && hideCheckbox ? "Check to remove issues from game backlog" : ""}
          position="left"
        >
          <Checkbox
            isChecked={isSomeSelected || table.getIsAllRowsSelected() || allDisabledSelected}
            isIndeterminate={isSomeSelected && !table.getIsAllRowsSelected()}
            isDisabled={isLoading || allDisabledSelected}
            onChange={onToggleAllRowsHandler}
            testId="select-all-checkbox"
          />
        </Tooltip>
      );
    },
    cell: ({ row }) => {
      const isDisabled = disabledItemIds.includes(row.id);
      return (
        <div className={hideCheckbox ? "hoverable-checkbox" : ""}>
          <Loader size={size} loading={isLoading}>
            <Tooltip
              content={
                isDisabled ? disabledTooltipMessage : hideCheckbox ? "Check to remove this issue from game backlog" : ""
              }
              position="left"
            >
              <Checkbox
                isChecked={row.getIsSelected() || isDisabled}
                onChange={row.getToggleSelectedHandler()}
                isDisabled={!row.getCanSelect()}
              />
            </Tooltip>
          </Loader>
        </div>
      );
    },
  });
};

export function generateHeaderContent(columnId: string, field?: JiraField) {
  const customTitle = CUSTOM_HEADERS_TITLES[columnId];
  if (customTitle) return customTitle;
  if (field) return field.name;
  return upperCaseFirstLetter(columnId);
}

interface ColumnProps {
  columnId: string;
  fields: JiraField[];
  estimationFieldId?: string;
  isLoading: boolean;
}

export function createColumn({ columnId, fields, estimationFieldId, isLoading }: ColumnProps) {
  const field = fields.find((field) => field.id === columnId);
  const type = CUSTOM_RENDERERS[columnId] ? columnId : field?.schema?.type;
  const size = type ? sizes[type] : { size: 40 };
  return columnHelper.display({
    id: columnId,
    meta: {
      cssId: type ?? columnId,
    },
    ...size,
    header: () => <span data-private>{generateHeaderContent(columnId, field)}</span>,
    cell: ({ row, cell }) => {
      return (
        <Loader size={cell.column.getSize()} loading={isLoading}>
          <FieldRenderer issue={row.original} estimationFieldId={estimationFieldId} columnId={columnId} field={field} />
        </Loader>
      );
    },
  });
}

const sizes: Record<string, { minSize?: number; maxSize?: number; size?: number }> = {
  id: { size: 32 },
  priority: { size: 40 },
  issuetype: { size: 32 },
  epic: { size: 140 },
  status: { size: 110 },
  user: { size: 70 },
  estimate: { size: 80 },
  string: { size: 150 },
  option: { size: 150 },
  number: { size: 100 },
  array: { size: 300 },
  datetime: { size: 200 },
  date: { size: 100 },
  timetracking: { size: 100 },
  parent: { size: 200 },
  [FIELD.GAME_ESTIMATION]: { size: 52 },
};
