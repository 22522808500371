import React, { useCallback } from "react";

import { EstimationHeaderProps } from "@appfire/poker-core";
import Button from "@atlaskit/button/new";

import { useRecordCardsRevealedEvent } from "../../../analytics/hooks/events/useRecordCardsRevealedEvent";
import { JiraIssue } from "../../../types";

type PersonalEstimationActionsProps = Pick<EstimationHeaderProps<JiraIssue>, "revealVotes" | "votedParticipantsCount">;

export function PersonalEstimationActions({ revealVotes }: Readonly<PersonalEstimationActionsProps>) {
  const recordCardsRevealedEvent = useRecordCardsRevealedEvent();

  const onClick = useCallback(() => {
    revealVotes();
    recordCardsRevealedEvent();
  }, [recordCardsRevealedEvent, revealVotes]);

  return (
    <Button onClick={onClick} appearance="primary" testId="reveal-cards-button">
      Reveal cards
    </Button>
  );
}
