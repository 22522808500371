import React, { ChangeEvent, useCallback, useState } from "react";

import { EstimationHeaderProps } from "@appfire/poker-core";
import { IconButton } from "@atlaskit/button/new";
import Textfield from "@atlaskit/textfield";
import Tooltip from "@atlaskit/tooltip";
import { AkRetryIcon } from "@fuegokit/fuegoicons-react";
import { Box } from "@fuegokit/react";

import { ReplayClicked } from "../../../analytics/ampli";
import { useFinalEstimateSavedEvent } from "../../../analytics/hooks/events/useFinalEstimateSavedEvent";
import { useRecordEvent } from "../../../analytics/hooks/events/useRecordEvent";
import { JiraIssue } from "../../../types";
import { useGameData } from "../GameProvider";
import { useIsCurrentUserSpectator } from "../ParticipantsPanel/hooks/useIsCurrentUserSpectator";
import { SaveEstimationButtons } from "./SaveEstimationButtons";

type FinalEstimateActionsProps = Pick<
  EstimationHeaderProps<JiraIssue>,
  "finalEstimate" | "setFinalEstimate" | "saveFinalEstimate" | "resetVotes" | "activeItem"
>;

function ReplayIcon() {
  return <AkRetryIcon />;
}

export function FinalEstimationActions({
  finalEstimate,
  setFinalEstimate,
  saveFinalEstimate,
  resetVotes,
  activeItem,
}: Readonly<FinalEstimateActionsProps>) {
  const [isLoading, setIsLoading] = useState(false);
  const recordFinalEstimateSavedEvent = useFinalEstimateSavedEvent();
  const recordReplayClickedEvent = useRecordEvent(ReplayClicked);
  const { gameId } = useGameData();
  const isSpectator = useIsCurrentUserSpectator();

  const onSave = useCallback(
    (goToNextIssue?: boolean, nextIssueId?: string | null) => {
      void (async () => {
        recordFinalEstimateSavedEvent({ issue_id: activeItem!.id, estimation_value: finalEstimate! });
        setIsLoading(true);
        await saveFinalEstimate(Boolean(goToNextIssue), nextIssueId);
        setIsLoading(false);
      })();
    },
    [activeItem, finalEstimate, recordFinalEstimateSavedEvent, saveFinalEstimate],
  );

  const onReplayClicked = useCallback(() => {
    resetVotes();
    recordReplayClickedEvent({
      game_id: gameId,
      is_spectator: isSpectator,
      issue_id: activeItem!.id,
    });
  }, [activeItem, gameId, isSpectator, recordReplayClickedEvent, resetVotes]);

  const isSaveDisabled = !finalEstimate;

  return (
    <>
      <Box width="170px">
        <Textfield
          placeholder="Pick final estimate"
          isCompact
          value={finalEstimate}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setFinalEstimate(event.target.value)}
        />
      </Box>
      <SaveEstimationButtons isLoading={isLoading} isDisabled={isSaveDisabled} onSave={onSave} />
      <Tooltip content="Replay">
        <IconButton icon={ReplayIcon} label="Replay" onClick={onReplayClicked} />
      </Tooltip>
    </>
  );
}
