import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
  ElementDragPayload,
  monitorForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import { reorder } from "@atlaskit/pragmatic-drag-and-drop/reorder";
import { autoScrollForElements } from "@atlaskit/pragmatic-drag-and-drop-auto-scroll/element";
import { triggerPostMoveFlash } from "@atlaskit/pragmatic-drag-and-drop-flourish/trigger-post-move-flash";
import { attachClosestEdge, Edge, extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { getReorderDestinationIndex } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index";

import { AbstractItem } from "../types";

type ItemEntry = { itemId: string; element: HTMLElement };

type ItemState =
  | { type: "idle" }
  | { type: "preview"; container: HTMLElement }
  | { type: "dragging" }
  | { type: "is-over"; closestEdge: Edge | null };

const IDLE_STATE: ItemState = { type: "idle" };
const DRAGGING_STATE: ItemState = { type: "dragging" };

type ListContextValue = {
  registerItem: (entry: ItemEntry) => VoidFunction;
  getListLength: () => number;
  reorderItem: (args: { startIndex: number; indexOfTarget: number; closestEdgeOfTarget: Edge | null }) => void;
  instanceId: symbol;
};

const ListContext = createContext<ListContextValue | null>(null);

function getItemRegistry() {
  const registry = new Map<string, HTMLElement>();

  function register({ itemId, element }: ItemEntry) {
    registry.set(itemId, element);

    return function unregister() {
      if (registry.get(itemId) === element) {
        registry.delete(itemId);
      }
    };
  }

  function getElement(itemId: string): HTMLElement | null {
    return registry.get(itemId) ?? null;
  }

  return { register, getElement };
}

export function useListContext() {
  const listContext = useContext(ListContext);
  if (!listContext) {
    throw new Error("List context context not initialized, probably context provider is missing");
  }
  return listContext;
}

const itemKey = Symbol("item");
type ItemData<TItem> = {
  [itemKey]: true;
  item: TItem;
  index: number;
  instanceId: symbol;
};

function getItemData<TItem>({
  item,
  index,
  instanceId,
}: {
  item: TItem;
  index: number;
  instanceId: symbol;
}): ItemData<TItem> {
  return {
    [itemKey]: true,
    item,
    index,
    instanceId,
  };
}

function isItemData<TItem extends AbstractItem>(data: Record<string | symbol, unknown>): data is ItemData<TItem> {
  return data[itemKey] === true;
}

export function useDraggableItem<TItem extends AbstractItem>({
  item,
  index,
  isDraggable = false,
}: {
  item: TItem;
  index: number;
  isDraggable?: boolean;
}) {
  const { instanceId, registerItem } = useListContext();

  const innerRef = useRef<HTMLDivElement>(null);

  const [state, setState] = useState<ItemState>(IDLE_STATE);

  useEffect(() => {
    const element = innerRef.current;
    if (!element) {
      console.error("Draggable Item context not initialized, innerRef is null");
      return;
    }

    function predicate({ source }: { source: ElementDragPayload }): boolean {
      return isItemData(source.data) && source.data.instanceId === instanceId && source.data.item.id !== item.id;
    }

    const data = getItemData<TItem>({ item, index, instanceId });

    return combine(
      registerItem({ itemId: item.id, element }),
      draggable({
        element,
        canDrag: () => isDraggable,
        getInitialData: () => data,
        onGenerateDragPreview({ nativeSetDragImage }) {
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({ x: "16px", y: "8px" }),
            render({ container }) {
              setState({ type: "preview", container });

              return () => setState(DRAGGING_STATE);
            },
          });
        },
        onDragStart() {
          setState(DRAGGING_STATE);
        },
        onDrop() {
          setState(IDLE_STATE);
        },
      }),
      dropTargetForElements({
        element,
        canDrop: predicate,
        getIsSticky: () => true,
        getData({ input }) {
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ["top", "bottom"],
          });
        },
        onDrag({ self }) {
          const closestEdge = extractClosestEdge(self.data);

          setState((current) => {
            if (current.type === "is-over" && current.closestEdge === closestEdge) {
              return current;
            }
            return { type: "is-over", closestEdge };
          });
        },
        onDragLeave() {
          setState(IDLE_STATE);
        },
        onDrop() {
          setState(IDLE_STATE);
        },
      }),
    );
  }, [instanceId, item, index, isDraggable, registerItem]);

  return {
    innerRef,
    state,
  };
}

interface LastCard<TItem> {
  item: TItem;
  currentIndex: number;
}

export function DraggableList<T extends AbstractItem>({
  children,
  items,
  onOrderChange,
  scrollContainerRef,
}: {
  items: T[];
  children: React.ReactNode;
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  onOrderChange?: (items: T[]) => void;
}) {
  const [instanceId] = useState(() => Symbol("instance-id"));
  const [lastCardMoved, setLastCardMoved] = useState<LastCard<T> | null>(null);
  const [registry] = useState(getItemRegistry);
  const reorderItem = useCallback(
    ({
      startIndex,
      indexOfTarget,
      closestEdgeOfTarget,
    }: {
      startIndex: number;
      indexOfTarget: number;
      closestEdgeOfTarget: Edge | null;
    }) => {
      const finishIndex = getReorderDestinationIndex({
        startIndex,
        closestEdgeOfTarget,
        indexOfTarget,
        axis: "vertical",
      });

      if (finishIndex === startIndex) {
        // If there would be no change, we skip the update
        return;
      }

      const reordered = reorder({
        list: items,
        startIndex,
        finishIndex,
      });

      setLastCardMoved({
        item: items[startIndex],
        currentIndex: finishIndex,
      });

      onOrderChange?.(reordered);
    },
    [items, onOrderChange],
  );

  useEffect(() => {
    if (lastCardMoved === null) {
      return;
    }

    const { item } = lastCardMoved;

    setTimeout(() => {
      const element = registry.getElement(item.id);
      if (element) {
        triggerPostMoveFlash(element);
      }
    }, 100);
  }, [lastCardMoved, registry]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) {
      console.error("Draggable context not initialized, scrollContainer is missing");
      return;
    }

    function canRespond({ source }: { source: ElementDragPayload }) {
      return isItemData(source.data) && source.data.instanceId === instanceId;
    }

    return combine(
      monitorForElements({
        canMonitor: canRespond,
        onDragStart() {
          scrollContainer.setAttribute("dragging", "true");
        },
        onDrop({ location, source }) {
          scrollContainer.removeAttribute("dragging");

          const [target] = location.current.dropTargets;

          if (!target) {
            return;
          }

          const sourceData = source.data;
          const targetData = target.data;

          if (!isItemData(sourceData) || !isItemData(targetData)) {
            return;
          }

          const indexOfTarget = items.findIndex((item) => {
            return item.id === targetData.item.id;
          });
          if (indexOfTarget < 0) {
            return;
          }

          const closestEdgeOfTarget = extractClosestEdge(targetData);
          reorderItem({
            startIndex: sourceData.index,
            indexOfTarget,
            closestEdgeOfTarget,
          });
        },
      }),
      autoScrollForElements({ canScroll: canRespond, element: scrollContainer }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instanceId, items, reorderItem]);

  const getListLength = useCallback(() => items.length, [items.length]);

  const contextValue: ListContextValue = useMemo(() => {
    return {
      registerItem: registry.register,
      reorderItem,
      instanceId,
      getListLength,
    };
  }, [reorderItem, instanceId, getListLength, registry.register]);

  return <ListContext.Provider value={contextValue}>{children}</ListContext.Provider>;
}
