import React from "react";

import Toggle from "@atlaskit/toggle";
import { Box } from "@fuegokit/react";
import { FuegoSystemStyleObject } from "@fuegokit/react/lib/sx";

import { InfoTooltip } from "./InfoTooltip";

interface SwitchElementProps {
  label: string;
  value: boolean;
  onToggle: () => void;
  id: string;
  labelStyles?: FuegoSystemStyleObject;
  isDisabled?: boolean;
  explanation?: string;
}

export function SwitchElement({
  label,
  value,
  onToggle,
  id,
  labelStyles,
  isDisabled,
  explanation,
}: Readonly<SwitchElementProps>) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "4px",
        color: "var(--ds-text-subtle, #6b778c)",
        marginLeft: "2px",
      }}
    >
      <Box marginLeft="-4px">
        <Toggle id={id} size="regular" isChecked={value} onChange={onToggle} isDisabled={isDisabled} />
      </Box>
      <Box
        as="label"
        htmlFor={id}
        sx={{
          color: "text.default",
          ...labelStyles,
        }}
      >
        {label}
      </Box>
      {explanation ? <InfoTooltip message={explanation} /> : null}
    </Box>
  );
}
