import { child, DatabaseReference, onDisconnect, push, ref, remove, update } from "firebase/database";
import { useCallback, useMemo } from "react";
import { useDatabase, useDatabaseObjectData } from "reactfire";

import { useGameItemRef } from "../firebase";

export function useOnConnectionStateChange() {
  const database = useDatabase();
  const connectionInfo = ref(database, "/.info/connected");
  return useDatabaseObjectData<boolean>(connectionInfo, { initialData: false });
}

function useParticipantsRef(gameId: string) {
  const gameRef = useGameItemRef(gameId);

  return useMemo(() => child(gameRef, "participants"), [gameRef]);
}

function useParticipantRef(gameId: string, userId: string) {
  const participantsRef = useParticipantsRef(gameId);

  return useMemo(() => child(participantsRef, userId), [participantsRef, userId]);
}

export function useParticipant(gameId: string, userId: string) {
  const participantRef = useParticipantRef(gameId, userId);
  return useDatabaseObjectData<Record<string, unknown>>(participantRef);
}

export function useParticipantInstanceRegister(gameId: string, accountId: string) {
  const participantRef = useParticipantRef(gameId, accountId);

  return useCallback(
    async (isConnected: boolean) => {
      const instancesRef = child(participantRef, "instances");

      let instanceRef: DatabaseReference;
      if (isConnected) {
        await update(participantRef, { accountId });
        instanceRef = await push(instancesRef, true);
        await onDisconnect(instanceRef).remove();
      }

      return async () => {
        if (isConnected && instanceRef) {
          await remove(instanceRef).catch(console.error);
        }
      };
    },
    [accountId, participantRef],
  );
}
