import React from "react";
import styled from "styled-components";

const StyledExpandIcon = styled.div<{ $isExpanded: boolean }>`
  position: absolute;
  left: -12px;
  top: 76px;
  z-index: var(--z-index-layer-3);
  opacity: ${(props) => (props.$isExpanded ? "1" : "0")};
  transition: opacity 0.2s;
  transform: rotate(${(props) => (props.$isExpanded ? "180deg" : "0")});
  height: 24px;
  width: 24px;

  & > svg {
    fill: var(--ds-surface-overlay, #ffffff);
    color: var(--ds-text-subtle, #6b778c);
    cursor: pointer;
  }

  path#Vector {
    fill: var(--ds-text-subtle, #6b778c);
  }

  &:hover > svg {
    fill: var(--ds-border-selected, #0c66e4);

    path#Vector {
      fill: var(--ds-surface-overlay, #ffffff);
    }
  }
`;

interface ExpandIconProps {
  onClick: () => void;
  isExpanded: boolean;
}

export function ExpandIcon({ isExpanded, onClick }: ExpandIconProps) {
  return (
    <StyledExpandIcon $isExpanded={isExpanded} onClick={onClick}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Chevron Left Icon">
          <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" />
          <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="var(--ds-scale-neutral-300, #DCDFE4)" />
          <path
            id="Vector"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.706 9.69799C13.7992 9.60607 13.8733 9.49655 13.9238 9.37578C13.9743 9.25501 14.0003 9.1254 14.0003 8.99449C14.0003 8.86358 13.9743 8.73397 13.9238 8.6132C13.8733 8.49243 13.7992 8.38291 13.706 8.29099C13.517 8.10443 13.2621 7.99982 12.9965 7.99982C12.7309 7.99982 12.4761 8.10443 12.287 8.29099L9.32203 11.231C9.21984 11.3323 9.13872 11.4529 9.08337 11.5857C9.02801 11.7186 8.99951 11.8611 8.99951 12.005C8.99951 12.1489 9.02801 12.2914 9.08337 12.4242C9.13872 12.5571 9.21984 12.6777 9.32203 12.779L12.277 15.709C12.4661 15.8959 12.7212 16.0007 12.987 16.0007C13.2529 16.0007 13.508 15.8959 13.697 15.709C13.7902 15.6171 13.8643 15.5075 13.9148 15.3868C13.9653 15.266 13.9913 15.1364 13.9913 15.0055C13.9913 14.8746 13.9653 14.745 13.9148 14.6242C13.8643 14.5034 13.7902 14.3939 13.697 14.302L11.379 12.005L13.706 9.69799Z"
          />
        </g>
      </svg>
    </StyledExpandIcon>
  );
}
