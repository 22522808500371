import React from "react";

import March2022 from "./AppfireStatement";
import April2022 from "./April2022";
import August2024 from "./August2024";
import February2022 from "./February2022";
import July2022 from "./July2022";
import July2024 from "./July2024";
import November2024 from "./November2024";
import October2024 from "./October2024";
import September2024 from "./September2024";

export type ChangelogItem = {
  Component: () => React.ReactElement;
  date: Date;
};

export const CHANGELOGS: ChangelogItem[] = [
  // New changelog entry must be added as the first array element
  November2024,
  October2024,
  September2024,
  August2024,
  July2024,
  July2022,
  April2022,
  March2022,
  February2022,
];

export const CHANGELOG_NUMBER_APP = CHANGELOGS.length;
export const CHANGELOG_DATE_LAST_UPDATED = CHANGELOGS[0].date;
