import React, { useMemo } from "react";

import { useApplicationContext } from "../../../providers/ApplicationContextProvider";
import { TruncatedTooltipMemoized } from "../TruncatedTooltip";

interface DateTimeCellProps {
  datetime?: string;
}

export function DateTimeCell({ datetime }: Readonly<DateTimeCellProps>) {
  const { userLocale } = useApplicationContext();

  const formattedDateTime = useMemo(() => {
    if (!datetime) return "";
    const date = new Date(datetime);
    return Intl.DateTimeFormat(userLocale, {
      dateStyle: "medium",
      timeStyle: "short",
    }).format(date);
  }, [datetime, userLocale]);

  return (
    <TruncatedTooltipMemoized tooltipText={formattedDateTime}>
      <span data-private>{formattedDateTime}</span>
    </TruncatedTooltipMemoized>
  );
}
