import { load } from "@fingerprintjs/fingerprintjs-pro";

const FINGERPRINT_IDENTITY_KEY = "fingerprint-identity-key";
export const FINGERPRINT_API_KEY = process.env.FINGERPRINT_API_KEY as string;

const getFingerprintIdentity = async (userAccountId: string, host: string, licenseId?: string) => {
  try {
    const fingerprint = await load({
      apiKey: FINGERPRINT_API_KEY,
    });

    const identity = await fingerprint.get({
      linkedId: userAccountId,
      tag: {
        licenseId,
        host,
      },
    });

    setCachedIdentity(identity.visitorId);
    return identity.visitorId;
  } catch (error) {
    return undefined;
  }
};

const setCachedIdentity = (visitorId: string) => {
  try {
    localStorage.setItem(FINGERPRINT_IDENTITY_KEY, visitorId);
  } catch (error) {
    return undefined;
  }
};

const getCachedIdentity = () => {
  try {
    return localStorage.getItem(FINGERPRINT_IDENTITY_KEY);
  } catch (error) {
    return undefined;
  }
};

export const getIdentity = async (userAccountId: string, host: string, licenseId?: string) => {
  return getCachedIdentity() || (await getFingerprintIdentity(userAccountId, host, licenseId));
};
