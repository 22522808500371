import { getAddonInfo } from "../services/jira-api";
import { AddonInfo } from "../types";

const SUPPORT_PORTAL_URL = "https://appfire.atlassian.net/servicedesk/customer/portal/11/group/36/create/163";
const APP_NAME_FIELD = "customfield_10149";
const PLATFORM_FIELD = "customfield_10161";
const INSTANCE_ADDRESS_FIELD = "customfield_10205";
const SEN_FIELD = "customfield_10210";

const PLANNING_POKER = "10734";
const CLOUD = "10935";

function buildSearchParams(hostBaseURL: string, addonInfo?: AddonInfo) {
  const searchParams = new URLSearchParams();
  searchParams.set(APP_NAME_FIELD, PLANNING_POKER);
  searchParams.set(PLATFORM_FIELD, CLOUD);
  searchParams.set(INSTANCE_ADDRESS_FIELD, hostBaseURL);
  if (addonInfo) {
    searchParams.set(SEN_FIELD, addonInfo.license?.entitlementId || "");
  }
  return searchParams;
}

export async function getSupportURL(addonKey: string, hostBaseURL: string) {
  const searchParams = await getAddonInfo(addonKey)
    .then((pluginInfo) => {
      return buildSearchParams(hostBaseURL, pluginInfo);
    })
    .catch(() => {
      return buildSearchParams(hostBaseURL);
    });
  return new URL(SUPPORT_PORTAL_URL + "?" + searchParams.toString());
}
