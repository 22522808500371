import React from "react";

import { Box } from "@fuegokit/react";

import { CHANGELOGS } from "./index";

export function ChangelogModalContent() {
  return (
    <>
      {CHANGELOGS.map(({ Component, date }) => (
        <Component key={date.toJSON()} />
      ))}
      <Box>
        <h2>Missing a feature?</h2>
        <p>
          Let us know by emailing your suggestions to{" "}
          <a href="mailto:support@appfire.com" target="_blank" rel="noreferrer">
            support@appfire
          </a>
        </p>
      </Box>
    </>
  );
}
