import React, { Dispatch, SetStateAction, useMemo } from "react";

import SectionMessage from "@atlaskit/section-message";
import { Box } from "@fuegokit/react";

import { JiraField, JiraIssue } from "../../types";
import { createColumn, getCheckboxColumn } from "./headers";
import { IssuesTable } from "./IssuesTable";

interface AddIssuesTableProps {
  setRowSelection: Dispatch<SetStateAction<Record<string, boolean>>>;
  rowSelection: Record<string, boolean>;
  issues: JiraIssue[];
  isLoading: boolean;
  disabledIssueKeys: string[];
  children: React.ReactNode;
  columnsIds: string[];
  fields: JiraField[];
  estimationFieldId?: string;
}

export const AddIssuesTable = ({
  issues,
  setRowSelection,
  rowSelection,
  children,
  disabledIssueKeys,
  isLoading,
  columnsIds,
  fields,
  estimationFieldId,
}: AddIssuesTableProps) => {
  const isLimitReached = issues.length >= 1000;
  const loading = isLoading && issues.length === 0;
  const isNotEmpty = issues.length > 0 || loading;

  const columns = useMemo(
    () => [
      getCheckboxColumn({
        isLoading: loading,
        disabledItemIds: disabledIssueKeys,
        disabledTooltipMessage: "Already added to game backlog",
      }),
      ...columnsIds.map((columnId) => createColumn({ columnId, fields, estimationFieldId, isLoading: loading })),
    ],
    [loading, columnsIds, fields, estimationFieldId, disabledIssueKeys],
  );

  return (
    <>
      {isNotEmpty && (
        <>
          <Box my={3}>
            {isLimitReached ? (
              <SectionMessage appearance="warning">
                <strong>{">1000 Issue limit exceeded, consider applying more filters"}</strong>
              </SectionMessage>
            ) : (
              <strong>{`${issues.length} issues loaded`}</strong>
            )}
          </Box>

          <IssuesTable
            disabledItemIds={disabledIssueKeys}
            isLoading={loading}
            columns={columns}
            issues={issues}
            fields={fields}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
          >
            {children}
          </IssuesTable>
        </>
      )}
    </>
  );
};
