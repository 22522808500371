import { useEffect, useState } from "react";

export function useThemeObserver() {
  const [colorMode, setColorMode] = useState<string | undefined>(undefined);

  useEffect(() => {
    const htmlElement = document.getElementsByTagName("html")[0];
    const observer = new MutationObserver((mutationList) =>
      mutationList.forEach((mutation) => {
        // When watching on attributes it will always be Element - https://developer.mozilla.org/en-US/docs/Web/API/MutationRecord/target
        const target = mutation.target as HTMLElement;
        setColorMode(target.dataset.colorMode);
      }),
    );
    observer.observe(htmlElement, { attributes: true });
    setColorMode(htmlElement.dataset.colorMode);
    return () => observer.disconnect();
  }, []);

  return colorMode;
}
