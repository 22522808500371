import { css } from "styled-components";

export const visuallyHidden = css`
  height: 0px;
  width: 0px;
  overflow: hidden;
`;

export const visuallyVisible = css`
  height: unset;
  width: unset;
  overflow: unset;
`;
