import React from "react";
import styled from "styled-components";

import Button from "@atlaskit/button/new";
import EditorIndentIcon from "@atlaskit/icon/glyph/editor/indent";
import TextField from "@atlaskit/textfield";
import Tooltip from "@atlaskit/tooltip";
import { Box, themeGet } from "@fuegokit/react";

import { IssueQuickAdded } from "../../analytics/ampli";
import { useRecordEvent } from "../../analytics/hooks/events/useRecordEvent";
import { showFlag } from "../../services/jira-api";
import { useGameData } from "../game/GameProvider";
import { useQueryByIssueKey } from "./useQueryByIssueKey";

const Container = styled(Box)`
  width: 164px;
  min-width: 164px;
  height: fit-content;
`;

const IssueKeyInput = styled(TextField)`
  border-width: 2px !important;
  height: 32px;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  &:not:hover,
  &:not:focus,
  &:not:active {
    border-color: ${themeGet("colors.border.input")};
  }

  & > input {
    text-transform: uppercase;

    &::placeholder {
      text-transform: none;
    }
  }
`;

const ButtonContainer = styled(Box)`
  & > button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

type QuickAddIssueInputProps = {
  columnsIds: string[];
  backlog: string[];
  addIssueToBacklog: (issueKey: string) => void;
};

export const QuickAddIssueInput = ({ columnsIds, backlog, addIssueToBacklog }: QuickAddIssueInputProps) => {
  const [issueKey, setIssueKey] = React.useState<string>("");

  const { loading, fetch } = useQueryByIssueKey(columnsIds);
  const { gameId } = useGameData();
  const recordIssueQuickAddedEvent = useRecordEvent(IssueQuickAdded);

  const handleSubmit = (action: "button" | "enter") => {
    if (!issueKey.length) return;

    if (backlog.includes(issueKey.toUpperCase())) {
      showFlag("Issue is already in game backlog", "", "info");
      return;
    }

    fetch(issueKey)
      .then((jiraIssue) => {
        addIssueToBacklog(jiraIssue.key);
        setIssueKey("");
        recordIssueQuickAddedEvent({
          game_id: gameId,
          action,
        });
      })
      .catch((error: Error) => {
        showFlag(error.message, "", "error");
      });
  };

  return (
    <Container display="flex" alignItems="center">
      <IssueKeyInput
        name="issueKey"
        placeholder="Issue key"
        autoComplete="off"
        value={issueKey}
        onChange={(event) => setIssueKey(event.currentTarget.value)}
        onKeyDown={(event) => (event.key === "Enter" ? handleSubmit("enter") : undefined)}
      />
      <Tooltip content="Add issue to backlog" position="top">
        <ButtonContainer>
          <Button
            onClick={() => handleSubmit("button")}
            isLoading={loading}
            iconBefore={() => <EditorIndentIcon label="Add issue" size="medium" />}
            isDisabled={!issueKey.length}
          >
            {undefined /* Passed undefined intentionally due to easier styling the button according to the designs */}
          </Button>
        </ButtonContainer>
      </Tooltip>
    </Container>
  );
};
