import { SyntheticEvent, useCallback, useMemo } from "react";

import { useApplicationContext } from "../../../providers/ApplicationContextProvider";
import { copyToClipBoard } from "../../../utils";
import { buildShareGameUrl } from "../../../utils/game";

export function useGameUrl(gameId: string) {
  const { addonKey, url } = useApplicationContext();
  const gameUrl = useMemo(
    () => buildShareGameUrl(gameId, addonKey, url.displayUrl),
    [addonKey, gameId, url.displayUrl],
  );

  const copyGameUrl = useCallback((e: SyntheticEvent) => copyToClipBoard(e, gameUrl), [gameUrl]);

  return { gameUrl, copyGameUrl };
}
