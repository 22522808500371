import React from "react";
import styled from "styled-components";

import { themeGet } from "@fuegokit/react";

import { JiraField, JiraIssue } from "../../../types";
import { FieldRenderer } from "../../backlog/FieldRenderer";
import { CollapsiblePanel } from "./CollapsiblePanel";

const GridDetailsFields = styled.div`
  padding: 12px 16px;
  min-width: 350px;
  display: grid;
  grid-template-columns: minmax(100px, 1fr) 1.5fr;
  grid-auto-rows: 32px;
  align-items: center;
  gap: 8px;
`;

const FieldName = styled.div`
  color: ${themeGet("colors.text.subtle")};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FieldRendererWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface DetailsProps {
  isExpanded: boolean;
  toggle: () => void;
  detailsFields: JiraField[];
  activeItem: JiraIssue;
}

export function Details({ isExpanded, toggle, detailsFields, activeItem }: DetailsProps) {
  return (
    <CollapsiblePanel
      isExpanded={isExpanded}
      toggle={toggle}
      title="Details"
      secondaryText={detailsFields.map((field) => field.name).join(", ")}
    >
      {detailsFields.length > 0 ? (
        <GridDetailsFields>
          {detailsFields.map((field) => (
            <React.Fragment key={field.id}>
              <FieldName>{field.name}</FieldName>
              <FieldRendererWrapper>
                {activeItem.fields[field.id] ? (
                  <FieldRenderer issue={activeItem} columnId={field.id} field={field} extended={true} />
                ) : (
                  <div>-</div>
                )}
              </FieldRendererWrapper>
            </React.Fragment>
          ))}
        </GridDetailsFields>
      ) : null}
    </CollapsiblePanel>
  );
}
