import React from "react";

import { Box } from "@fuegokit/react";

export function EmptyFiltersImage() {
  return (
    <Box py={4}>
      <svg width="177" height="92" viewBox="0 0 177 92" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M167.337 8.10782H16.9326V91.7764H167.337V8.10782Z" fill="#CFD4DB" />
        <path d="M167.337 8.10785H58.7666V46.954H167.337V8.10785Z" fill="url(#paint0_linear_424_122017)" />
        <path
          d="M42.6717 8.1079C35.6218 8.09654 28.733 10.1377 22.9092 13.9637V24.7969C26.4486 21.0307 31.0688 18.3604 36.1849 17.1242C41.301 15.888 46.683 16.1414 51.6495 17.8523C56.616 19.5632 60.9437 22.6548 64.0847 26.7356C67.2257 30.8163 69.0388 35.7028 69.2944 40.7764C69.55 45.85 68.2367 50.8826 65.5207 55.237C62.8047 59.5915 58.8081 63.0719 54.0369 65.2379C49.2657 67.4038 43.9345 68.1577 38.7181 67.4043C33.5017 66.6508 28.6348 64.4238 24.7334 61.0052L22.9092 59.2485V70.0817C27.4651 73.0302 32.6745 74.9108 38.1204 75.5729C43.5664 76.235 49.0976 75.6602 54.2716 73.8946C59.4455 72.1289 64.1184 69.2214 67.9162 65.4048C71.7141 61.5882 74.5314 56.9684 76.1428 51.9152C77.7542 46.862 78.1148 41.5157 77.1959 36.3042C76.277 31.0927 74.104 26.1607 70.8509 21.903C67.5977 17.6452 63.3547 14.18 58.4615 11.7845C53.5683 9.38904 48.1608 8.12992 42.6717 8.1079Z"
          fill="#CFD4DB"
        />
        <path d="M15.9284 59.2461L0 75.1745L4.55097 79.7254L20.4794 63.797L15.9284 59.2461Z" fill="#0065FF" />
        <path
          d="M38.7283 70.8593C55.5065 70.8593 69.108 57.2578 69.108 40.4796C69.108 23.7014 55.5065 10.0999 38.7283 10.0999C21.9501 10.0999 8.34863 23.7014 8.34863 40.4796C8.34863 57.2578 21.9501 70.8593 38.7283 70.8593Z"
          fill="#E5E8EC"
        />
        <path
          d="M68.7269 48.9461H25.8965V68.8672H42.3314L58.7663 65.381L60.7584 60.4007L68.7269 48.9461Z"
          fill="white"
        />
        <path d="M92.6329 26.0368H70.7197V32.0131H92.6329V26.0368Z" fill="white" />
        <path d="M146.419 48.9461H56.7744V53.9264H146.419V48.9461Z" fill="white" />
        <path d="M146.419 57.9106H56.7744V62.8909H146.419V57.9106Z" fill="white" />
        <path d="M146.419 66.8751H56.7744V71.8553H146.419V66.8751Z" fill="white" />
        <path d="M119.526 26.0368H97.6133V32.0131H119.526V26.0368Z" fill="white" />
        <path d="M146.42 26.0368H124.507V32.0131H146.42V26.0368Z" fill="white" />
        <path d="M63.7466 26.0368H25.8965V37.9895H63.7466V26.0368Z" fill="white" />
        <path
          d="M30.877 33.835V29.2866H32.4545C33.2404 29.2866 33.7972 29.876 33.7972 30.7397V30.746C33.7972 31.6097 33.2404 32.2117 32.4545 32.2117H31.3989V33.835H30.877ZM32.3211 29.7909H31.3989V31.7074H32.3211C32.9185 31.7074 33.2636 31.3512 33.2636 30.7523V30.746C33.2636 30.1471 32.9185 29.7909 32.3211 29.7909Z"
          fill="#44546F"
        />
        <path
          d="M34.5889 33.835V30.4371H35.0935V30.9414H35.1399C35.2588 30.5852 35.5517 30.3772 35.9751 30.3772C36.0708 30.3772 36.1781 30.3898 36.2274 30.3993V30.932C36.123 30.9131 36.0273 30.9004 35.9171 30.9004C35.4357 30.9004 35.0935 31.2314 35.0935 31.7294V33.835H34.5889Z"
          fill="#44546F"
        />
        <path
          d="M38.0486 33.8949C37.1583 33.8949 36.6073 33.2267 36.6073 32.1392V32.1329C36.6073 31.0423 37.1583 30.3772 38.0486 30.3772C38.9389 30.3772 39.4899 31.0423 39.4899 32.1329V32.1392C39.4899 33.2267 38.9389 33.8949 38.0486 33.8949ZM38.0486 33.4095C38.6402 33.4095 38.9737 32.9398 38.9737 32.1392V32.1329C38.9737 31.3291 38.6402 30.8626 38.0486 30.8626C37.457 30.8626 37.1235 31.3291 37.1235 32.1329V32.1392C37.1235 32.9398 37.457 33.4095 38.0486 33.4095Z"
          fill="#44546F"
        />
        <path
          d="M40.5339 29.7815C40.3425 29.7815 40.1859 29.6112 40.1859 29.4032C40.1859 29.1952 40.3425 29.025 40.5339 29.025C40.7253 29.025 40.8819 29.1952 40.8819 29.4032C40.8819 29.6112 40.7253 29.7815 40.5339 29.7815ZM39.8263 35.0013H39.7393V34.5159H39.8147C40.154 34.5159 40.2816 34.3866 40.2816 34.0115V30.4371H40.7862V34.0021C40.7862 34.7018 40.4817 35.0013 39.8263 35.0013Z"
          fill="#44546F"
        />
        <path
          d="M43.0162 33.8949C42.1231 33.8949 41.5837 33.2141 41.5837 32.1487V32.1455C41.5837 31.0959 42.1347 30.3772 42.9843 30.3772C43.834 30.3772 44.3502 31.0644 44.3502 32.0793V32.2937H42.0999C42.1144 32.9966 42.471 33.4063 43.0278 33.4063C43.4512 33.4063 43.7122 33.1889 43.7963 32.984L43.8079 32.9556H44.3125L44.3067 32.9808C44.1994 33.441 43.7528 33.8949 43.0162 33.8949ZM42.9814 30.8658C42.5174 30.8658 42.1637 31.2093 42.1086 31.8555H43.8369C43.7847 31.1841 43.4425 30.8658 42.9814 30.8658Z"
          fill="#44546F"
        />
        <path
          d="M46.4121 33.8949C45.5363 33.8949 44.9766 33.2046 44.9766 32.1266V32.1203C44.9766 31.0644 45.5334 30.3772 46.4092 30.3772C47.169 30.3772 47.6243 30.8532 47.7171 31.4584L47.72 31.4773H47.2183L47.2154 31.4678C47.1371 31.1337 46.8587 30.8626 46.4092 30.8626C45.8466 30.8626 45.4928 31.3543 45.4928 32.1203V32.1266C45.4928 32.9083 45.8524 33.4095 46.4092 33.4095C46.8297 33.4095 47.0965 33.2078 47.2125 32.8295L47.2183 32.8106L47.7171 32.8075L47.7113 32.8421C47.5837 33.4568 47.1632 33.8949 46.4121 33.8949Z"
          fill="#44546F"
        />
        <path
          d="M49.6253 33.8602C48.9815 33.8602 48.7118 33.6018 48.7118 32.9524V30.891H48.2188V30.4371H48.7118V29.5577H49.2338V30.4371H49.9182V30.891H49.2338V32.8138C49.2338 33.2141 49.3614 33.3748 49.6862 33.3748C49.7761 33.3748 49.8254 33.3717 49.9182 33.3622V33.8287C49.8196 33.8476 49.7239 33.8602 49.6253 33.8602Z"
          fill="#44546F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.4965 30.7424C55.4172 30.8295 55.3728 30.9471 55.3728 31.0696C55.3728 31.1921 55.4172 31.3096 55.4965 31.3967L56.7433 32.7639C56.8358 32.863 56.9554 32.9124 57.0738 32.9124C57.1921 32.9124 57.3096 32.863 57.4 32.7639L58.643 31.4013C58.7221 31.3142 58.7665 31.1966 58.7665 31.0742C58.7665 30.9517 58.7221 30.8342 58.643 30.747C58.604 30.7041 58.5576 30.6699 58.5064 30.6466C58.4552 30.6234 58.4002 30.6114 58.3447 30.6114C58.2892 30.6114 58.2343 30.6234 58.1831 30.6466C58.1319 30.6699 58.0855 30.7041 58.0465 30.747L57.0716 31.8154L56.093 30.7424C56.0539 30.6996 56.0075 30.6656 55.9563 30.6425C55.9051 30.6193 55.8502 30.6073 55.7947 30.6073C55.7393 30.6073 55.6844 30.6193 55.6332 30.6425C55.582 30.6656 55.5355 30.6996 55.4965 30.7424Z"
          fill="#626F86"
        />
        <path
          opacity="0.2"
          d="M43.2106 10.3988C34.5709 10.6857 26.3162 14.0417 19.9272 19.8648C13.5383 25.6878 9.4333 33.5968 8.34863 42.1729C8.68303 48.1783 10.7925 53.9493 14.4099 58.7547C18.0272 63.5601 22.9895 67.1836 28.6682 69.166C25.5181 67.1976 22.7996 64.6113 20.6768 61.5631C18.554 58.5149 17.0709 55.0682 16.3172 51.431C15.5634 47.7937 15.5545 44.0415 16.2911 40.4007C17.0277 36.76 18.4945 33.3063 20.6028 30.2481C22.7112 27.19 25.4175 24.5908 28.5582 22.6076C31.699 20.6243 35.209 19.2981 38.8765 18.709C42.544 18.12 46.2929 18.2802 49.8967 19.1802C53.5006 20.0801 56.8847 21.701 59.8447 23.9451C63.2959 26.2802 66.1596 29.3826 68.2115 33.0092C66.7067 27.2112 63.5375 21.9805 59.0947 17.9626C54.652 13.9446 49.1302 11.3152 43.2106 10.3988Z"
          fill="#091E42"
        />
        <path
          d="M39.2594 72.7594C32.7675 72.7594 26.4213 70.8344 21.0234 67.2276C15.6256 63.6209 11.4185 58.4945 8.93413 52.4967C6.44977 46.499 5.79975 39.8992 7.06626 33.532C8.33278 27.1648 11.4589 21.3161 16.0494 16.7256C20.6399 12.1352 26.4886 9.00899 32.8558 7.74247C39.223 6.47596 45.8228 7.12598 51.8205 9.61033C57.8183 12.0947 62.9447 16.3018 66.5514 21.6997C70.1581 27.0975 72.0832 33.4437 72.0832 39.9356C72.0832 48.641 68.625 56.9899 62.4694 63.1456C56.3137 69.3012 47.9648 72.7594 39.2594 72.7594ZM39.2594 12.9907C33.9302 12.9907 28.7207 14.571 24.2896 17.5317C19.8585 20.4925 16.4049 24.7007 14.3655 29.6242C12.3261 34.5478 11.7925 39.9655 12.8322 45.1923C13.8719 50.4191 16.4381 55.2202 20.2064 58.9885C23.9748 62.7569 28.7759 65.3231 34.0027 66.3628C39.2295 67.4025 44.6472 66.8689 49.5708 64.8295C54.4943 62.7901 58.7025 59.3365 61.6633 54.9054C64.624 50.4743 66.2043 45.2648 66.2043 39.9356C66.2043 32.7894 63.3655 25.9358 58.3123 20.8827C53.2592 15.8295 46.4056 12.9907 39.2594 12.9907Z"
          fill="#0065FF"
        />
        <rect x="148.673" y="3.25015" width="28.3186" height="28.3186" rx="10" fill="#B9C0CA" />
        <path
          d="M146.742 6.74399C146.742 3.39258 149.459 0.67572 152.81 0.67572H168.993C172.344 0.67572 175.061 3.39258 175.061 6.74399V22.9261C175.061 26.2775 172.344 28.9943 168.993 28.9943H152.81C149.459 28.9943 146.742 26.2775 146.742 22.9261V6.74399Z"
          fill="#0C66E4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M156.288 13.4298C156.102 13.2366 155.878 13.0825 155.632 12.9765C155.385 12.8705 155.119 12.8147 154.851 12.8123C154.582 12.81 154.316 12.8612 154.067 12.9629C153.819 13.0646 153.593 13.2148 153.403 13.4047C153.213 13.5946 153.063 13.8205 152.961 14.0691C152.859 14.3177 152.808 14.584 152.811 14.8526C152.813 15.1212 152.869 15.3866 152.975 15.6334C153.081 15.8802 153.235 16.1034 153.428 16.29L157.474 20.3355C157.853 20.7147 158.367 20.9277 158.904 20.9277C159.44 20.9277 159.954 20.7147 160.334 20.3355L168.425 12.2445C168.618 12.0579 168.772 11.8347 168.878 11.5879C168.984 11.3411 169.04 11.0757 169.042 10.8071C169.044 10.5385 168.993 10.2722 168.892 10.0236C168.79 9.77497 168.64 9.54913 168.45 9.3592C168.26 9.16928 168.034 9.01909 167.785 8.91738C167.537 8.81567 167.27 8.76449 167.002 8.76683C166.733 8.76916 166.468 8.82496 166.221 8.93097C165.974 9.03698 165.751 9.19108 165.565 9.38428L158.904 16.0452L156.288 13.4298Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_424_122017"
            x1="113.022"
            y1="46.954"
            x2="113.022"
            y2="-8.3005"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E5E8EC" stopOpacity="0.1" />
            <stop offset="0.08" stopColor="#E1E4E9" stopOpacity="0.27" />
            <stop offset="0.18" stopColor="#DCE0E5" stopOpacity="0.46" />
            <stop offset="0.29" stopColor="#D8DCE2" stopOpacity="0.63" />
            <stop offset="0.4" stopColor="#D5D9DF" stopOpacity="0.76" />
            <stop offset="0.53" stopColor="#D2D7DD" stopOpacity="0.87" />
            <stop offset="0.66" stopColor="#D0D5DC" stopOpacity="0.94" />
            <stop offset="0.8" stopColor="#CFD4DB" stopOpacity="0.99" />
            <stop offset="1" stopColor="#CFD4DB" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}
