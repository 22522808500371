import React from "react";
import styled from "styled-components";

import Tooltip from "@atlaskit/tooltip";
import { AkPeopleGroupIcon } from "@fuegokit/fuegoicons-react";
import { Box, themeGet } from "@fuegokit/react";

import { ExpandTransition } from "./ExpandTransition";

interface PanelHeaderProps {
  isExpanded: boolean;
  onlineParticipantsCount: number;
}

const HeaderText = styled.div`
  color: ${themeGet("colors.text.subtle")};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-left: 8px;
  white-space: nowrap;
`;

export function PanelHeader({ isExpanded, onlineParticipantsCount }: Readonly<PanelHeaderProps>) {
  return (
    <Box
      paddingX="20px"
      height="32px"
      display="flex"
      justifyContent={isExpanded ? "space-between" : "center"}
      alignItems="center"
    >
      <Tooltip content={isExpanded ? "" : `Online participants: ${onlineParticipantsCount}. Click to expand`}>
        <Box display="flex">
          <AkPeopleGroupIcon />
          <HeaderText>
            {onlineParticipantsCount}
            <ExpandTransition $isExpanded={isExpanded}>
              {isExpanded && ` Online Participant${onlineParticipantsCount > 1 ? "s" : ""}`}
            </ExpandTransition>
          </HeaderText>
        </Box>
      </Tooltip>
    </Box>
  );
}
