import React, { createContext, ReactNode, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Navigate, useParams } from "react-router-dom";

import { useGetSupportedBacklogColumnIds } from "../../hooks/useGetSupportedBacklogColumnIds";
import { useGetSupportedEstimationFieldId } from "../../hooks/useGetSupportedEstimationFieldId";
import { useApplicationContext } from "../../providers/ApplicationContextProvider";
import { useJiraDataStore } from "../../providers/JiraDataStoreProvider";
import { useUsersStore } from "../../providers/UserStoreContextProvider";
import { useGameItemRealTime } from "../../services/firebase";
import { showFlag } from "../../services/jira-api";
import { Game, JiraUser } from "../../types";
import { normalizeBy } from "../../utils";
import { debouncedShowFlagGameDisconnected, isGameAdmin } from "../../utils/game";
import { LoadingPage } from "../shared/LoadingPage";

interface GameContextData {
  game: Game;
  gameId: string;
  isGameAdmin: boolean;
  usersDetails: Record<string, JiraUser>;
}

const GameContext = createContext<GameContextData | null>(null);

const useGameFlagGameStatus = (game: Game | null, isLoading: boolean) => {
  const gameRef = useRef(game);

  useEffect(() => {
    if (isLoading) return;
    // Game doesn't exist
    if (gameRef.current === null && game === null) {
      showFlag("Game not found", "", "error");
    }
    // Game existed, but was deleted
    if (gameRef.current && game === null) {
      void debouncedShowFlagGameDisconnected();
    }

    gameRef.current = game;
  }, [game, isLoading]);
};

export function GameProvider({ children }: Readonly<{ children: ReactNode }>) {
  const { id: gameId } = useParams() as { id: string };
  const { userAccountId } = useApplicationContext();
  const [usersDetails, setUsersDetails] = useState<Record<string, JiraUser>>({});
  const { queryByAccountIds } = useUsersStore();

  const { data: firebaseGame, status: gameStatus } = useGameItemRealTime(gameId);
  const { isLoading: isLoadingJiraData } = useJiraDataStore();
  const isLoading = gameStatus === "loading" || isLoadingJiraData;
  const getSupportedEstimationField = useGetSupportedEstimationFieldId();
  const getSupportedBacklogColumnIds = useGetSupportedBacklogColumnIds();

  useEffect(() => {
    void (async () => {
      if (!firebaseGame) return;
      const participantsIds = Object.values(firebaseGame.participants).map((participant) => participant.accountId);
      const users = await queryByAccountIds(participantsIds);
      const normalizedUsers = normalizeBy(users, "accountId");
      setUsersDetails(normalizedUsers);
    })();
  }, [firebaseGame, firebaseGame?.participants, queryByAccountIds, userAccountId]);

  const game = useMemo(() => {
    if (!firebaseGame) return firebaseGame;
    const estimationFieldId = getSupportedEstimationField(firebaseGame.configuration.estimationFieldId);
    const backlogColumnIds = getSupportedBacklogColumnIds(firebaseGame.configuration.backlogColumnIds ?? []);
    return { ...firebaseGame, configuration: { ...firebaseGame.configuration, estimationFieldId, backlogColumnIds } };
  }, [firebaseGame, getSupportedBacklogColumnIds, getSupportedEstimationField]);

  const value = useMemo(
    () =>
      game
        ? {
            game,
            gameId: gameId,
            isGameAdmin: isGameAdmin(userAccountId, game?.configuration?.admins),
            usersDetails,
          }
        : null,
    [game, gameId, userAccountId, usersDetails],
  );
  useGameFlagGameStatus(game, isLoading);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!value) {
    return <Navigate to="/" />;
  }

  return <GameContext.Provider value={value}>{children}</GameContext.Provider>;
}

export function useGameData() {
  const context = useContext(GameContext);
  if (!context) {
    throw new Error("Game context not initialized");
  }
  return context;
}
