import { useMemo } from "react";

import { useApplicationContext } from "../../../../providers/ApplicationContextProvider";
import { GameConfig, GameParticipant } from "../../../../types";
import { useGameData } from "../../GameProvider";

// 1. Current user
// 2. Admins
// 3. Online participants
// 4. Offline participants
const sortParticipants = (participants: GameParticipant[], userAccountId: string, admins: GameConfig["admins"]) =>
  participants.sort((a, b) => {
    if (a.accountId === userAccountId) return -1;
    if (b.accountId === userAccountId) return 1;

    const aIsAdmin = admins[a.accountId];
    const bIsAdmin = admins[b.accountId];

    if (aIsAdmin && !bIsAdmin) return -1;
    if (bIsAdmin && !aIsAdmin) return 1;

    if (a.instances && !b.instances) return -1;
    if (b.instances && !a.instances) return 1;

    return 0;
  });

export function useSortedParticipants() {
  const { game } = useGameData();
  const { userAccountId } = useApplicationContext();

  return useMemo(
    () => sortParticipants(Object.values(game.participants), userAccountId, game.configuration.admins),
    [game.participants, userAccountId, game.configuration.admins],
  );
}
