import React, { useCallback, useMemo } from "react";

import { ItemState, PersonalEstimateSelectorProps } from "@appfire/poker-core";
import Button from "@atlaskit/button";
import { AkEditIcon, MdyUndoIcon } from "@fuegokit/fuegoicons-react";
import { Box } from "@fuegokit/react";

import { PersonalVoteProvided, VoteEdited } from "../../analytics/ampli";
import { useRecordEvent } from "../../analytics/hooks/events/useRecordEvent";
import { JiraIssue } from "../../types";
import { CardType, GamePersona } from "../../types/common";
import { CardDeck } from "./Cards/CardDeck";
import { UnrevealedVotes } from "./Cards/UnrevealedVotes";
import { CardsSectionMessage } from "./CardsSectionMessage";
import { useGameData } from "./GameProvider";
import { useToggleRole } from "./ParticipantsPanel/hooks/useToggleRole";

export function PersonalEstimateSelector({
  activeItem,
  cards,
  selectedCard,
  removeEstimate,
  selectEstimate,
  onMouseEnter,
  onMouseLeave,
  isSpectator,
  toggleEditMode,
  isEditMode,
  participantsVotes,
  activeItemState,
}: PersonalEstimateSelectorProps<JiraIssue, CardType, string, GamePersona>) {
  const {
    game: { participants },
    gameId,
    isGameAdmin,
  } = useGameData();
  const recordPersonalVoteProvidedEvent = useRecordEvent(PersonalVoteProvided);
  const recordVoteEditedEvent = useRecordEvent(VoteEdited);

  const onlineEstimatorsCount = useMemo(
    () =>
      Object.values(participants).filter((participant) => Boolean(participant.instances) && !participant.isSpectator)
        .length,
    [participants],
  );

  const toggleRole = useToggleRole();

  const recordVoteEvent = useCallback(
    (card: CardType) => {
      const properties = {
        estimation_value: card.value,
        game_id: gameId,
        is_game_admin: isGameAdmin,
        issue_id: activeItem!.id,
      };
      if (isEditMode) {
        recordVoteEditedEvent(properties);
      } else {
        recordPersonalVoteProvidedEvent(properties);
      }
    },
    [activeItem, gameId, isGameAdmin, recordPersonalVoteProvidedEvent, recordVoteEditedEvent, isEditMode],
  );

  const onCardClicked = useCallback(
    (card: CardType) => {
      if (card.value === selectedCard?.value) {
        removeEstimate();
      } else {
        selectEstimate(card.value);
        recordVoteEvent(card);
      }
    },
    [selectedCard?.value, removeEstimate, selectEstimate, recordVoteEvent],
  );

  function setEditMode() {
    toggleEditMode(!isEditMode);
  }

  const filteredParticipantsVotes = useMemo(
    () =>
      participantsVotes.filter(
        (participantVote) =>
          !participantVote.participant.isSpectator &&
          (participantVote.participant.hasVoted || participantVote.participant.isOnline),
      ),
    [participantsVotes],
  );

  const becomeEstimator = useCallback(() => void toggleRole(), [toggleRole]);

  if (!activeItem) return null;

  if (isSpectator) {
    return onlineEstimatorsCount ? (
      <Box paddingTop="24px" minHeight="136px">
        <UnrevealedVotes votes={filteredParticipantsVotes} />
      </Box>
    ) : (
      <CardsSectionMessage message="There are no online estimators in the game">
        <Button onClick={becomeEstimator}>Become estimator</Button>
      </CardsSectionMessage>
    );
  }

  return (
    <Box display="grid" gridTemplateColumns="160px 1fr 160px" paddingTop="24px" minHeight="136px">
      <Button
        appearance="subtle"
        style={{
          width: "fit-content",
          visibility: selectedCard || activeItemState === ItemState.FINAL_ESTIMATION ? "visible" : "hidden",
          transform: "translateY(-20px)",
        }}
        iconBefore={isEditMode ? <MdyUndoIcon size="small" /> : <AkEditIcon size="small" />}
        onClick={setEditMode}
      >
        {isEditMode ? "Back" : "Edit personal vote"}
      </Button>
      <CardDeck
        cards={cards}
        isEditMode={isEditMode}
        participantVotes={filteredParticipantsVotes}
        selected={selectedCard}
        onCardClicked={onCardClicked}
        onCardMouseEnter={onMouseEnter}
        onCardMouseLeave={onMouseLeave}
        onEdit={setEditMode}
      />
    </Box>
  );
}
