import { Attachment } from "../types";

function isAbsoluteUrl(url: string) {
  return /^[a-z][a-z0-9+.-]*:/.test(url);
}

function isRelativeUrl(url: string) {
  return !isAbsoluteUrl(url);
}

const IMAGE_ERROR_REGEXP = /File \((?<filename>.+)\) not found/;

export function parseHtmlFromString({
  text = "",
  baseURL = "",
  attachments = [],
}: {
  text: string;
  baseURL: string;
  attachments: Attachment[];
}) {
  const withBaseURL = (path: string) => `${baseURL}${path}`;

  const doc = new DOMParser().parseFromString(text, "text/html");

  doc.querySelectorAll("a[file-preview-type]").forEach((a) => {
    const img = a.querySelector("img");
    const href = a.getAttribute("href");
    if (img && href) {
      img.setAttribute("src", isRelativeUrl(href) ? withBaseURL(href) : href);
      a.replaceWith(img);
    }
  });

  doc.querySelectorAll("a").forEach((a) => {
    const href = a.getAttribute("href");

    if (href) {
      a.setAttribute("target", "_blank");
      if (isRelativeUrl(href)) {
        a.setAttribute("href", withBaseURL(a.pathname));
      }
    }
  });

  doc.querySelectorAll('img[src^="/"]').forEach((e) => {
    const src = e.getAttribute("src");
    if (src) {
      e.setAttribute("src", withBaseURL(src));
    }
  });

  doc.querySelectorAll('object[type^="video/"]').forEach((e) => {
    const videoElement = document.createElement("video");
    const data = e.getAttribute("data");
    if (data) {
      videoElement.setAttribute("src", withBaseURL(data));
      videoElement.setAttribute("preload", "auto");
      videoElement.setAttribute("controls", "");

      e.replaceWith(videoElement);
    }
  });

  doc.querySelectorAll("span.error").forEach((element) => {
    const innerText = element.textContent?.trim();
    if (innerText?.startsWith("Unable to render embedded object")) {
      const result = innerText.match(IMAGE_ERROR_REGEXP) as unknown as { groups: { filename: string } };
      if (result) {
        const { filename } = result.groups;
        const attachment = attachments.find((a) => a.filename === filename);
        if (attachment) {
          const image = document.createElement("img");
          image.setAttribute("src", attachment.content);
          image.setAttribute("width", "100%");
          const imageWrapper = document.createElement("span");
          imageWrapper.classList.add("image-wrap");
          imageWrapper.appendChild(image);
          element.replaceWith(imageWrapper);
        }
      }
    }
  });

  return doc.body.innerHTML;
}
