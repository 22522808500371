import React, { useCallback, useMemo, useState } from "react";
import styled, { css } from "styled-components";

import Button, { IconButton, SplitButton } from "@atlaskit/button/new";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";
import EditorEditIcon from "@atlaskit/icon/glyph/editor/edit";
import RecentIcon from "@atlaskit/icon/glyph/recent";
import VidPauseIcon from "@atlaskit/icon/glyph/vid-pause";

import {
  useTimerUpdateAutoStartValue,
  useTimerUpdateCustomDurationValue,
  useTimerUpdateSelectedDuration,
} from "../../../../../services/firebase";
import { SwitchElement } from "../../../../shared/SwitchElement";
import { useGameData } from "../../../GameProvider";
import { ButtonMute } from "./components/ButtonMute";
import { CustomDurationInput } from "./components/CustomDurationInput";
import { useTimerRunningState } from "./hooks/useTimerRunningState";
const DEFAULT_DURATIONS_LIST = [
  { value: 10_000, displayValue: "10s" },
  { value: 60_000, displayValue: "60s" },
] as const;

const TimerControlContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TimerAutoStartContainer = styled.div`
  padding: 4px 12px;
`;

interface TimerVisualProps {
  $isRunning?: boolean;
}

// Based on Button used in admin controls
const TimerVisual = styled.div<TimerVisualProps>`
  padding-block: var(--ds-space-0, 0px);
  padding-inline-end: var(--ds-space-150, 12px);
  padding-inline-start: var(--ds-space-150, 8px);
  border-radius: var(--ds-border-radius-100, 3px);

  transition: background-color 0.1s ease-out;
  ${(p) =>
    p.$isRunning
      ? css`
          color: var(--ds-text-inverse, #ffffff);
          background-color: var(--ds-background-brand-bold, #0052cc);
        `
      : css`
          color: var(--ds-text, #42526e);
          background-color: var(--ds-background-neutral, rgba(9, 30, 66, 0.04));
        `}
`;

const TimerText = styled.span`
  min-width: 78px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: var(--ds-space-050, 4px);
  line-height: 2.2857142857142856em;
  font-family: monospace;
  font-weight: 500;
`;

interface TimerControlsProps {
  className?: string;
  isGameAdmin?: boolean;
  onTimerEnd?: () => void;
}

export function TimerControls({ className, isGameAdmin, onTimerEnd }: TimerControlsProps) {
  const { game, gameId } = useGameData();
  const { selectedDuration, customDurationValue, autoStartOnNextRound } = game.timer;

  const selectedDurationDisplay = useMemo(() => `${selectedDuration / 1000}s`, [selectedDuration]);
  const customDurationValueInSec = useMemo(() => customDurationValue / 1000, [customDurationValue]);
  const customDurationValueDisplay = useMemo(() => `${customDurationValueInSec}s`, [customDurationValueInSec]);

  const [showCustomDurationInput, setShowCustomDurationInput] = useState(false);
  const updateCustomDurationValue = useTimerUpdateCustomDurationValue(gameId);
  const updateSelectedDuration = useTimerUpdateSelectedDuration(gameId);
  const updateAutoStartTimer = useTimerUpdateAutoStartValue(gameId);
  const setAndShowCustomDuration = useCallback(
    (val: number) => {
      void updateCustomDurationValue(val);
      void updateSelectedDuration(val);
      setShowCustomDurationInput(false);
    },
    [updateCustomDurationValue, updateSelectedDuration],
  );

  const audio = useMemo(() => new Audio(), []);
  const { isRunning, displayValue, startTimer, stopTimer } = useTimerRunningState({ audio, onTimerEnd });

  if (!isGameAdmin) {
    return (
      <TimerControlContainer className={className}>
        <TimerVisual $isRunning={isRunning}>
          <TimerText>
            <RecentIcon label="Recent" />
            {isRunning ? displayValue : selectedDurationDisplay}
          </TimerText>
        </TimerVisual>
        <ButtonMute audio={audio} />
      </TimerControlContainer>
    );
  }

  return (
    <TimerControlContainer className={className}>
      {showCustomDurationInput ? (
        <CustomDurationInput
          defaultValue={customDurationValueInSec}
          onSet={setAndShowCustomDuration}
          onCancel={() => setShowCustomDurationInput(false)}
        />
      ) : (
        <SplitButton appearance={isRunning ? "primary" : undefined}>
          <Button onClick={isRunning ? stopTimer : startTimer}>
            <TimerText>
              {isRunning ? (
                <>
                  <VidPauseIcon label="Pause" />
                  {displayValue}
                </>
              ) : (
                <>
                  <RecentIcon label="Recent" />
                  {selectedDurationDisplay}
                </>
              )}
            </TimerText>
          </Button>
          <DropdownMenu<HTMLButtonElement>
            trigger={({ triggerRef, ...triggerProps }) => (
              <IconButton
                ref={triggerRef}
                {...triggerProps}
                icon={ChevronDownIcon}
                isDisabled={isRunning}
                label="More update options"
              />
            )}
            spacing="compact"
            placement="bottom-end"
          >
            <DropdownItemGroup>
              {DEFAULT_DURATIONS_LIST.map(({ value, displayValue }) => (
                <DropdownItem key={value} onClick={() => void updateSelectedDuration(value)}>
                  {displayValue}
                </DropdownItem>
              ))}
            </DropdownItemGroup>
            <DropdownItemGroup hasSeparator>
              <DropdownItem onClick={() => void updateSelectedDuration(customDurationValue)}>
                Custom: {customDurationValueDisplay}
              </DropdownItem>
              <DropdownItem
                elemBefore={<EditorEditIcon label="Edit custom time" />}
                onClick={() => setShowCustomDurationInput(true)}
              >
                Edit custom time
              </DropdownItem>
            </DropdownItemGroup>

            <DropdownItemGroup hasSeparator>
              <TimerAutoStartContainer>
                <SwitchElement
                  label="Start timer automatically"
                  value={autoStartOnNextRound}
                  onToggle={() => void updateAutoStartTimer(!autoStartOnNextRound)}
                  id="start-timer-automatically"
                />
              </TimerAutoStartContainer>
            </DropdownItemGroup>
          </DropdownMenu>
        </SplitButton>
      )}
      <ButtonMute audio={audio} />
    </TimerControlContainer>
  );
}
