import React, { useMemo } from "react";
import styled from "styled-components";

import { AfLogoPlanningPokerIcon, AkWatchIcon } from "@fuegokit/fuegoicons-react";
import { Box, themeGet } from "@fuegokit/react";

import { GAME_HEADER_HEIGHT } from "../GameHeader";
import { useGameData } from "../GameProvider";
import { ChangeRoleButton } from "./ChangeRoleButton";
import { COLLAPSED_PANEL_WIDTH, EXPANDED_PANEL_WIDTH, TRANSITION_TIMEOUT } from "./constants";
import { ExpandIcon } from "./ExpandIcon";
import { useIsCurrentUserSpectator } from "./hooks/useIsCurrentUserSpectator";
import { useSortedParticipants } from "./hooks/useSortedParticipants";
import { PanelHeader } from "./PanelHeader";
import { ParticipantsGroup } from "./ParticipantsGroup";

const ParticipantsPanelWrapper = styled.div<{ $isExpanded: boolean }>`
  overflow: visible;
  position: fixed;
  right: 0;
  top: 0;
  width: ${(props) => (props.$isExpanded ? EXPANDED_PANEL_WIDTH : COLLAPSED_PANEL_WIDTH)};
  transition: width ${TRANSITION_TIMEOUT};
  z-index: var(--z-index-layer-1);

  &:hover,
  &:hover > :first-child {
    ${(props) => (props.$isExpanded ? "" : "width: 96px;")}
  }

  &:hover > :last-child {
    opacity: 1;
  }
`;

const ParticipantsPanelContainer = styled.div<{ $isExpanded: boolean }>`
  width: ${(props) => (props.$isExpanded ? EXPANDED_PANEL_WIDTH : COLLAPSED_PANEL_WIDTH)};
  height: calc(100vh - ${GAME_HEADER_HEIGHT});
  background: ${themeGet("colors.background.input.default")};
  border-radius: 3px ${themeGet("radii.0")} ${themeGet("radii.0")} 3px;
  box-shadow: var(--ds-shadow-overlay);
  position: fixed;
  right: 0;
  top: ${GAME_HEADER_HEIGHT};
  transition: width ${TRANSITION_TIMEOUT};
  padding: 8px 0;
  overflow: hidden;

  &:hover {
    overflow-y: auto;
  }
`;

interface ParticipantsPanelProps {
  isExpanded: boolean;
  onClick: () => void;
}

export function ParticipantsPanel({ isExpanded, onClick }: Readonly<ParticipantsPanelProps>) {
  const participants = useSortedParticipants();
  const { usersDetails } = useGameData();

  const onlineParticipants = useMemo(() => participants.filter((participant) => participant.instances), [participants]);

  const estimators = useMemo(() => participants.filter((participant) => !participant.isSpectator), [participants]);

  const spectators = useMemo(() => participants.filter((participant) => participant.isSpectator), [participants]);
  const isCurrentUserSpectator = useIsCurrentUserSpectator();

  return (
    <ParticipantsPanelWrapper $isExpanded={isExpanded}>
      <ParticipantsPanelContainer $isExpanded={isExpanded}>
        <PanelHeader isExpanded={isExpanded} onlineParticipantsCount={onlineParticipants.length} />
        <Box marginTop="16px">
          <ParticipantsGroup
            groupName="estimator"
            participants={estimators}
            isExpanded={isExpanded}
            usersDetails={usersDetails}
            groupIcon={<AfLogoPlanningPokerIcon />}
            actionButton={
              isCurrentUserSpectator ? (
                <ChangeRoleButton isUserSpectator={isCurrentUserSpectator} isExpanded={isExpanded} />
              ) : undefined
            }
          />
          <ParticipantsGroup
            groupName="spectator"
            participants={spectators}
            isExpanded={isExpanded}
            usersDetails={usersDetails}
            groupIcon={<AkWatchIcon />}
            actionButton={
              !isCurrentUserSpectator ? (
                <ChangeRoleButton isUserSpectator={isCurrentUserSpectator} isExpanded={isExpanded} />
              ) : undefined
            }
          />
        </Box>
      </ParticipantsPanelContainer>
      <ExpandIcon onClick={onClick} isExpanded={isExpanded} />
    </ParticipantsPanelWrapper>
  );
}
