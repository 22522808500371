import React from "react";

import { Box } from "@fuegokit/react";

export const EmptyBacklog = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
      <img src="/icon/add-issues.svg" alt="add-issues" />
      <Box py={4} textAlign="center">
        <h3>Empty game backlog</h3>
        <p>Add issues to game backlog to proceed</p>
      </Box>
      {children}
    </Box>
  );
};
