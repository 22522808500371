import { useCallback } from "react";

import { GameFormConfig } from "../../../types";
import { GameEdited } from "../../ampli";
import { useGetGameConfigAmplitudeProperties } from "../useGetGameConfigAmplitueProperties";
import { useRecordEvent } from "./useRecordEvent";

export function useGameEditedEvent() {
  const recordEvent = useRecordEvent(GameEdited);
  const getGameConfigAmplitudeProperties = useGetGameConfigAmplitudeProperties();

  return useCallback(
    (updatedGameForm: GameFormConfig, gameId: string) => {
      const gameProperties = getGameConfigAmplitudeProperties(updatedGameForm);
      recordEvent({ ...gameProperties, game_id: gameId });
    },
    [getGameConfigAmplitudeProperties, recordEvent],
  );
}
