// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comments .new-comment {
    font-weight: 600;
    cursor: pointer;
}

.comments .flex-align-center {
    display: flex;
    align-items: center;
}


.comments .details {
    gap: 5px;
}

.comments .created {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 0;
    flex: 1;
}

.comments .comment-body {
    margin-left: 34px;
    margin-bottom: 8px;
}

.comments .display-name {
    font-weight: 600;
    flex-shrink: 0;
}

.comments .buttons-group {
    padding-top: 10px;
    display: flex;
    gap: 5px;
    justify-content: flex-end;
}

.comment-counter {
    display: flex;
    align-items: center;
    background: var(--ds-background-neutral, rgba(9, 30, 66, 0.04));
    border-radius: 3px;
    padding: 0 4px;
    gap: 3px;
}

.comments .comments-expander {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    padding-top: 6px;
    padding-bottom: 16px;

}

.comments .comments-expander .title {
    margin-left: 8px;
}

.comments .comments-list {
    margin-bottom: 16px;
}

`, "",{"version":3,"sources":["webpack://./src/components/Comments.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;;AAGA;IACI,QAAQ;AACZ;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,YAAY;IACZ,OAAO;AACX;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,QAAQ;IACR,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,+DAA+D;IAC/D,kBAAkB;IAClB,cAAc;IACd,QAAQ;AACZ;;AAEA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,oBAAoB;;AAExB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".comments .new-comment {\n    font-weight: 600;\n    cursor: pointer;\n}\n\n.comments .flex-align-center {\n    display: flex;\n    align-items: center;\n}\n\n\n.comments .details {\n    gap: 5px;\n}\n\n.comments .created {\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    min-width: 0;\n    flex: 1;\n}\n\n.comments .comment-body {\n    margin-left: 34px;\n    margin-bottom: 8px;\n}\n\n.comments .display-name {\n    font-weight: 600;\n    flex-shrink: 0;\n}\n\n.comments .buttons-group {\n    padding-top: 10px;\n    display: flex;\n    gap: 5px;\n    justify-content: flex-end;\n}\n\n.comment-counter {\n    display: flex;\n    align-items: center;\n    background: var(--ds-background-neutral, rgba(9, 30, 66, 0.04));\n    border-radius: 3px;\n    padding: 0 4px;\n    gap: 3px;\n}\n\n.comments .comments-expander {\n    display: inline-flex;\n    align-items: center;\n    cursor: pointer;\n    font-weight: 500;\n    padding-top: 6px;\n    padding-bottom: 16px;\n\n}\n\n.comments .comments-expander .title {\n    margin-left: 8px;\n}\n\n.comments .comments-list {\n    margin-bottom: 16px;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
