// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body,
#root {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

:root {
    --z-index-layer-1: 510;
    --z-index-layer-2: 999;
    --z-index-layer-3: 9999;
    --z-index-layer-4: 99999;
}

html[data-color-mode="light"][data-theme~="light:light"], html[data-color-mode="dark"][data-theme~="dark:light"] {
    --pp-card-border: #CCE0FF;
    --pp-card-border-hovered: #85B8FF;
    --pp-card-border-selected: #1D7AFC;
}

html[data-color-mode="light"][data-theme~="light:dark"], html[data-color-mode="dark"][data-theme~="dark:dark"] {
    --pp-card-border: #85B8FF;
}
`, "",{"version":3,"sources":["webpack://./src/integrations/main.css"],"names":[],"mappings":"AAAA;;;IAGI,iJAAiJ;IACjJ,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,MAAM;IACN,gBAAgB;IAChB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;IACtB,sBAAsB;IACtB,uBAAuB;IACvB,wBAAwB;AAC5B;;AAEA;IACI,yBAAyB;IACzB,iCAAiC;IACjC,kCAAkC;AACtC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["html,\nbody,\n#root {\n    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;\n    position: absolute;\n    left: 0;\n    right: 0;\n    top: 0;\n    min-height: 100%;\n    display: flex;\n    flex-direction: column;\n}\n\n:root {\n    --z-index-layer-1: 510;\n    --z-index-layer-2: 999;\n    --z-index-layer-3: 9999;\n    --z-index-layer-4: 99999;\n}\n\nhtml[data-color-mode=\"light\"][data-theme~=\"light:light\"], html[data-color-mode=\"dark\"][data-theme~=\"dark:light\"] {\n    --pp-card-border: #CCE0FF;\n    --pp-card-border-hovered: #85B8FF;\n    --pp-card-border-selected: #1D7AFC;\n}\n\nhtml[data-color-mode=\"light\"][data-theme~=\"light:dark\"], html[data-color-mode=\"dark\"][data-theme~=\"dark:dark\"] {\n    --pp-card-border: #85B8FF;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
