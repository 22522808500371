import React, { ChangeEvent, KeyboardEvent, useCallback, useState } from "react";
import styled from "styled-components";

import { IconButton } from "@atlaskit/button/new";
import CheckIcon from "@atlaskit/icon/glyph/check";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import TextField from "@atlaskit/textfield";

const CustomDurationInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

interface CustomDurationInputProps {
  defaultValue: number;
  onSet?: (val: number) => void;
  onCancel?: () => void;
}

export function CustomDurationInput({ defaultValue, onSet, onCancel }: CustomDurationInputProps) {
  const [customDurationValue, setCustomDurationValue] = useState(defaultValue);
  const updateCustomDurationValue = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCustomDurationValue(e.currentTarget.valueAsNumber || 1);
  }, []);
  const saveOnEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== "Enter") return;

      // Convert s to ms
      onSet?.(e.currentTarget.valueAsNumber * 1000);
    },
    [onSet],
  );
  const onSetWrapper = useCallback(() => onSet?.(customDurationValue * 1000), [customDurationValue, onSet]);

  return (
    <CustomDurationInputContainer>
      <TextField
        value={customDurationValue}
        min={1}
        width={120}
        type="number"
        placeholder="Set the timer"
        isCompact
        onChange={updateCustomDurationValue}
        onKeyUp={saveOnEnter}
      />
      <IconButton icon={() => <CheckIcon label="Check" size="large" />} label="Set" onClick={onSetWrapper} />
      <IconButton
        icon={() => <CrossIcon label="Cross" size="large" />}
        appearance="subtle"
        label="Cancel"
        onClick={onCancel}
      />
    </CustomDurationInputContainer>
  );
}
