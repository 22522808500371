import React from "react";

import Lozenge, { ThemeAppearance } from "@atlaskit/lozenge";

import { GameState } from "../../../types";

interface StateCellProps {
  gameState: GameState;
}

type GameStateMap = {
  [key in GameState]: ThemeAppearance;
};

const gameStateMap: GameStateMap = {
  [GameState.ACTIVE]: "success",
  [GameState.FINISHED]: "default",
};

export function StateCell({ gameState }: StateCellProps) {
  const appearance: ThemeAppearance = gameStateMap[gameState];

  return (
    <Lozenge appearance={appearance} isBold>
      {gameState}
    </Lozenge>
  );
}
