import React from "react";

import Button from "@atlaskit/button";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from "@atlaskit/modal-dialog";

interface LeaveCreateFormModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export function LeaveCreateFormModal({ isOpen, onClose, onConfirm }: Readonly<LeaveCreateFormModalProps>) {
  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={onClose}>
          <ModalHeader>
            <ModalTitle appearance="warning">Leave game creation</ModalTitle>
          </ModalHeader>
          <ModalBody>By confirming, you will leave the game creation process and lose current progress.</ModalBody>
          <ModalFooter>
            <Button appearance="subtle" onClick={onClose}>
              Cancel
            </Button>
            <Button appearance="warning" onClick={onConfirm}>
              Confirm
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
}
