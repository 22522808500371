import React from "react";

import { useGameJoinedEvent } from "../analytics/hooks/events/useGameJoinedEvent";
import { GameControls } from "../components/game/GameControls";
import { GameHeader } from "../components/game/GameHeader";

export function Game() {
  useGameJoinedEvent();

  return (
    <>
      <GameHeader />
      <GameControls />
    </>
  );
}
