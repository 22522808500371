import React, { useMemo } from "react";
import styled from "styled-components";

import { EnrichedParticipant } from "@appfire/poker-core";
import Tooltip from "@atlaskit/tooltip";
import { AkPersonIcon } from "@fuegokit/fuegoicons-react";
import { Heading } from "@fuegokit/react";

import { GamePersona } from "../../../types/common";

interface MoreVotesIndicator {
  notVisibleParticipants: EnrichedParticipant<GamePersona>[];
}

const MoreVotesIndicatorWrapper = styled.div`
  height: 76px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  padding: 0 12px;
`;

const TooltipContent = styled.div`
  white-space: pre-wrap;
`;

export function MoreVotesIndicator({ notVisibleParticipants }: MoreVotesIndicator) {
  const tooltipText = useMemo(
    () => notVisibleParticipants.map((participant) => participant.displayName).join("\n"),
    [notVisibleParticipants],
  );

  return (
    <Tooltip content={<TooltipContent>{tooltipText}</TooltipContent>}>
      <MoreVotesIndicatorWrapper>
        <Heading variant="h500">+{notVisibleParticipants.length}</Heading>
        <AkPersonIcon />
      </MoreVotesIndicatorWrapper>
    </Tooltip>
  );
}
