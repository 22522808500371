import React, { useCallback } from "react";

import { EstimationHeaderProps } from "@appfire/poker-core";
import { IconButton } from "@atlaskit/button/new";
import FlagFilledIcon from "@atlaskit/icon/glyph/flag-filled";
import Tooltip from "@atlaskit/tooltip";
import { AkTrashIcon, AkVidForwardIcon } from "@fuegokit/fuegoicons-react";

import { useRefetchIssuesById } from "../../../hooks/useRefetchIssuesById";
import { runSkipIssueAutomation } from "../../../services/automations";
import { JiraIssue } from "../../../types";
import { useGameData } from "../GameProvider";

function NextItemIcon() {
  return <AkVidForwardIcon />;
}

function RemoveItemIcon() {
  return <AkTrashIcon />;
}

function FinishGameIcon() {
  return <FlagFilledIcon label="Finish game" />;
}

type CommonActionsProps = Pick<
  EstimationHeaderProps<JiraIssue>,
  "nextItemOrFinish" | "removeActiveItem" | "isLastItem" | "activeItem"
>;

export function CommonActions({
  nextItemOrFinish,
  removeActiveItem,
  isLastItem,
  activeItem,
}: Readonly<CommonActionsProps>) {
  const { game } = useGameData();
  const { refetchIssue } = useRefetchIssuesById();

  const onSelectNextItem = useCallback(() => {
    void (async () => {
      const activeItemKey = activeItem?.key;
      await nextItemOrFinish();
      if (activeItemKey) {
        await runSkipIssueAutomation(activeItemKey, game.configuration, refetchIssue);
      }
    })();
  }, [activeItem?.key, game.configuration, nextItemOrFinish, refetchIssue]);

  return (
    <>
      <Tooltip content={isLastItem ? "Skip & finish game" : "Select next issue"}>
        <IconButton icon={isLastItem ? FinishGameIcon : NextItemIcon} onClick={onSelectNextItem} label="Next issue" />
      </Tooltip>
      <Tooltip content="Remove issue from game">
        <IconButton icon={RemoveItemIcon} onClick={() => void removeActiveItem()} label="Remove issue" />
      </Tooltip>
    </>
  );
}
