import { useMemo } from "react";

import { useJiraDataStore } from "../providers/JiraDataStoreProvider";

export const useEstimationField = (estimationFieldId: string) => {
  const { fields } = useJiraDataStore();

  const estimationField = useMemo(
    () => fields?.find((field) => field.id === estimationFieldId),
    [fields, estimationFieldId],
  );

  return estimationField;
};
