import React from "react";

import Avatar, { AvatarItem, SizeType } from "@atlaskit/avatar";
import { Box } from "@fuegokit/react";

import { JiraUser } from "../../../types";

interface AvatarItemCellProps {
  creator: JiraUser;
  size?: SizeType;
}

export function AvatarItemCell({ creator, size = "medium" }: AvatarItemCellProps) {
  const displayName = creator?.displayName || "Unknown account";
  const avatarUrl = creator?.avatarUrls["24x24"] || "";
  return (
    <Box width={210} data-private>
      <AvatarItem primaryText={displayName} avatar={<Avatar src={avatarUrl} name={displayName} size={size} />} />
    </Box>
  );
}
