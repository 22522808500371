import { useEffect, useMemo, useState } from "react";

import { useBacklogStore } from "../providers/BacklogStoreContextProvider";
import { GameConfig, JiraIssue } from "../types";
import { groupByIssueKey } from "../utils";

export function useQueryIssuesById(configuration: GameConfig) {
  const { queryIssuesByIds, backlogIssues } = useBacklogStore();
  const [issues, setIssues] = useState<JiraIssue[]>([]);
  const [isLoadingIssues, setIsLoadingIssues] = useState(true);
  const [issueKeys, setIssueKeys] = useState<string[]>(configuration.backlog || []);
  const columns = useMemo(() => configuration.backlogColumnIds || [], [configuration.backlogColumnIds]);
  const allColumns = useMemo(
    () => [configuration.estimationFieldId, ...columns],
    [configuration.estimationFieldId, columns],
  );

  useEffect(() => {
    void (async () => {
      setIsLoadingIssues(true);
      const issuesDetails = await queryIssuesByIds(issueKeys, allColumns);
      setIssues((prev) => {
        const grouped = groupByIssueKey(issuesDetails);
        const prevKeys = prev.map((issue) => issue.key);
        if (issuesDetails.length > prev.length) {
          return [...new Set([...prevKeys, ...issueKeys])].map((key) => grouped[key]).filter(Boolean);
        } else {
          return prevKeys
            .filter((key) => issueKeys.includes(key))
            .map((key) => grouped[key])
            .filter(Boolean);
        }
      });
      setIsLoadingIssues(false);
    })();
  }, [issueKeys, allColumns, queryIssuesByIds]);

  const updatedIssues = useMemo(() => {
    return issues.map((issue) => backlogIssues[issue.key] || issue);
  }, [issues, backlogIssues]);

  return {
    issues: updatedIssues,
    isLoadingIssues,
    setIssues,
    setIssueKeys,
  };
}
