import { useCallback } from "react";

import { useJiraDataStore } from "../providers/JiraDataStoreProvider";
import { getDefaultEstimationField, isEstimationFieldSupported } from "../utils";

export function useGetSupportedEstimationFieldId() {
  const { fields } = useJiraDataStore();

  return useCallback(
    (estimationFieldId: string) => {
      if (estimationFieldId === "comments") return estimationFieldId;
      const estimationField = fields?.find((field) => field.id === estimationFieldId);
      if (estimationField && isEstimationFieldSupported(estimationField)) {
        return estimationFieldId;
      }
      const defaultEstimationField = getDefaultEstimationField(fields);
      if (defaultEstimationField) {
        return defaultEstimationField.id;
      }
      return estimationFieldId;
    },
    [fields],
  );
}
