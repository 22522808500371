import React, { useEffect, useState } from "react";

import { Box } from "@fuegokit/react";

import { SwitchElement } from "./SwitchElement";

interface SwitchableOptionProps {
  name: string;
  label: string;
  explanation: string;
  onToggle: (name: string, isEnabled: boolean) => void;
  children: React.ReactNode;
  enabled?: boolean;
}

export function SwitchableOption({ name, label, explanation, onToggle, children, enabled }: SwitchableOptionProps) {
  const [isEnabled, setIsEnabled] = useState<boolean>(enabled ?? false);

  useEffect(() => {
    onToggle(name, isEnabled);
  }, [isEnabled, name, onToggle]);

  return (
    <section>
      <SwitchElement
        label={label}
        value={isEnabled}
        onToggle={() => setIsEnabled((prev) => !prev)}
        explanation={explanation}
        id={name}
      />
      {isEnabled && <Box style={{ marginTop: "4px" }}>{children}</Box>}
    </section>
  );
}
