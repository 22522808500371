import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from "react";

import { getIssuesByIds, showFlag } from "../services/jira-api";
import { JiraIssue } from "../types";
import { allIncluded, compareArrays, normalizeBy, separateFoundAndMissing } from "../utils";

type StoredIssueData = JiraIssue & { additionalFetchedFields: string[] };
type IssuesStore = Record<string, StoredIssueData>;

interface BacklogStoreContextData {
  queryIssuesByIds: (issuesIds: string[], additionalFields?: string[], useCache?: boolean) => Promise<JiraIssue[]>;
  backlogIssues: IssuesStore;
  setBacklogIssues: React.Dispatch<React.SetStateAction<IssuesStore>>;
}

const BacklogStoreContext = createContext<BacklogStoreContextData | null>(null);

interface BacklogStoreContextProviderProps {
  children?: React.ReactNode;
}

const handleNotFetchedIssues = (fetchedIssues: JiraIssue[], attemptedToFetchKeys: string[]) => {
  const { removedItems } = compareArrays(
    attemptedToFetchKeys,
    fetchedIssues.map((i) => i.key),
  );
  if (removedItems.length) {
    showFlag(`Failed to fetch issues with ids: ${removedItems.join(", ")}`, "", "error");
  }
};

export const BacklogStoreContextProvider = ({ children }: BacklogStoreContextProviderProps) => {
  const [backlogIssues, setBacklogIssues] = useState<IssuesStore>({});
  const issuesRef = useRef<IssuesStore>({});

  const queryIssuesByIds = useCallback(async (issuesIds: string[], additionalFields?: string[], useCache = true) => {
    const issues = issuesRef.current;
    const { foundItems, missingIds } = useCache
      ? separateFoundAndMissing<StoredIssueData>(
          issuesIds,
          issues,
          (itemId) =>
            issues[itemId] && allIncluded(additionalFields ?? [], issues[itemId]?.additionalFetchedFields ?? []),
        )
      : { foundItems: [], missingIds: issuesIds };
    if (!missingIds.length) return foundItems;
    const missingIssues = await getIssuesByIds(missingIds, additionalFields);
    handleNotFetchedIssues(missingIssues, missingIds);
    const normalizedIssues = normalizeBy<StoredIssueData, "key">(
      missingIssues.map((issue) => ({ ...issue, additionalFetchedFields: additionalFields ?? [] })),
      "key",
    );
    issuesRef.current = { ...issues, ...normalizedIssues };
    setBacklogIssues((prev) => ({ ...prev, ...normalizedIssues }));
    return [...foundItems, ...missingIssues];
  }, []);

  const value = useMemo(
    () => ({
      queryIssuesByIds,
      backlogIssues,
      setBacklogIssues,
    }),
    [queryIssuesByIds, setBacklogIssues, backlogIssues],
  );

  return <BacklogStoreContext.Provider value={value}>{children}</BacklogStoreContext.Provider>;
};

export function useBacklogStore() {
  const context = useContext(BacklogStoreContext);
  if (!context) {
    throw new Error("Backlog store context not initialized, probably context provider is missing");
  }
  return context;
}
