import React, { ReactElement } from "react";

import SectionMessage, { SectionMessageAction } from "@atlaskit/section-message";
import { Box } from "@fuegokit/react";

const refreshPage = () => window.location.reload();

interface ErrorMessageProps {
  title: string;
  text: ReactElement | string;
  actions?: ReactElement[];
}

export function ErrorMessage({ title, text, actions }: Readonly<ErrorMessageProps>) {
  return (
    <Box padding="10px" display="flex" justifyContent="center">
      <Box maxWidth="1000px" width="100%">
        <SectionMessage
          title={title}
          appearance="error"
          actions={[
            <SectionMessageAction key="1" onClick={refreshPage}>
              Reload app
            </SectionMessageAction>,
            ...(actions ?? []),
          ]}
        >
          <p>{text}</p>
        </SectionMessage>
      </Box>
    </Box>
  );
}
