import React from "react";

import { Box } from "@fuegokit/react";

import { ChangelogEntry } from "./ChangelogEntry";
import { ChangelogItem } from "./index";

const date = new Date("2022-04-08");

function April2022() {
  return (
    <ChangelogEntry title="April 2022 update" date={date}>
      <Box display="flex">
        <Box flex="2 1 0">
          <p>Planning Poker has received multiple experience improvements, including: </p>
          <ul>
            <li>
              New <strong>in-game backlog experience</strong>: unestimated backlog and game backlog have been merged,
              the single backlog received a new layout and customizable columns; issues with{" "}
              <strong>Done status</strong> are marked accordingly;
            </li>
            <li>
              Improved <strong>estimation cards layout</strong>;
            </li>
            <li>
              <strong>Open issues in Jira</strong> option for finished games for further edit and bulk edit actions;
            </li>
            <li>
              Improved <strong>Previously estimated</strong> feature: new priority rules for shown issues, improved
              layout;
            </li>
            <li>
              <strong>Edit issue</strong> option that opens a modal with full issue details that supports edit and all
              standard Jira’s actions.
            </li>
          </ul>
        </Box>
        <Box textAlign="center" flex="1 1 0">
          <img src="/images/whats-new-april.png" alt="whats-new-april" width="100%" />
          <p>New finished game look</p>
        </Box>
      </Box>
    </ChangelogEntry>
  );
}

export default {
  Component: April2022,
  date,
} as ChangelogItem;
