import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useApplicationContext } from "../../../../providers/ApplicationContextProvider";
import { debouncedShowFlag, showFlag } from "../../../../services/jira-api";
import { Game, GameParticipant } from "../../../../types";
import { debouncedShowFlagGameDisconnected } from "../../../../utils/game";
import { useGameData } from "../../GameProvider";
import { useSavedEstimationFlag } from "./useSavedEstimationFlag";

const useCheckSettingsFlag = (game: Game) => {
  const currentGameFormConfig = useMemo(() => {
    // Parts of the config must be stripped because we have different flags for those
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { backlog, backlogColumnIds, jql, ...stripedConfig } = game.configuration;
    return stripedConfig;
  }, [game.configuration]);
  const previousGameFormConfig = useRef(currentGameFormConfig);
  const checkFlag = useCallback(() => {
    const changedGameSettings = !isEqual(currentGameFormConfig, previousGameFormConfig.current);
    previousGameFormConfig.current = currentGameFormConfig;

    if (changedGameSettings) {
      void debouncedShowFlag("Game updated", "Game configuration updated", "success");
    }
  }, [currentGameFormConfig]);

  return checkFlag;
};

const useCheckBacklogEditFlag = (game: Game) => {
  const currentBacklogConfiguration = useMemo(() => {
    const { backlog, backlogColumnIds } = game.configuration;
    return { backlog, backlogColumnIds };
  }, [game.configuration]);
  const previousBacklogConfiguration = useRef(currentBacklogConfiguration);
  return useCallback(() => {
    const isBacklogChanged = !isEqual(currentBacklogConfiguration, previousBacklogConfiguration.current);
    previousBacklogConfiguration.current = currentBacklogConfiguration;

    if (isBacklogChanged) {
      showFlag("Game updated", "Game backlog has been updated", "success");
    }
  }, [currentBacklogConfiguration]);
};

const useCheckSpectatorFlag = (participant?: GameParticipant) => {
  const previousIsSpectator = useRef(participant?.isSpectator);
  const checkFlag = useCallback(() => {
    const currentIsSpectator = participant?.isSpectator;
    const changedIsSpectator = currentIsSpectator !== previousIsSpectator.current;
    previousIsSpectator.current = currentIsSpectator;

    if (changedIsSpectator) {
      showFlag(
        "User role changed",
        currentIsSpectator ? "You have become a Spectator" : "You have become an Estimator",
        "info",
      );
    }
  }, [participant?.isSpectator]);

  return checkFlag;
};

const useCheckAdminFlag = (game: Game, userAccountId: string) => {
  const currentIsAdmin = useMemo(
    () => Boolean(game.configuration.admins[userAccountId]),
    [game.configuration.admins, userAccountId],
  );
  const previousIsAdmin = useRef(currentIsAdmin);
  const checkFlag = useCallback(() => {
    const changedIsAdmin = currentIsAdmin !== previousIsAdmin.current;
    previousIsAdmin.current = currentIsAdmin;
    if (changedIsAdmin) {
      showFlag(
        "User role changed",
        currentIsAdmin
          ? "You have been added to the list of game admins"
          : "You have been removed from the list of game admins",
        "info",
      );
    }
  }, [currentIsAdmin]);

  return checkFlag;
};

// TODO: https://appfire.atlassian.net/browse/POK-618
export const useGameFlags = () => {
  const { game } = useGameData();
  const { userAccountId } = useApplicationContext();

  const currentParticipant: GameParticipant | undefined = useMemo(
    () => game.participants[userAccountId],
    [game.participants, userAccountId],
  );
  const navigate = useNavigate();
  const checkSpectatorFlag = useCheckSpectatorFlag(currentParticipant);
  const checkAdminFlag = useCheckAdminFlag(game, userAccountId);
  const checkSettingsFlag = useCheckSettingsFlag(game);
  const checkBacklogFlag = useCheckBacklogEditFlag(game);
  const checkSavedEstimationFlag = useSavedEstimationFlag(game.voting);
  useEffect(() => {
    if (!currentParticipant) {
      void debouncedShowFlagGameDisconnected();
      navigate("/");
      return;
    }

    checkSettingsFlag();
    checkSpectatorFlag();
    checkAdminFlag();
    checkBacklogFlag();
    checkSavedEstimationFlag();
  }, [
    checkAdminFlag,
    checkSettingsFlag,
    checkSpectatorFlag,
    currentParticipant,
    checkBacklogFlag,
    navigate,
    checkSavedEstimationFlag,
  ]);
};
