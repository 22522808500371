import React from "react";
import styled from "styled-components";

import Tabs, { Tab, TabList, TabPanel } from "@atlaskit/tabs";
import { Box } from "@fuegokit/react";

import { GameFormConfig } from "../../types";
import { FULL_PAGE_DIALOG_CONTROLS_WIDTH } from "../../utils";
import { SwitchElement } from "../shared/SwitchElement";
import { AdvancedSettingsForm } from "./AdvancedSettingsForm";
import { BasicGameConfigForm } from "./BasicGameConfigForm";
import { NameField } from "./NameField";

interface GameConfigFormProps {
  isNewGame?: boolean;
  gameConfig: GameFormConfig;
  onConfigChange: (toUpdate: Partial<GameFormConfig>) => void;
  onAdvancedSettingsTabClick?: () => void;
  gameCreatorId: string;
}

const TabsWrapper = styled.div`
  margin-left: -8px;
  margin-top: 16px;
  height: 100%;
  overflow: hidden;

  & > div {
    overflow: hidden;
    height: 100%;
  }
`;

export function GameConfigForm({
  isNewGame,
  gameConfig,
  onConfigChange,
  gameCreatorId,
  onAdvancedSettingsTabClick,
}: Readonly<GameConfigFormProps>) {
  return (
    <Box height="100%" display="flex" flexDirection="column" width={FULL_PAGE_DIALOG_CONTROLS_WIDTH}>
      <Box marginBottom="8px">
        <NameField value={gameConfig.name} onChange={(name: string) => onConfigChange({ name })} />
      </Box>
      <SwitchElement
        label="Private game"
        value={Boolean(gameConfig.private)}
        onToggle={() => onConfigChange({ private: !gameConfig.private })}
        id="private"
        explanation="Private games can be seen and accessed only by invited Estimators and Spectators."
      />
      <TabsWrapper>
        <Tabs id="game-config-tabs">
          <TabList>
            <Tab>Basic settings</Tab>
            <Tab>
              <div onClick={onAdvancedSettingsTabClick}>Advanced settings</div>
            </Tab>
          </TabList>
          <TabPanel>
            <Box height="100%" width="100%" overflowY="auto">
              <BasicGameConfigForm
                gameConfig={gameConfig}
                isNewGame={isNewGame}
                onConfigChange={onConfigChange}
                gameCreatorId={gameCreatorId}
              />
            </Box>
          </TabPanel>
          <TabPanel>
            <Box height="100%" width="100%" overflowY="auto">
              <AdvancedSettingsForm gameConfig={gameConfig} onConfigChange={onConfigChange} />
            </Box>
          </TabPanel>
        </Tabs>
      </TabsWrapper>
    </Box>
  );
}
