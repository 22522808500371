import React from "react";
import Confetti from "react-confetti";

interface ConsensusConfettiProps {
  show: boolean;
}

export function ConsensusConfetti({ show }: ConsensusConfettiProps) {
  return show ? <Confetti recycle={false} /> : null;
}
