import React, { PropsWithChildren } from "react";

import { SectionMessageAction } from "@atlaskit/section-message";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import { ErrorMessage } from "../components/shared/ErrorMessage";
import { getApplicationContext } from "../services/api";

const SUPPORT_URL = "https://appfire.atlassian.net/servicedesk/customer/portal/11/group/36/create/161";
const VERSION = process.env.BITBUCKET_COMMIT?.slice(0, 7);

Bugsnag.start({
  apiKey: process.env.BUGSNAG_API_KEY as string,
  appType: "client",
  appVersion: VERSION,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["production", "staging"],
  releaseStage: process.env.ENVIRONMENT,
  onError: async (event) => {
    try {
      const { clientKey, userAccountId } = await getApplicationContext();
      event.setUser(userAccountId);
      event.addMetadata("login", {
        clientKey,
        userAccountId,
      });
    } catch (err) {
      console.error("Failed to get application context", err);
    }
    return true;
  },
});

const bugsnagReactPlugin = Bugsnag.getPlugin("react");

if (!bugsnagReactPlugin) {
  throw new Error("Bugsnag React plugin failed to load");
}

const BugsnagErrorBoundary = bugsnagReactPlugin.createErrorBoundary(React);

export function ErrorBoundary({ children }: Readonly<PropsWithChildren<unknown>>) {
  return <BugsnagErrorBoundary FallbackComponent={FatalErrorMessage}>{children}</BugsnagErrorBoundary>;
}

interface FatalErrorMessageProps {
  error: Error;
}

export function FatalErrorMessage({ error }: Readonly<FatalErrorMessageProps>) {
  const openSupportPortal = () => window.open(SUPPORT_URL, "_blank", "noopener");
  return (
    <ErrorMessage
      title="Something went wrong."
      text={error.message}
      actions={[
        <SectionMessageAction key="2" onClick={openSupportPortal}>
          Contact support
        </SectionMessageAction>,
      ]}
    />
  );
}
