import React, { PropsWithChildren } from "react";

import { ParticipantVote } from "@appfire/poker-core";
import { Box } from "@fuegokit/react";

import { CardType, GamePersona } from "../../../types/common";
import { UnrevealedCard } from "./UnrevealedCard";

interface UnrevealedVotesProps {
  votes: ParticipantVote<GamePersona, CardType, string>[];
}

export function UnrevealedVotes({ votes, children }: PropsWithChildren<UnrevealedVotesProps>) {
  return (
    <Box display="flex" justifyContent="center" flexDirection="row" sx={{ gap: "100px" }}>
      {children}
      {votes.length ? (
        <Box display="flex" flexWrap="wrap" sx={{ gap: "8px" }}>
          {votes.map((participantVote) => (
            <UnrevealedCard
              key={participantVote.participant.id}
              className="card-non-interactive"
              participantDetails={participantVote.participant}
              voted={participantVote.hasVoted}
            />
          ))}
        </Box>
      ) : null}
    </Box>
  );
}
