import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useGameDelete } from "../../services/firebase";
import { showFlag } from "../../services/jira-api";
import { AbstractModal } from "../dashboard/modals/modals";

export function GameDeleteGameModal() {
  const { id } = useParams() as { id: string };

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate("..", { relative: "path" }), [navigate]);
  const goToDashboard = useCallback(() => navigate("/"), [navigate]);

  const deleteGames = useGameDelete();
  const confirmDeleteGames = useCallback(async () => {
    try {
      await deleteGames([id]);
      showFlag("Game deleted successfully", "The selected game has been successfully deleted", "success");
      goToDashboard();
    } catch (e) {
      console.error(e);
      showFlag("Failed to delete game", "An error occurred while trying to delete game", "error");
      goBack();
    }
  }, [deleteGames, goBack, goToDashboard, id]);

  return (
    <AbstractModal title="Delete game" width="small" isWarning onClose={goBack} onConfirm={confirmDeleteGames}>
      You&apos;re about to permanently delete the game, its progress, and all of its data.
    </AbstractModal>
  );
}
