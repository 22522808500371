import { useState } from "react";

import { getIssues, JiraApiError } from "../../services/jira-api";
import { FIELD } from "../../utils/backlog";

export const useQueryByIssueKey = (columnsIds: string[]) => {
  const [loading, setLoading] = useState(false);

  const fetch = async (issueKey: string) => {
    try {
      setLoading(true);

      const { issues } = await getIssues(
        `jql=${encodeURIComponent(`${FIELD.ISSUE_KEY} = ${issueKey}`)}&maxResults=1&fields=${columnsIds.join(",")}`,
      );

      return issues[0];
    } catch (error) {
      const jiraError = new JiraApiError(error);

      const message = jiraError.message.toLowerCase();

      if (message.includes("issue does not exist")) {
        throw new Error("Issue key not found");
      } else if (
        message.includes(`the issue key '${issueKey}' for field '${FIELD.ISSUE_KEY}' is invalid`.toLowerCase())
      ) {
        throw new Error(`Issue key is not valid`);
      } else {
        throw new Error("Something went wrong");
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    fetch,
  };
};
