import React, { createContext, useContext, useEffect, useState } from "react";

import { getFields } from "../services/jira-api";
import { JiraField } from "../types";

interface JiraDataStoreContextData {
  fields?: JiraField[];
  isLoading: boolean;
}

const JiraDataStoreContext = createContext<JiraDataStoreContextData | null>(null);

interface JiraDataStoreContextProviderProps {
  children?: React.ReactNode;
}

export const JiraDataStoreContextProvider = ({ children }: JiraDataStoreContextProviderProps) => {
  const [store, setStore] = useState<JiraDataStoreContextData>({ isLoading: true });

  useEffect(() => {
    void (async () => {
      try {
        const fields = await getFields();
        setStore((prev) => ({ ...prev, fields }));
      } catch (e) {
        console.error(e);
      } finally {
        setStore((prev) => ({ ...prev, isLoading: false }));
      }
    })();
  }, []);

  return <JiraDataStoreContext.Provider value={store}>{children}</JiraDataStoreContext.Provider>;
};

export function useJiraDataStore() {
  const context = useContext(JiraDataStoreContext);
  if (!context) {
    throw new Error("Jira data store context not initialized, probably context provider is missing");
  }
  return context;
}
