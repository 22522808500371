import React, { useCallback, useMemo, useState } from "react";

import Button, { IconButton } from "@atlaskit/button/new";
import Tooltip from "@atlaskit/tooltip";
import { AfTrophyIcon, AkEditorFileIcon } from "@fuegokit/fuegoicons-react";

import { useGameIssues } from "../GameIssuesProvider";
import { useGameData } from "../GameProvider";

interface SaveEstimationButtonsProps {
  isLoading: boolean;
  isDisabled: boolean;
  onSave: (goToNextIssue?: boolean, nextIssueId?: string | null) => void;
}

type LoadingButtons = "primary" | "secondary";
interface SaveEstimateProps {
  clickedLoadingButton: LoadingButtons;
  goToNextIssue?: boolean;
  nextIssueId?: string | null;
}

function SaveIcon() {
  return <AkEditorFileIcon />;
}

function FinishGameIcon() {
  return <AfTrophyIcon />;
}

export function SaveEstimationButtons({ isLoading, isDisabled, onSave }: Readonly<SaveEstimationButtonsProps>) {
  const [loadingButton, setLoadingButton] = useState<LoadingButtons>();
  const onEstimationSave = useCallback(
    ({ clickedLoadingButton, goToNextIssue, nextIssueId }: SaveEstimateProps) => {
      setLoadingButton(clickedLoadingButton);
      // Be careful, undefined does nothing while null sets empty item
      onSave(goToNextIssue, nextIssueId);
    },
    [onSave],
  );

  const { unestimatedIssues } = useGameIssues();
  const { game } = useGameData();
  const isLastUnestimatedItem = useMemo(() => {
    return (
      unestimatedIssues.length === 0 || (unestimatedIssues.length === 1 && game.activeItemId === unestimatedIssues[0])
    );
  }, [unestimatedIssues, game.activeItemId]);
  const isLastBacklogItem = useMemo(() => {
    const allItems = game.configuration.backlog;
    const activeItemId = game.activeItemId;

    if (!allItems || !activeItemId) return true;

    return game.configuration.hideEstimatedIssues
      ? unestimatedIssues.indexOf(activeItemId) === unestimatedIssues.length - 1
      : allItems.indexOf(activeItemId) === allItems.length - 1;
  }, [game.activeItemId, game.configuration.backlog, game.configuration.hideEstimatedIssues, unestimatedIssues]);

  // We changed the action here because customers finished games by accident
  if (isLastBacklogItem && !isLastUnestimatedItem) {
    return (
      <>
        <Button
          appearance="primary"
          isDisabled={isDisabled || isLoading}
          isLoading={isLoading && loadingButton === "primary"}
          onClick={() =>
            onEstimationSave({
              clickedLoadingButton: "primary",
              nextIssueId: game.configuration.hideEstimatedIssues ? null : undefined,
            })
          }
        >
          Save
        </Button>
        <Tooltip content="Save & finish">
          <IconButton
            testId="save-estimation-button"
            label="Save & finish"
            icon={FinishGameIcon}
            isDisabled={isDisabled || isLoading}
            isLoading={isLoading && loadingButton === "secondary"}
            onClick={() => onEstimationSave({ clickedLoadingButton: "secondary", goToNextIssue: true })}
          />
        </Tooltip>
      </>
    );
  }

  return (
    <>
      <Button
        testId="save-estimation-button"
        appearance="primary"
        isDisabled={isDisabled || isLoading}
        isLoading={isLoading && loadingButton === "primary"}
        onClick={() => onEstimationSave({ clickedLoadingButton: "primary", goToNextIssue: true })}
      >
        Save & {isLastUnestimatedItem ? "finish" : "next"}
      </Button>
      <Tooltip content="Save">
        <IconButton
          label="Save"
          icon={SaveIcon}
          isDisabled={isDisabled || isLoading}
          isLoading={isLoading && loadingButton === "secondary"}
          onClick={() =>
            onEstimationSave({
              clickedLoadingButton: "secondary",
              nextIssueId: game.configuration.hideEstimatedIssues ? null : undefined,
            })
          }
        />
      </Tooltip>
    </>
  );
}
