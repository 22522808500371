import React, { useEffect, useMemo, useState } from "react";

import { ErrorMessage, Field } from "@atlaskit/form";
import Select, { CreatableSelect } from "@atlaskit/select";
import { Box } from "@fuegokit/react";

import { DECK_TYPES, DECKS, getVoteSetName } from "../../../utils";
import { InfoTooltip } from "../InfoTooltip";

interface CardDeckSelectorProps {
  onUpdate: (config: { cards: string[] }) => void;
  currentCards: string[];
  isDisabled?: boolean;
  tooltip?: string;
}

const DropdownIndicator = () => {
  return (
    <Box marginRight="4px" color="var(--ds-text-subtle, #6b778c)">
      <InfoTooltip
        message="Type desired estimation value and confirm with ENTER.
Optionally, provide values mapping, e.g. “LABEL=VALUE”"
      />
    </Box>
  );
};

export function CardDeckSelector({ onUpdate, currentCards, isDisabled, tooltip }: Readonly<CardDeckSelectorProps>) {
  const [deckType, setDeckType] = useState<string>();

  const selectedDeckType = useMemo(() => DECK_TYPES.find((t) => t.value === deckType), [deckType]);

  useEffect(() => {
    const name = getVoteSetName(currentCards);
    setDeckType(name);
  }, [currentCards]);

  return (
    <>
      <div className="card-deck-select">
        <Field
          isRequired
          name="card-deck"
          label="Card deck"
          elementAfterLabel={
            tooltip && (
              <Box display="inline-flex" ml={1}>
                <InfoTooltip message={tooltip} />
              </Box>
            )
          }
        >
          {() => (
            <Select
              onChange={(option) => {
                if (option) {
                  setDeckType(option.value);
                  if (option.value !== "custom") {
                    onUpdate({
                      cards: DECKS[option.value] || [],
                    });
                  }
                }
              }}
              options={DECK_TYPES}
              value={selectedDeckType}
              placeholder="Choose an estimation field"
              isDisabled={isDisabled}
            />
          )}
        </Field>
      </div>
      <Box marginTop="8px">
        <CreatableSelect
          isMulti
          isClearable={false}
          placeholder="0,1,2,3..."
          onChange={(options) => {
            onUpdate({ cards: options.map((o) => o?.value) });
          }}
          value={currentCards?.map((value) => ({ label: value, value }))}
          noOptionsMessage={() => null}
          isDisabled={isDisabled}
          components={{ DropdownIndicator }}
          isInvalid={!currentCards.length}
        />
        {!currentCards.length && <ErrorMessage>At least one value must be defined.</ErrorMessage>}
      </Box>
    </>
  );
}
