import { useCallback, useMemo } from "react";

import { useJiraDataStore } from "../providers/JiraDataStoreProvider";
import { isFieldTypeSupported } from "../utils/fields";

export function useGetSupportedBacklogColumnIds() {
  const { fields } = useJiraDataStore();

  const supportedFieldsIds = useMemo(
    () => fields?.filter((field) => isFieldTypeSupported(field, [])).map((field) => field.id),
    [fields],
  );

  return useCallback(
    (backlogColumnIds: string[]) => backlogColumnIds.filter((columId) => supportedFieldsIds?.includes(columId)),
    [supportedFieldsIds],
  );
}
