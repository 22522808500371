import React from "react";

import { Box, Heading, Text } from "@fuegokit/react";

import { OpenIssuesInJira } from "./OpenIssuesInJira";

export function FinishedState() {
  return (
    <Box display="flex" justifyContent="center" marginBottom={24}>
      <Box display="flex" flexDirection="column" alignItems="center" sx={{ gap: 3 }}>
        <img src="/images/game_finished_state.svg" alt="Poker cards" width={132} height={132} />
        <Box display="flex" flexDirection="column" alignItems="center" sx={{ gap: 2 }}>
          <Heading variant="h600">Game finished</Heading>
          <Text textAlign="center">It looks like all issues have been estimated. Well done!</Text>
        </Box>
        <OpenIssuesInJira />
      </Box>
    </Box>
  );
}
