import React from "react";

import Tooltip from "@atlaskit/tooltip";

import { useEstimationField } from "../../../hooks/useEstimationField";
import { JiraIssue } from "../../../types";
import { getEstimationValue } from "../../../utils/estimation-values";

interface EstimationFieldProps {
  issue: JiraIssue;
  estimationFieldId: string;
}

export function EstimationFieldCell({ issue, estimationFieldId }: Readonly<EstimationFieldProps>) {
  const estimationField = useEstimationField(estimationFieldId);

  return (
    <Tooltip content={estimationField?.name}>
      <span>{getEstimationValue(issue.fields[estimationFieldId], estimationField)}</span>
    </Tooltip>
  );
}
