import { useCallback } from "react";

import { useGameIssues } from "../components/game/GameIssuesProvider";
import { useBacklogStore } from "../providers/BacklogStoreContextProvider";
import { getIssuesByIds } from "../services/jira-api";

export function useRefetchIssuesById() {
  const { setBacklogIssues } = useBacklogStore();
  const { fields } = useGameIssues();

  const refetchIssue = useCallback(
    async (issueId: string) => {
      const [refetchedIssue] = await getIssuesByIds([issueId], fields);
      if (refetchedIssue) {
        setBacklogIssues((prev) => ({
          ...prev,
          [refetchedIssue.key]: { ...refetchedIssue, additionalFetchedFields: fields ?? [] },
        }));
      }
    },
    [fields, setBacklogIssues],
  );

  return {
    refetchIssue,
  };
}
