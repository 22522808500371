import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@atlaskit/button";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "@atlaskit/modal-dialog";

import { useUpdateChangelogNumberUser } from "../../utils/changelog";
import { ChangelogModalContent } from "./ChangelogModalContent";

export function ChangelogModal() {
  const navigate = useNavigate();
  const closeModal = useCallback(() => navigate(".."), [navigate]);

  const updateChangelogNumberUser = useUpdateChangelogNumberUser();
  useEffect(() => {
    void updateChangelogNumberUser();
  }, [updateChangelogNumberUser]);

  return (
    <Modal onClose={closeModal} width="large">
      <ModalHeader>
        <ModalTitle>What&apos;s new</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <ChangelogModalContent />
      </ModalBody>
      <ModalFooter>
        <Button appearance="primary" onClick={closeModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
