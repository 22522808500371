import { MIME_TYPE } from "../types";

export function getFileName(str: string) {
  if (str.length > 10) {
    return str.substring(0, 10) + "..." + str.substring(str.length - 7);
  }
  return str;
}

export function getType(mimeType?: string) {
  if (mimeType?.startsWith("image")) {
    return MIME_TYPE.IMAGE;
  } else if (mimeType?.startsWith("video")) {
    return MIME_TYPE.VIDEO;
  } else {
    return MIME_TYPE.OTHER;
  }
}

function formatTime(date: Date) {
  return date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
}

export function formatDate(date: Date) {
  const day = date.toLocaleDateString("en-US", { day: "2-digit" });
  const month = date.toLocaleDateString("en-US", { month: "short" });
  const year = date.toLocaleDateString("en-US", { year: "numeric" });

  return `${day} ${month} ${year}`;
}

export function formatDateAndTime(time: Date) {
  const date = new Date(time);

  const formattedDate = formatDate(date);
  const formattedTime = formatTime(date);

  return `${formattedDate}, ${formattedTime.toLowerCase()}`;
}
