import React, { useCallback, useMemo, useState } from "react";

import Button from "@atlaskit/button";
import { AkTrashIcon } from "@fuegokit/fuegoicons-react";
import { Box } from "@fuegokit/react";

import { showFlag } from "../../services/jira-api";
import { GameConfig, JiraIssue } from "../../types";
import { DEFAULT_COLUMNS } from "../../utils/backlog";
import { AddIssuesDialog } from "./AddIssuesDialog";
import { BacklogTable } from "./BacklogTable";
import { ColumnsSelector } from "./ColumnsSelector";
import { EmptyBacklog } from "./EmptyBacklog";

interface BacklogProps {
  gameConfig: GameConfig;
  onConfigChange: (toUpdate: Partial<GameConfig>) => void;
  issues: JiraIssue[];
  isLoadingIssues: boolean;
  onOrderChange: (items: JiraIssue[]) => void;
  setIssueKeys: React.Dispatch<React.SetStateAction<string[]>>;
  onBacklogChange?: VoidFunction;
}

export const Backlog = ({
  gameConfig,
  onConfigChange,
  issues,
  isLoadingIssues,
  onOrderChange,
  setIssueKeys,
  onBacklogChange,
}: BacklogProps) => {
  const [rowSelection, setRowSelection] = useState({});
  const selectedIssues = useMemo(() => Object.keys(rowSelection), [rowSelection]);
  const columnsIds = useMemo(
    () => [...DEFAULT_COLUMNS, ...(gameConfig.backlogColumnIds ?? [])],
    [gameConfig.backlogColumnIds],
  );

  const deleteButtonText = useMemo(() => {
    return `Remove (${selectedIssues.length})`;
  }, [selectedIssues.length]);

  const isBacklogEmpty = useMemo(() => {
    return !gameConfig.backlog?.length;
  }, [gameConfig.backlog]);

  function addMoreIssues(issueKeys: string[]) {
    showFlag("Issues added", `${issueKeys.length} issue(s) added to game backlog`, "success");
    void setIssueKeys((prev) => [...prev, ...issueKeys]);
    onBacklogChange?.();
  }

  const onSelectedColumnsChange = useCallback(
    (newIds: string[]) => {
      onConfigChange({ backlogColumnIds: newIds });
      onBacklogChange?.();
    },
    [onConfigChange, onBacklogChange],
  );

  const onDelete = useCallback(() => {
    void setIssueKeys((prev) => prev.filter((key) => !selectedIssues.includes(key)));
    setRowSelection({});
    onBacklogChange?.();
  }, [selectedIssues, setIssueKeys, onBacklogChange]);

  const onJqlChange = useCallback((jql: string) => onConfigChange({ jql }), [onConfigChange]);

  return (
    <Box width="100%" display="flex" marginTop="8px" flexDirection="column" sx={{ gap: "24px" }}>
      {!isBacklogEmpty && (
        <Box display="flex" marginBottom="8px" justifyContent="space-between">
          <AddIssuesDialog
            addMoreIssues={addMoreIssues}
            disabledIssueKeys={gameConfig.backlog ?? []}
            columnsIds={columnsIds}
            estimationFieldId={gameConfig.estimationFieldId}
            jql={gameConfig.jql}
            onJqlChange={onJqlChange}
          />
          <Box display="flex" sx={{ gap: "8px" }}>
            <ColumnsSelector
              selectedColumnIds={gameConfig.backlogColumnIds ?? []}
              onSelectedColumnsChange={onSelectedColumnsChange}
              excludedColumns={DEFAULT_COLUMNS}
            />
            <Button
              appearance="warning"
              isDisabled={!selectedIssues.length}
              onClick={onDelete}
              iconBefore={<AkTrashIcon />}
            >
              {deleteButtonText}
            </Button>
          </Box>
        </Box>
      )}
      {isBacklogEmpty && !isLoadingIssues && (
        <EmptyBacklog>
          <AddIssuesDialog
            addMoreIssues={addMoreIssues}
            shouldOpenOnLoad={true}
            disabledIssueKeys={gameConfig.backlog ?? []}
            columnsIds={columnsIds}
            estimationFieldId={gameConfig.estimationFieldId}
            jql={gameConfig.jql}
            onJqlChange={onJqlChange}
          />
        </EmptyBacklog>
      )}
      {!isBacklogEmpty && (
        <BacklogTable
          issues={issues}
          onOrderChange={onOrderChange}
          isLoadingIssues={isLoadingIssues}
          estimationFieldId={gameConfig.estimationFieldId}
          columnsIds={columnsIds}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
        />
      )}
    </Box>
  );
};
