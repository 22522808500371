import React from "react";

import { Checkbox } from "@atlaskit/checkbox";

interface CheckboxCellProps {
  id: string;
  selectedIds: string[];
  isDisabled?: boolean;
}

export function CheckboxCell({ id, isDisabled, selectedIds }: CheckboxCellProps) {
  const isChecked = Boolean(selectedIds.find((s) => s === id));
  return <Checkbox isChecked={isChecked} isDisabled={isDisabled} />;
}
