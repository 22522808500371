import { useCallback } from "react";

import { amplitudePlugin } from "@appfire/analytics-client";

import { useApplicationContext } from "../../providers/ApplicationContextProvider";
import { getApproximateLicenseCount } from "../../services/jira-api";
import { License } from "../../types";
import { analyticsFallback } from "../utils";

const getLicenseTypeValue = (license: License | undefined) => {
  if (!license?.type) return "unavailable";
  if (license.type === "DEVELOPER") return license.type;
  if (license.evaluation) return "EVALUATION";
  return license.type;
};

const getStatusValue = (license: License | undefined): "active" | "inactive" | "unavailable" => {
  if (license?.active === undefined) return "unavailable";
  return license.active ? "active" : "inactive";
};

export function useUpdateGroupProperties() {
  const { license, isJiraAdmin, hostBaseUrl, addonKey, url } = useApplicationContext();

  const updateGroupProperties = useCallback(async () => {
    const { entitlementNumber, evaluation } = license || {};

    const approximateLicenseCountResult = isJiraAdmin ? await getApproximateLicenseCount() : undefined;
    const approximateLicenseCount = isNaN(Number(approximateLicenseCountResult))
      ? -1
      : Number(approximateLicenseCountResult);

    await amplitudePlugin.updateHostProperties({
      base_url: analyticsFallback(hostBaseUrl),
      display_url: analyticsFallback(url.displayUrl),
      ecosystem: "atlassian",
      hosting: "cloud",
      parent_product: "jira",
      test_instance: process.env.ENVIRONMENT !== "production",
    });

    if (entitlementNumber) {
      await amplitudePlugin.updateLicenseProperties({
        app_key: addonKey,
        approx_user_count: approximateLicenseCount,
        hosting: "cloud",
        is_eval: Boolean(evaluation),
        license_type: getLicenseTypeValue(license),
        status: getStatusValue(license),
      });
    }
  }, [addonKey, hostBaseUrl, isJiraAdmin, license, url.displayUrl]);

  return updateGroupProperties;
}
