import React, { forwardRef, PropsWithChildren, useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { GameState } from "@appfire/poker-core";
import Button from "@atlaskit/button";
import { IconButton } from "@atlaskit/button/new";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import BacklogIcon from "@atlaskit/icon/glyph/backlog";
import FeedbackIcon from "@atlaskit/icon/glyph/feedback";
import FlagFilledIcon from "@atlaskit/icon/glyph/flag-filled";
import MoreIcon from "@atlaskit/icon/glyph/more";
import RetryIcon from "@atlaskit/icon/glyph/retry";
import SettingsIcon from "@atlaskit/icon/glyph/settings";
import TrashIcon from "@atlaskit/icon/glyph/trash";

import { useApplicationContext } from "../../../../providers/ApplicationContextProvider";
import { getSupportURL } from "../../../../utils/feedback";
import { Divider } from "../../../shared/Divider";
import { useGameData } from "../../GameProvider";

const RightSideContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export function RightSide() {
  const { hostBaseUrl, addonKey } = useApplicationContext();
  const openSupportUrl = useCallback(() => {
    void getSupportURL(addonKey, hostBaseUrl).then((supportURL) =>
      window.open(supportURL, "_blank", "noreferrer noopener"),
    );
  }, [addonKey, hostBaseUrl]);
  const { gameId, isGameAdmin, game } = useGameData();

  if (isGameAdmin) {
    return (
      <RightSideContainer>
        <Button
          component={forwardRef<HTMLAnchorElement, PropsWithChildren<unknown>>(function LinkWithRef(props, ref) {
            return <Link ref={ref} to={`/game/${gameId}/backlog`} {...props} />;
          })}
          appearance="subtle"
          iconBefore={<BacklogIcon label="BacklogIcon" />}
        >
          Edit backlog
        </Button>
        <Button
          component={forwardRef<HTMLAnchorElement, PropsWithChildren<unknown>>(function LinkWithRef(props, ref) {
            return <Link ref={ref} to={`/game/${gameId}/configuration`} {...props} />;
          })}
          appearance="subtle"
          iconBefore={<SettingsIcon label="SettingsIcon" />}
        >
          Edit game
        </Button>
        <DropdownMenu<HTMLButtonElement>
          placement="bottom-end"
          trigger={({ triggerRef, ...props }) => (
            <IconButton {...props} icon={MoreIcon} appearance="subtle" label="More actions" ref={triggerRef} />
          )}
        >
          <DropdownItemGroup>
            <DropdownItem
              component={forwardRef<HTMLAnchorElement, PropsWithChildren<unknown>>(function LinkWithRef(props, ref) {
                return <Link ref={ref} to={`/game/${gameId}/restart-game`} {...props} />;
              })}
              elemBefore={<RetryIcon label="RetryIcon" />}
            >
              Restart game
            </DropdownItem>
            {game.state !== GameState.FINISHED && (
              <DropdownItem
                component={forwardRef<HTMLAnchorElement, PropsWithChildren<unknown>>(function LinkWithRef(props, ref) {
                  return <Link ref={ref} to={`/game/${gameId}/finish-game`} {...props} />;
                })}
                elemBefore={<FlagFilledIcon label="FlagFilledIcon" />}
              >
                Finish game
              </DropdownItem>
            )}
            <DropdownItem
              component={forwardRef<HTMLAnchorElement, PropsWithChildren<unknown>>(function LinkWithRef(props, ref) {
                return <Link ref={ref} to={`/game/${gameId}/delete-game`} {...props} />;
              })}
              elemBefore={<TrashIcon label="TrashIcon" />}
            >
              Delete game
            </DropdownItem>
            <Divider />
            <DropdownItem elemBefore={<FeedbackIcon label="FeedbackIcon" />} onClick={openSupportUrl}>
              Contact us
            </DropdownItem>
          </DropdownItemGroup>
        </DropdownMenu>
      </RightSideContainer>
    );
  }

  return (
    <RightSideContainer>
      <Button appearance="subtle" iconBefore={<FeedbackIcon label="FeedbackIcon" />} onClick={openSupportUrl}>
        Contact us
      </Button>
    </RightSideContainer>
  );
}
