import React, { ReactElement, useState } from "react";

import Blanket from "@atlaskit/blanket";
import { Box } from "@fuegokit/react";

import { COLLAPSED_PANEL_WIDTH } from "./ParticipantsPanel/constants";
import { ParticipantsPanel } from "./ParticipantsPanel/ParticipantsPanel";

interface GameChildrenProps {
  controllerElement: ReactElement;
}

export function GameChildren({ controllerElement }: Readonly<GameChildrenProps>) {
  const [isParticipantsPanelExpanded, setIsParticipantsPanelExpanded] = useState(false);

  return (
    <Box pl="40px">
      <Box marginTop="24px" width={`calc(100% - ${COLLAPSED_PANEL_WIDTH})`} pr="27px">
        {controllerElement}
      </Box>
      {isParticipantsPanelExpanded && (
        <Blanket onBlanketClicked={() => setIsParticipantsPanelExpanded(false)} isTinted />
      )}
      <ParticipantsPanel
        isExpanded={isParticipantsPanelExpanded}
        onClick={() => setIsParticipantsPanelExpanded((prev) => !prev)}
      />
    </Box>
  );
}
