import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { getAllJiraIssuesBySprintId, getJiraSprintById } from "../services/jira-api";
import { GameFormConfig } from "../types";

interface UsePreloadSprintDataProps {
  setGameConfig: (value: React.SetStateAction<GameFormConfig>) => void;
  setIssueKeys: (value: React.SetStateAction<string[]>) => void;
  setIsSprintLoading: (value: React.SetStateAction<boolean>) => void;
}

export function usePreloadSprintData({ setGameConfig, setIssueKeys, setIsSprintLoading }: UsePreloadSprintDataProps) {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const sprintId = params.get("sprint.id");

  const loadSprintName = useCallback(
    async (sprintId: string) => {
      try {
        const { name } = await getJiraSprintById(sprintId);
        setGameConfig((prev) => ({ ...prev, name }));
      } catch (err) {
        console.warn(`Failed to fetch sprint ${sprintId}`, err);
      }
    },
    [setGameConfig],
  );

  const loadSprintIssues = useCallback(
    async (sprintId: string) => {
      try {
        setIsSprintLoading(true);
        const { issues } = await getAllJiraIssuesBySprintId(sprintId);
        setIssueKeys(issues.map((issue) => issue.key));
      } catch (err) {
        console.warn(`Failed to issues for sprint ${sprintId}`, err);
      } finally {
        setIsSprintLoading(false);
      }
    },
    [setIsSprintLoading, setIssueKeys],
  );

  useEffect(() => {
    void (async () => {
      if (sprintId) {
        await Promise.all([loadSprintName(sprintId), loadSprintIssues(sprintId)]);
      }
    })();
  }, [loadSprintIssues, loadSprintName, sprintId]);
}
