import React, { useCallback, useMemo } from "react";

import { ItemState } from "@appfire/poker-core";
import Lozenge from "@atlaskit/lozenge";

import { Voting } from "../../../types";
import { getVoteSetName } from "../../../utils";
interface AverageScoreProps {
  activeItemState: ItemState;
  activeItemVotes?: Voting;
  cardDeck: string[];
}

const parseVote = (vote: string) => {
  if (vote === "?" || vote === "coffee") return null;

  const votePattern = /^(\d+(\.\d+)?)(h)?$/;
  const numericMatch = votePattern.exec(vote);

  if (numericMatch) {
    const numericVote = Number(numericMatch[1]);
    return !isNaN(numericVote) ? numericVote : null;
  }
  return null;
};

const formatAverageLabel = (cardDeckType: string, average: number) => {
  if (cardDeckType === "1-12") {
    const hours = Math.floor(average);
    const minutes = Math.round((average - hours) * 60);

    return average % 1 === 0 ? `${hours}h` : `${hours}h ${minutes}min`;
  }

  return average % 1 === 0 ? average : average.toFixed(2);
};

export function AverageScore({ activeItemVotes, activeItemState, cardDeck }: Readonly<AverageScoreProps>) {
  const cardDeckType = useMemo(() => {
    return getVoteSetName(cardDeck);
  }, [cardDeck]);

  const calculateAverage = useCallback(
    (votes: Record<string, string>) => {
      let sum = 0;
      let count = 0;

      if (cardDeckType === "custom" && cardDeck.some((card) => isNaN(Number(card)))) {
        return "N/A";
      }

      const voteValues = Object.values(votes);

      for (const vote of voteValues) {
        const numericVote = parseVote(vote);
        if (numericVote !== null) {
          sum += numericVote;
          count++;
        }
      }

      if (count === 0) return "N/A";

      const average = sum / count;
      return formatAverageLabel(cardDeckType, average);
    },
    [cardDeckType, cardDeck],
  );

  const averageLabel = useMemo(() => {
    if (activeItemVotes?.votes && activeItemState === ItemState.FINAL_ESTIMATION) {
      return calculateAverage(activeItemVotes.votes);
    }
    return "N/A";
  }, [activeItemVotes?.votes, activeItemState, calculateAverage]);

  return (
    <Lozenge isBold={true} appearance="new">
      {`Average score ${averageLabel}`}
    </Lozenge>
  );
}
