import React, { useCallback, useMemo } from "react";

import { FinalEstimateSummaryProps, GroupedVote } from "@appfire/poker-core";
import Button from "@atlaskit/button";
import Lozenge from "@atlaskit/lozenge";
import { Radio } from "@atlaskit/radio";
import { AfLogoPlanningPokerIcon, AkEditIcon } from "@fuegokit/fuegoicons-react";
import { Box } from "@fuegokit/react";

import "./voting-summary.css";

import { useApplicationContext } from "../../../providers/ApplicationContextProvider";
import { JiraIssue } from "../../../types";
import { CardType, GamePersona } from "../../../types/common";
import { didUserVote } from "../../../utils/game";
import { CardsSectionMessage } from "../CardsSectionMessage";
import { useGameData } from "../GameProvider";
import { CardsGroup } from "./CardsGroup";

const UNSELECTABLE_GROUP_VALUES = ["coffee", "?"];

export function VotingSummary({
  groupedVotes,
  votedParticipantsCount,
  isSpectator,
  isEditMode,
  finalEstimate,
  setEditMode,
  selectFinalEstimate,
  onMouseEnter,
  onMouseLeave,
}: Readonly<FinalEstimateSummaryProps<JiraIssue, GamePersona, CardType, string>>) {
  const { userAccountId } = useApplicationContext();
  const { isGameAdmin, game } = useGameData();

  const isGroupSelectable = useCallback(
    (groupValue: string) => isGameAdmin && !UNSELECTABLE_GROUP_VALUES.includes(groupValue),
    [isGameAdmin],
  );

  const getLozengeLabel = useCallback(
    (groupedVote: GroupedVote<GamePersona, CardType>) => {
      return `${groupedVote.participants.length}/${votedParticipantsCount} VOTED: ${groupedVote.value}`;
    },
    [votedParticipantsCount],
  );

  const isChecked = useCallback(
    (value: string | undefined) => {
      return isGameAdmin && value === finalEstimate;
    },
    [isGameAdmin, finalEstimate],
  );

  const onGroupClick = (value?: string) => {
    if (!value || !isGroupSelectable(value)) return;
    selectFinalEstimate(value);
  };

  const onEditClick = useCallback(() => setEditMode(true), [setEditMode]);

  const groupedVotesWithCurrentUserAtEnd = useMemo(
    () =>
      [...groupedVotes].reverse().map((group) => {
        const currentUserInGroup = group.participants.find((participant) => participant.id === userAccountId);
        if (!currentUserInGroup) {
          return group;
        }
        const groupWithoutCurrentUser = group.participants.filter((participant) => participant.id !== userAccountId);
        return {
          ...group,
          participants: [...groupWithoutCurrentUser, currentUserInGroup],
        };
      }),
    [groupedVotes, userAccountId],
  );

  const groupSummaryHeaderClasses = useCallback(
    (groupValue: string) => {
      const classNames = ["summary-header"];
      if (groupValue === finalEstimate) {
        classNames.push("selected");
      }
      if (isGroupSelectable(groupValue)) {
        classNames.push("selectable");
      }
      return classNames.join(" ");
    },
    [finalEstimate, isGroupSelectable],
  );

  const hasUserVoted = useMemo(
    () => didUserVote(game.activeItemId, game.voting, userAccountId),
    [game.activeItemId, game.voting, userAccountId],
  );

  if (!groupedVotes.length && !isEditMode) {
    return (
      <CardsSectionMessage message="No votes provided">
        {!isSpectator && (
          <Button onClick={() => setEditMode(true)} iconBefore={<AfLogoPlanningPokerIcon />}>
            Add your vote
          </Button>
        )}
      </CardsSectionMessage>
    );
  }

  return !isEditMode ? (
    <div className="voting-summary-container">
      {!isSpectator ? (
        <Button
          className="edit-vote"
          appearance="subtle"
          iconBefore={<AkEditIcon size="small" />}
          onClick={onEditClick}
        >
          {hasUserVoted ? "Edit" : "Add"} personal vote
        </Button>
      ) : null}
      <div className="voting-summary-group-wrapper">
        {groupedVotesWithCurrentUserAtEnd.map((groupedVote) => (
          <Box
            className="voting-summary-group"
            key={groupedVote.value}
            onMouseEnter={() => onMouseEnter?.(groupedVote.value)}
            onMouseLeave={onMouseLeave}
          >
            <Box
              className={groupSummaryHeaderClasses(groupedVote.value)}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={() => onGroupClick(groupedVote.value)}
            >
              <Lozenge
                isBold={isChecked(groupedVote.value)}
                appearance={isChecked(groupedVote.value) ? "inprogress" : "default"}
              >
                {getLozengeLabel(groupedVote)}
              </Lozenge>
              {isGroupSelectable(groupedVote.value) && <Radio isChecked={isChecked(groupedVote.card?.value)} />}
            </Box>
            <CardsGroup groupedVote={groupedVote} onEditClick={onEditClick} />
          </Box>
        ))}
      </div>
    </div>
  ) : null;
}
