import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useEstimationField } from "../../hooks/useEstimationField";
import { useBacklogStore } from "../../providers/BacklogStoreContextProvider";
import { JiraIssue, Voting } from "../../types";
import { normalizeBy } from "../../utils";
import { getEstimationValue } from "../../utils/estimation-values";
import { useGameData } from "./GameProvider";

interface GameIssuesContextData {
  issues: Record<string, JiraIssue>;
  isLoading: boolean;
  fields: string[];
  estimatedIssues: Record<string, Voting>;
  unestimatedIssues: string[];
}

const GameIssuesContext = createContext<GameIssuesContextData | null>(null);

export function GameIssuesProvider({ children }: Readonly<{ children: ReactNode }>) {
  const { queryIssuesByIds, backlogIssues } = useBacklogStore();
  const [issues, setIssues] = useState<Record<string, JiraIssue>>({});
  const [isLoading, setIsLoading] = useState(true);
  const { game } = useGameData();
  const backlog = useMemo(() => game.configuration.backlog || [], [game.configuration.backlog]);
  const estimationField = useEstimationField(game.configuration.estimationFieldId);

  const fields = useMemo(
    () => [
      ...(game.configuration.backlogColumnIds ?? []),
      ...(game.configuration.layoutFields ?? []),
      game.configuration.estimationFieldId,
    ],
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      game.configuration.estimationFieldId,
      JSON.stringify(game.configuration.backlogColumnIds),
      JSON.stringify(game.configuration.layoutFields),
    ],
    /* eslint-enable react-hooks/exhaustive-deps */
  );

  useEffect(() => {
    void (async () => {
      setIsLoading(true);
      const issues = await queryIssuesByIds(backlog, fields);
      const groupedIssues = normalizeBy(issues, "key");
      setIssues(groupedIssues);
      setIsLoading(false);
    })();
    // Without JSON.stringify it will trigger update every time anything changes in game object in db, cause arrays references will change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, JSON.stringify(backlog), queryIssuesByIds]);

  const updatedIssues = useMemo(() => {
    return Object.keys(issues).reduce(
      (acc, issueKey) => {
        acc[issueKey] = backlogIssues[issueKey] || issues[issueKey];
        return acc;
      },
      { ...issues },
    );
  }, [issues, backlogIssues]);

  const getIssueEstimationValue = useCallback(
    (issueKey: string) => {
      const issue = updatedIssues[issueKey];
      return getEstimationValue(issue?.fields?.[game.configuration.estimationFieldId], estimationField);
    },
    [estimationField, game.configuration.estimationFieldId, updatedIssues],
  );

  const estimatedIssues = useMemo<Record<string, Voting>>(() => {
    const estimatedIssuesArray = Object.entries(game.voting ?? {}).filter(
      ([issueKey, voting]) => getIssueEstimationValue(issueKey) === voting.savedEstimate,
    );
    return estimatedIssuesArray.reduce((allVotings, [issueKey, voting]) => ({ ...allVotings, [issueKey]: voting }), {});
  }, [game.voting, getIssueEstimationValue]);

  const unestimatedIssues = useMemo(
    () => backlog.filter((issue) => !estimatedIssues[issue]),
    [backlog, estimatedIssues],
  );

  const value = useMemo(
    () => ({
      issues: updatedIssues,
      isLoading,
      fields,
      estimatedIssues,
      unestimatedIssues,
    }),
    [updatedIssues, isLoading, fields, estimatedIssues, unestimatedIssues],
  );

  return <GameIssuesContext.Provider value={value}> {children}</GameIssuesContext.Provider>;
}

export function useGameIssues() {
  const context = useContext(GameIssuesContext);
  if (!context) {
    throw new Error("Game issues context not initialized");
  }
  return context;
}
