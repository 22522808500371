import React from "react";
import styled from "styled-components";

import { AkEditorInfoIcon } from "@fuegokit/fuegoicons-react";
import { Text, themeGet } from "@fuegokit/react";

const InlineMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const CardsSectionMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 136px;
  gap: 22px;
`;

const EditorIcon = styled(AkEditorInfoIcon)`
  color: ${themeGet("colors.icon.discovery")};
`;

interface CardsSectionMessageProps {
  message: string;
  children: React.ReactNode;
}

export function CardsSectionMessage({ message, children }: CardsSectionMessageProps) {
  return (
    <CardsSectionMessageContainer>
      <InlineMessage>
        <EditorIcon />
        <Text>{message}</Text>
      </InlineMessage>
      {children}
    </CardsSectionMessageContainer>
  );
}
