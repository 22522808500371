import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useFinishGame } from "../../hooks/useFinishGame";
import { showFlag } from "../../services/jira-api";
import { AbstractModal } from "../dashboard/modals/modals";

export function GameFinishGameModal() {
  const { id } = useParams() as { id: string };

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate("..", { relative: "path" }), [navigate]);

  const finishGame = useFinishGame(id);
  const confirmFinishGames = useCallback(async () => {
    try {
      await finishGame();
      showFlag("Game finished", "", "success");
      goBack();
    } catch (e) {
      console.error(e);
      showFlag("Failed to finish game", "", "error");
      goBack();
    }
  }, [finishGame, goBack]);

  return (
    <AbstractModal title="Finish game" width="small" onClose={goBack} onConfirm={confirmFinishGames}>
      By confirming game will be finished. No further changes to the game will be possible without restoring it. All
      unfinished stories will remain in estimation backlog.
    </AbstractModal>
  );
}
