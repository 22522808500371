import React, { ReactNode, useMemo } from "react";

import { JIRA_FIELD_SCHEMA_ITEMS, JIRA_FIELD_TYPE, JiraField, JiraIssue, JiraProject, JiraUser } from "../../types";
import { secondsToJiraTimeRepresentation, SPRINT_FIELD_SCHEMA } from "../../utils";
import { CUSTOM_FIELD, FIELD } from "../../utils/backlog";
import { AvatarCell } from "../dashboard/cells/AvatarCell";
import { DateTimeCell } from "../shared/cells/DateTimeCell";
import { EpicLabel } from "../shared/cells/EpicLabel";
import { EstimationFieldCell } from "../shared/cells/EstimationFieldCell";
import { GameEstimationFieldCell } from "../shared/cells/GameEstimationFieldCell";
import { IconCellMemoized } from "../shared/cells/IconCell";
import { IssueKeyCell } from "../shared/cells/IssueKeyCell";
import { ParentCell } from "../shared/cells/ParentCell";
import { ParentLinkCell } from "../shared/cells/ParentLinkCell";
import { ProjectCell } from "../shared/cells/ProjectCell";
import { StatusLabel } from "../shared/cells/StatusLabel";
import { TagsCell } from "../shared/cells/TagsCell";
import { UrlCell } from "../shared/cells/UrlCell";
import { TruncatedTooltipMemoized } from "../shared/TruncatedTooltip";

interface FieldRendererProps {
  issue: JiraIssue;
  estimationFieldId?: string;
  columnId: string;
  field?: JiraField;
  extended?: boolean;
}

const getArrayFieldStringValue = (field: JiraField, issue: JiraIssue) => {
  if (field.schema?.items === JIRA_FIELD_SCHEMA_ITEMS.VERSION) {
    const fieldValue = issue.fields[field.id] as { name: string }[] | undefined;
    return fieldValue?.map((value) => value.name).join(", ") ?? "";
  }
  if (
    (field.schema?.items === JIRA_FIELD_SCHEMA_ITEMS.JSON && field.schema.custom === SPRINT_FIELD_SCHEMA) ||
    field.schema?.items === JIRA_FIELD_SCHEMA_ITEMS.COMPONENT
  ) {
    const fieldValue = issue.fields[field.id] as { name: string }[] | null;
    if (!fieldValue) return "";
    return fieldValue.map((value) => value.name).join(",");
  }
  return String(issue.fields[field.id] ?? "");
};

export const CUSTOM_RENDERERS: Record<string, (props: FieldRendererProps) => ReactNode> = {
  [FIELD.ISSUE_TYPE]: ({ issue }: FieldRendererProps) => (
    <IconCellMemoized url={issue.fields.issuetype?.iconUrl} tooltip={issue.fields.issuetype?.name} />
  ),
  [FIELD.ISSUE_KEY]: ({ issue }: FieldRendererProps) => <IssueKeyCell issue={issue} />,
  [FIELD.SUMMARY]: ({ issue }: FieldRendererProps) => (
    <TruncatedTooltipMemoized tooltipText={issue.fields.summary}>{issue.fields.summary}</TruncatedTooltipMemoized>
  ),
  [FIELD.EPIC]: ({ issue }: FieldRendererProps) => (
    <TruncatedTooltipMemoized tooltipText={issue.fields.parent?.fields.summary ?? ""} showTooltipOnEqualWidth>
      <EpicLabel issue={issue} />
    </TruncatedTooltipMemoized>
  ),
  [FIELD.STATUS]: ({ issue }: FieldRendererProps) => (
    <TruncatedTooltipMemoized tooltipText={issue.fields?.status?.name} showTooltipOnEqualWidth>
      <StatusLabel issue={issue} />
    </TruncatedTooltipMemoized>
  ),
  [FIELD.ESTIMATE]: ({ issue, estimationFieldId }: FieldRendererProps) => (
    <EstimationFieldCell issue={issue} estimationFieldId={estimationFieldId!} />
  ),
  [FIELD.PRIORITY]: ({ issue }: FieldRendererProps) => (
    <IconCellMemoized url={issue.fields.priority?.iconUrl} tooltip={issue.fields.priority?.name} />
  ),
  [FIELD.PARENT]: ({ issue }: FieldRendererProps) =>
    issue.fields.parent ? <ParentCell parent={issue.fields.parent} /> : null,
  [FIELD.GAME_ESTIMATION]: ({ issue, estimationFieldId }: FieldRendererProps) => (
    <GameEstimationFieldCell issue={issue} estimationFieldId={estimationFieldId!} />
  ),
};

export function FieldRenderer(props: Readonly<FieldRendererProps>) {
  const fieldValue = props.issue.fields[props.columnId];

  const rendererByCustomSchema = useMemo(() => {
    switch (props.field?.schema?.custom as CUSTOM_FIELD) {
      case CUSTOM_FIELD.PARENT: {
        return <ParentLinkCell parent={props.issue.fields.parent} />;
      }
      case CUSTOM_FIELD.URL: {
        return <UrlCell url={fieldValue as string | undefined} />;
      }
      default:
        return null;
    }
  }, [props.field?.schema?.custom, props.issue.fields.parent, fieldValue]);

  const rendererByFieldType = useMemo(() => {
    const stringValue = String(fieldValue ?? "-");
    switch (props.field?.schema?.type) {
      case JIRA_FIELD_TYPE.PROJECT: {
        return <ProjectCell project={fieldValue as JiraProject} />;
      }
      case JIRA_FIELD_TYPE.USER:
        return <AvatarCell user={fieldValue as JiraUser} showName={props.extended} />;
      case JIRA_FIELD_TYPE.OPTION: {
        const optionValue = String((fieldValue as { value: string | null })?.value ?? "");
        return <TruncatedTooltipMemoized tooltipText={optionValue}>{optionValue}</TruncatedTooltipMemoized>;
      }
      case JIRA_FIELD_TYPE.DATETIME:
        return <DateTimeCell datetime={fieldValue as string} />;
      case JIRA_FIELD_TYPE.TIME_TRACKING: {
        const value = String((fieldValue as { timeSpent: string })?.timeSpent ?? "");
        return <TruncatedTooltipMemoized tooltipText={value}>{value}</TruncatedTooltipMemoized>;
      }
      case JIRA_FIELD_TYPE.ARRAY: {
        const value = getArrayFieldStringValue(props.field, props.issue);
        switch (props.field.schema.system) {
          case "labels":
            return <TagsCell tags={fieldValue as string[]} />;
          case "components":
            return <TagsCell tags={(fieldValue as { name: string }[])?.map((v) => v.name) ?? []} />;
          default:
            return <TruncatedTooltipMemoized tooltipText={value}>{value || "-"}</TruncatedTooltipMemoized>;
        }
      }
      case JIRA_FIELD_TYPE.NUMBER: {
        const value = props.field.id.includes("time")
          ? secondsToJiraTimeRepresentation(Number(stringValue))
          : stringValue;
        return <TruncatedTooltipMemoized tooltipText={value}>{value}</TruncatedTooltipMemoized>;
      }
      default:
        return props.columnId ? (
          <TruncatedTooltipMemoized tooltipText={stringValue}>{stringValue}</TruncatedTooltipMemoized>
        ) : null;
    }
  }, [fieldValue, props.columnId, props.extended, props.field, props.issue]);

  const renderFunction = CUSTOM_RENDERERS[props.columnId];

  if (renderFunction) {
    return <>{renderFunction(props)}</>;
  }

  if (rendererByCustomSchema) {
    return <>{rendererByCustomSchema}</>;
  }

  return <>{rendererByFieldType}</>;
}
