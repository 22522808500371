import { useCallback } from "react";

import { useJiraDataStore } from "../../providers/JiraDataStoreProvider";
import { GameConfigAmplitudeProps, GameFormConfig } from "../../types";
import { getDeckTypeValue } from "../utils";

export function useGetGameConfigAmplitudeProperties() {
  const { fields } = useJiraDataStore();

  const getFieldName = useCallback((fieldId: string) => fields?.find((field) => field.id === fieldId)?.name, [fields]);

  return useCallback(
    (config: GameFormConfig) => {
      const anyAutomationApplied = Boolean(
        config.estimatedLabel?.length ||
          config.skippedLabel?.length ||
          config.estimatedTransition ||
          config.estimatedSprintId,
      );

      const isCardDeckWithLabels = config.cards.some((card) => card.includes("="));
      const estimationFieldName = getFieldName(config.estimationFieldId) ?? "Comments";
      const customFieldsNames = config.layoutFields
        ?.map((layoutField) => getFieldName(layoutField))
        .filter(Boolean) as string[];

      const properties: GameConfigAmplitudeProps = {
        admins_count: Object.keys(config.admins ?? {}).length,
        any_automation_applied: anyAutomationApplied,
        coffee_card_enabled: config.coffeeCardsEnabled,
        deck_mapping: isCardDeckWithLabels,
        deck_type: getDeckTypeValue(config.cards),
        estimation_field: estimationFieldName,
        has_name: Boolean(config.name),
        issue_number: config.backlog?.length ?? 0,
        move_to_sprint: Boolean(config.estimatedSprintId),
        notify_participants: Boolean(config.sendEmails),
        private_game: Boolean(config.private),
        custom_fields: customFieldsNames,
      };
      return properties;
    },
    [getFieldName],
  );
}
