import { useCallback } from "react";

import { GameState } from "@appfire/poker-core";

import { useGameFinishedEvent } from "../analytics/hooks/events/useGameFinishedEvent";
import { useGameStateChange, useTimerUpdateStopTimestamp } from "../services/firebase";

export function useFinishGame(gameId: string) {
  const updateGameState = useGameStateChange(gameId);
  const updateStopTimestamp = useTimerUpdateStopTimestamp(gameId);
  const recordGameFinishedEvent = useGameFinishedEvent();

  return useCallback(async () => {
    await Promise.all([updateGameState(GameState.FINISHED), updateStopTimestamp(-1)]);
    recordGameFinishedEvent(gameId);
  }, [gameId, recordGameFinishedEvent, updateGameState, updateStopTimestamp]);
}
