import React, { SyntheticEvent, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import Button, { LinkButton } from "@atlaskit/button/new";
import { Box } from "@fuegokit/react";

import { useApplicationContext } from "../../../../providers/ApplicationContextProvider";
import { Voting } from "../../../../types";
import { copyToClipBoard } from "../../../../utils";
import { useGameData } from "../../GameProvider";

const MAX_NUMBER_CHARACTERS = 4000;
const getEstimatedIssueKeys = (voting?: Record<string, Voting>) => {
  const keys: string[] = [];
  Object.entries(voting ?? {}).forEach(([issueKey, voting]) => {
    if (voting.savedEstimate) {
      keys.push(issueKey);
    }
  });
  return keys;
};

export function OpenIssuesInJira() {
  const { game } = useGameData();
  const navigate = useNavigate();
  const goToDashboard = useCallback(() => navigate("/"), [navigate]);
  const { hostBaseUrl } = useApplicationContext();
  const estimated = getEstimatedIssueKeys(game.voting);
  const isURLWithinLimit = estimated.join(" ").length < MAX_NUMBER_CHARACTERS;
  const jql = `key in (${estimated.join(", ")})`;

  function navigateToIssueBrowser() {
    const relativeUrl = `/browse/?jql=${jql}`;
    AP.navigator.go("site", { relativeUrl: relativeUrl });
  }

  function onCopy(e: SyntheticEvent) {
    copyToClipBoard(e, jql);
  }

  if (estimated.length === 0) {
    return (
      <Button appearance="primary" onClick={goToDashboard}>
        Go to dashboard
      </Button>
    );
  } else if (isURLWithinLimit) {
    return (
      <Button appearance="primary" onClick={navigateToIssueBrowser}>
        Open issues in Jira
      </Button>
    );
  } else {
    return (
      <>
        <Button appearance="primary" onClick={onCopy}>
          Copy JQL
        </Button>
        <Box display={"flex"} alignItems={"center"} style={{ gap: "4px" }}>
          <p>Copy the JQL with estimated issues to edit or bulk edit them in</p>
          <LinkButton
            spacing={"none"}
            href={`${hostBaseUrl}/issues/?jql=order+by+created+DESC`}
            appearance="link"
            target="_blank"
          >
            Advanced issue search
          </LinkButton>
        </Box>
      </>
    );
  }
}
