import styled, { css } from "styled-components";

import { TRANSITION_TIMEOUT } from "./constants";

export const expandTransitionStyles = css<{ $isExpanded: boolean }>`
  transition: opacity 0.2s ease ${TRANSITION_TIMEOUT};
  opacity: ${(props) => (props.$isExpanded ? 1 : 0)};
`;

export const ExpandTransition = styled.span<{ $isExpanded: boolean }>`
  ${expandTransitionStyles}
`;
