import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "@atlaskit/button/new";
import { ProgressIndicator } from "@atlaskit/progress-indicator";
import { Box } from "@fuegokit/react";

import { Backlog } from "../components/backlog/Backlog";
import { GameConfigForm } from "../components/GameConfigForm/GameConfigForm";
import { LeaveCreateFormModal } from "../components/GameConfigForm/LeaveCreateFormModal";
import { FullPageDialog } from "../components/shared/FullPageDialog/FullPageDialog";
import { useGetSupportedBacklogColumnIds } from "../hooks/useGetSupportedBacklogColumnIds";
import { usePreloadSprintData } from "../hooks/usePreloadSprintData";
import { useQueryIssuesById } from "../hooks/useQueryIssuesById";
import { useApplicationContext } from "../providers/ApplicationContextProvider";
import { useFirebaseData } from "../providers/FirebaseDataProvider";
import { useGameCreate } from "../services/firebase";
import { GameFormConfig, JiraIssue } from "../types";
import { DECKS, FULL_PAGE_DIALOG_CONTROLS_WIDTH, FULL_PAGE_DIALOG_MAX_WIDTH } from "../utils";
import { buildJQLClause } from "../utils/backlog";
import { isGameFormConfigValid } from "../utils/game";

interface CreateNewGameDialogProps {
  isOpen: boolean;
}

type Step = 1 | 2;

interface StepsIndicatorProps {
  stepIndex: number;
  values: number[];
  backButtonText: string;
  nextButtonText: string;
  onBack: () => void;
  onNext: () => void;
  isNextDisabled: boolean;
  isLoading: boolean;
  appearance: "primary" | "default";
}

const StepsIndicatorContainer = styled.div`
  padding: 16px 0;
  height: 64px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-content: center;
  align-items: center;
`;

function StepsIndicator({
  stepIndex,
  values,
  backButtonText,
  nextButtonText,
  onBack,
  onNext,
  isNextDisabled,
  isLoading,
  appearance,
}: Readonly<StepsIndicatorProps>) {
  return (
    <StepsIndicatorContainer>
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Button appearance="subtle" onClick={onBack}>
          {backButtonText}
        </Button>
      </Box>
      <Box margin="0 182px">
        <ProgressIndicator appearance="primary" selectedIndex={stepIndex} values={values} />
      </Box>
      <Box display={"flex"}>
        <Button
          appearance={appearance}
          onClick={onNext}
          isDisabled={isNextDisabled}
          isLoading={isLoading}
          testId="next-step"
        >
          {nextButtonText}
        </Button>
      </Box>
    </StepsIndicatorContainer>
  );
}

export function CreateNewGameDialog({ isOpen }: Readonly<CreateNewGameDialogProps>) {
  const { lastUserGame } = useFirebaseData();
  const { userAccountId, userLocale, project, statuses } = useApplicationContext();
  const [step, setStep] = useState<Step>(1);
  const [isDirty, setIsDirty] = useState(false);
  const [isLeaveFormModalOpen, setIsLeaveFormModalOpen] = useState(false);
  const navigate = useNavigate();
  const createGame = useGameCreate();
  const [isSprintLoading, setIsSprintLoading] = useState(false);
  const [isAdvancedSettingsClicked, setIsAdvancedSettingsClicked] = useState(false);
  const getSupportedBacklogColumnIds = useGetSupportedBacklogColumnIds();

  const defaultGameName = useMemo(
    () =>
      "Planning Poker game, " +
      new Intl.DateTimeFormat(userLocale, { day: "numeric", month: "short", year: "numeric" }).format(new Date()),
    [userLocale],
  );

  const defaultConfig: GameFormConfig = useMemo(
    (): GameFormConfig => ({
      name: defaultGameName,
      private: false,
      estimationFieldId: "",
      cards: DECKS.fibonacci,
      coffeeCardsEnabled: true,
      participants: { [userAccountId]: { accountId: userAccountId, isSpectator: false } },
      admins: { [userAccountId]: true },
      sendEmails: false,
      backlog: [],
      enableEstimationContext: true,
      limitEstimationContext: false,
      estimationContextCustomQuery: "",
      backlogColumnIds: [],
      estimatedLabel: null,
      skippedLabel: null,
      estimatedTransition: null,
      estimatedSprintId: null,
      layoutFields: ["reporter", "labels", "components"],
      jql: buildJQLClause(project?.name, statuses),
    }),
    [defaultGameName, userAccountId, project?.name, statuses],
  );
  const [gameConfig, setGameConfig] = useState<GameFormConfig>(defaultConfig);

  const resetForm = useCallback(() => {
    if (lastUserGame) {
      setGameConfig({
        ...defaultConfig,
        ...lastUserGame.configuration,
        name: defaultGameName,
        participants: lastUserGame.participants,
        backlog: [],
      });
    } else {
      setGameConfig({ ...defaultConfig });
    }
    setIsDirty(false);
    setStep(1);
  }, [defaultConfig, lastUserGame, defaultGameName]);

  useEffect(() => {
    if (lastUserGame) {
      setGameConfig((prev) => ({
        ...prev,
        ...lastUserGame.configuration,
        backlogColumnIds: getSupportedBacklogColumnIds(lastUserGame.configuration.backlogColumnIds ?? []),
        layoutFields: lastUserGame ? lastUserGame.configuration.layoutFields || [] : prev.layoutFields,
        name: defaultGameName,
        backlog: [],
        participants: { ...lastUserGame.participants, ...prev.participants },
      }));
    }
  }, [defaultGameName, getSupportedBacklogColumnIds, lastUserGame, userAccountId]);

  const onConfigChange = useCallback((toUpdate: Partial<GameFormConfig>) => {
    setIsDirty(true);
    setGameConfig((prev) => ({ ...prev, ...toUpdate }));
  }, []);

  const { issues, setIssues, isLoadingIssues, setIssueKeys } = useQueryIssuesById(gameConfig);
  usePreloadSprintData({ setGameConfig, setIssueKeys, setIsSprintLoading });

  const onOrderChange = useCallback(
    (items: JiraIssue[]) => {
      setIssues?.(items);
    },
    [setIssues],
  );

  useEffect(() => {
    onConfigChange({ backlog: issues.map((i) => i.key) });
  }, [issues, onConfigChange]);

  const content = useMemo(() => {
    switch (step) {
      case 1:
        return (
          <GameConfigForm
            gameConfig={gameConfig}
            onConfigChange={onConfigChange}
            isNewGame
            gameCreatorId={userAccountId}
            onAdvancedSettingsTabClick={() => setIsAdvancedSettingsClicked(true)}
          />
        );
      case 2:
        return (
          <Backlog
            gameConfig={gameConfig}
            onConfigChange={onConfigChange}
            issues={issues}
            onOrderChange={onOrderChange}
            setIssueKeys={setIssueKeys}
            isLoadingIssues={isLoadingIssues}
          />
        );
    }
  }, [step, gameConfig, onConfigChange, userAccountId, issues, onOrderChange, setIssueKeys, isLoadingIssues]);

  const stepValues: Step[] = [1, 2];

  const onFormLeave = useCallback(() => {
    navigate("/");
    resetForm();
  }, [navigate, resetForm]);

  const onClose = useCallback(() => (isDirty ? setIsLeaveFormModalOpen(true) : onFormLeave()), [isDirty, onFormLeave]);

  const onBack = useCallback(() => {
    switch (step) {
      case 1:
        onClose();
        break;
      case 2:
        setStep(1);
        break;
    }
  }, [onClose, step]);

  const onNext = useCallback(async () => {
    switch (step) {
      case 1:
        setStep(2);
        break;
      case 2: {
        const result = await createGame(gameConfig, { advanced_expanded: isAdvancedSettingsClicked }, lastUserGame);
        if (result?.key) {
          navigate(`/game/${result.key}`);
          history.pushState({ from: "create-game" }, "");
        }
        break;
      }
    }
  }, [createGame, gameConfig, isAdvancedSettingsClicked, lastUserGame, navigate, step]);

  const isFormValid = useMemo(() => isGameFormConfigValid(gameConfig), [gameConfig]);
  const headerTitle = useMemo(() => (step === 1 ? "New game setup" : "New Game backlog setup"), [step]);
  const isPrimary = step === 1 || (step === 2 && issues.length > 0);

  const nextActionDisabled = useMemo(
    () => (step === 1 ? !isFormValid || isSprintLoading : false),
    [isFormValid, isSprintLoading, step],
  );

  return (
    <>
      <FullPageDialog
        isOpen={isOpen}
        headerTitle={headerTitle}
        content={content}
        onClose={onClose}
        controls={
          <StepsIndicator
            stepIndex={step - 1}
            appearance={isPrimary ? "primary" : "default"}
            values={stepValues}
            backButtonText={step === 1 ? "Cancel" : "Back"}
            nextButtonText={step === 1 ? "Next step" : `Start game: ${issues.length} issues`}
            onBack={onBack}
            onNext={() => void onNext()}
            isNextDisabled={nextActionDisabled}
            isLoading={isSprintLoading || isLoadingIssues}
          />
        }
        maxWidth={step === 1 ? FULL_PAGE_DIALOG_CONTROLS_WIDTH : FULL_PAGE_DIALOG_MAX_WIDTH}
        transitionKey={String(step)}
      />
      <LeaveCreateFormModal
        isOpen={isLeaveFormModalOpen}
        onClose={() => setIsLeaveFormModalOpen(false)}
        onConfirm={() => {
          setIsLeaveFormModalOpen(false);
          onFormLeave();
        }}
      />
    </>
  );
}
