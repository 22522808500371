import { useCallback } from "react";

import { useGetGameById } from "../../../services/firebase";
import { GameFinished } from "../../ampli";
import { getActiveParticipantsCount, getEstimatedIssuesCount } from "../../utils";
import { useRecordEvent } from "./useRecordEvent";

export function useGameFinishedEvent() {
  const recordEvent = useRecordEvent(GameFinished);
  const getGameById = useGetGameById();

  return useCallback(
    (gameId: string) =>
      void (async () => {
        const game = await getGameById(gameId);
        recordEvent({
          active_participants: getActiveParticipantsCount(game),
          estimated_issues: getEstimatedIssuesCount(game),
          game_id: gameId,
        });
      })(),
    [getGameById, recordEvent],
  );
}
