import { useCallback, useState } from "react";

const IS_EXPANDED_LS_KEY = "referenceIssuesIsExpanded";

export const useIsExpandedState = () => {
  const [isExpandedString, setIsExpandedString] = useState(localStorage.getItem(IS_EXPANDED_LS_KEY) || "true");
  const isExpanded = isExpandedString === "true";

  const toggleIsExpanded = useCallback(() => {
    const nextState = String(!isExpanded);
    localStorage.setItem(IS_EXPANDED_LS_KEY, nextState);
    setIsExpandedString(nextState);
  }, [isExpanded]);

  return {
    isExpanded,
    toggleIsExpanded,
  };
};
