// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attachments {
    display: flex;
    gap: 8px;
    overflow: auto;
    padding: 12px 16px;
}

.attachments .thumbnail {
    border-radius: 3px;
    width: 140px;
    overflow: hidden;
    flex-shrink: 0;
    box-shadow: var(--ds-shadow-raised, 0 1px 1px rgba(9, 30, 66, 0.31), 0 0 1px 0 rgba(9, 30, 66, 0.31));
}

.attachments .pointer {
    cursor: pointer;
}

.attachments .cover {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.attachments .cover img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.attachments .attachment-details {
    height: 36px;
    padding: 4px 8px;
}

.attachments .attachment-details .filename {
    font-size: 11px;
    font-weight: 600;
    white-space: nowrap;
}

.attachments .attachment-details .created {
    font-size: 11px;
}

.attachments .ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/components/Attachments.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,QAAQ;IACR,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,cAAc;IACd,qGAAqG;AACzG;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".attachments {\n    display: flex;\n    gap: 8px;\n    overflow: auto;\n    padding: 12px 16px;\n}\n\n.attachments .thumbnail {\n    border-radius: 3px;\n    width: 140px;\n    overflow: hidden;\n    flex-shrink: 0;\n    box-shadow: var(--ds-shadow-raised, 0 1px 1px rgba(9, 30, 66, 0.31), 0 0 1px 0 rgba(9, 30, 66, 0.31));\n}\n\n.attachments .pointer {\n    cursor: pointer;\n}\n\n.attachments .cover {\n    height: 100px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.attachments .cover img {\n    object-fit: cover;\n    height: 100%;\n    width: 100%;\n}\n\n.attachments .attachment-details {\n    height: 36px;\n    padding: 4px 8px;\n}\n\n.attachments .attachment-details .filename {\n    font-size: 11px;\n    font-weight: 600;\n    white-space: nowrap;\n}\n\n.attachments .attachment-details .created {\n    font-size: 11px;\n}\n\n.attachments .ellipsis {\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
