import React, { useCallback } from "react";

import { useApplicationContext } from "../../providers/ApplicationContextProvider";
import { getSupportURL } from "../../utils/feedback";
import { ChangelogEntry } from "./ChangelogEntry";
import { ChangelogItem } from "./index";

const date = new Date("2024-07-27");

function July2024() {
  const { hostBaseUrl, addonKey } = useApplicationContext();

  const openSupportUrl = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      void getSupportURL(addonKey, hostBaseUrl).then((supportURL) =>
        window.open(supportURL, "_blank", "noreferrer noopener"),
      );
    },
    [addonKey, hostBaseUrl],
  );

  return (
    <ChangelogEntry title="The biggest Planning Poker update ever!" date={date}>
      <p>
        Planning Poker appeared on the Atlassian marketplace a decade ago 🥳. To celebrate this with you, we’re
        introducing <strong>Planning Poker 2.0</strong> - the next-generation app with a brand-new design, multiple new
        features, and a reworked backend. The changes include:
      </p>
      <ul>
        <li>Increased performance, security, and reporting;</li>
        <li>Brand new Session-less Poker - estimation from issue details;</li>
        <li>New Game Dashboard experience;</li>
        <li>New poker game controls;</li>
        <li>Reworked participants approach;</li>
        <li>New customizable issue layout;</li>
        <li>Improved Reference issues panel;</li>
        <li>Brand new game backlog management;</li>
        <li>Private game support;</li>
        <li>Dark Theme support;</li>
      </ul>
      <p>
        and many more,{" "}
        <a href="https://apps.appf.re/poker/doc/release/poker20" target="_blank" rel="noopener noreferrer">
          click here
        </a>{" "}
        for details.
      </p>
      <p>As always, we would appreciate your feedback:</p>
      <ul>
        <li>
          <a href="#" onClick={openSupportUrl} target="_blank" rel="noopener noreferrer">
            Help Center
          </a>
        </li>
        <li>
          <a href="mailto:support@appfire.com" rel="noopener noreferrer">
            support@appfire.com
          </a>
        </li>
      </ul>
    </ChangelogEntry>
  );
}

export default {
  Component: July2024,
  date,
} as ChangelogItem;
