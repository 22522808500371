import React, { useCallback, useEffect, useRef, useState } from "react";

import Spinner from "@atlaskit/spinner";
import { Box } from "@fuegokit/react";

export interface InfiniteLoaderProps {
  loading?: boolean;
  hasMore?: boolean;
  loadNextPage: () => void;
}

export function useInfiniteScroll(loadNextPage: () => void) {
  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const handleObserver = useCallback(
    ([target]: IntersectionObserverEntry[]) => {
      if (target.isIntersecting) {
        loadNextPage();
      }
    },
    [loadNextPage],
  );

  const observer = useRef(new IntersectionObserver(handleObserver));

  useEffect(() => {
    const currentObserver = observer.current;
    if (element) {
      currentObserver.observe(element);
    }

    return () => {
      if (element) {
        currentObserver.unobserve(element);
      }
    };
  }, [handleObserver, element]);

  return { setElement };
}

export function InfiniteLoader({ loading, hasMore, loadNextPage }: InfiniteLoaderProps) {
  const { setElement } = useInfiniteScroll(loadNextPage);

  return (
    <>
      {loading && (
        <Box display="flex" justifyContent="center" p={1} alignItems={"center"} style={{ gap: "15px" }}>
          <Spinner />
          <Box>Loading more issues...</Box>
        </Box>
      )}
      {hasMore && !loading && <Box ref={setElement} pb="32px" width="100%" />}
    </>
  );
}
