import React from "react";

import { ErrorMessage } from "../ErrorMessage";

export function AccessDeniedMessage() {
  return (
    <ErrorMessage
      title="Access denied"
      text="Probably you don't belong to groups specified in global configuration. Contact your Jira Administrator."
    />
  );
}
