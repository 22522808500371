import React from "react";
import styled from "styled-components";

import { Text } from "@fuegokit/react";

const EmptyStateContainer = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

export function EmptyState() {
  return (
    <EmptyStateContainer>
      <img src="/images/dashboard_empty_state.svg" alt="No matching games found" width={207} />
      <Text>No matching games found</Text>
    </EmptyStateContainer>
  );
}
