import React from "react";
import styled from "styled-components";

import Button from "@atlaskit/button";

import { FULL_PAGE_DIALOG_CONTROLS_WIDTH } from "../../../../utils";

const ModalControlsContainer = styled.div`
  padding: 16px 0;
  width: ${FULL_PAGE_DIALOG_CONTROLS_WIDTH};
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface ModalControlsProps {
  isSaveDisabled: boolean;
  onClose: () => void;
  onSave: () => void;
}

export function ModalControls({ isSaveDisabled, onClose, onSave }: Readonly<ModalControlsProps>) {
  return (
    <ModalControlsContainer>
      <Button appearance="subtle" onClick={onClose}>
        Close
      </Button>
      <Button appearance="primary" onClick={onSave} isDisabled={isSaveDisabled}>
        Save & close
      </Button>
    </ModalControlsContainer>
  );
}
