import { useCallback } from "react";

import { BaseEvent } from "../../ampli";
import { useAnalyticsContext } from "../../AnalyticsContextProvider";

export function useRecordEvent<T extends BaseEvent>(EventClass: new (properties: T["event_properties"]) => T) {
  const { recordEvent } = useAnalyticsContext();

  return useCallback(
    (properties: T["event_properties"]) => {
      const event = new EventClass(properties);
      recordEvent(event);
    },
    [EventClass, recordEvent],
  );
}
