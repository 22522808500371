import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { GameReopened } from "../../analytics/ampli";
import { useRecordEvent } from "../../analytics/hooks/events/useRecordEvent";
import { useGameSetActiveItemId, useRestartGame } from "../../services/firebase";
import { showFlag } from "../../services/jira-api";
import { AbstractModal } from "../dashboard/modals/modals";
import { useGameData } from "./GameProvider";

export function GameRestartGameModal() {
  const { id } = useParams() as { id: string };
  const { game } = useGameData();
  const gameSetActiveItemId = useGameSetActiveItemId(id);
  const backlog = useMemo(() => game.configuration.backlog || [], [game.configuration.backlog]);
  const recordEvent = useRecordEvent(GameReopened);

  const navigate = useNavigate();
  const goBack = useCallback(() => navigate("..", { relative: "path" }), [navigate]);

  const restartGame = useRestartGame();
  const confirmFinishGames = useCallback(async () => {
    try {
      await restartGame(id);
      recordEvent({ game_id: id });
      showFlag("Game restarted", "", "success");
      await gameSetActiveItemId(backlog[0]);
      goBack();
    } catch (e) {
      console.error(e);
      showFlag("Failed to restart game", "", "error");
      goBack();
    }
  }, [restartGame, id, recordEvent, gameSetActiveItemId, backlog, goBack]);

  return (
    <AbstractModal title="Restart game" width="small" onClose={goBack} onConfirm={confirmFinishGames}>
      By confirming game will be restarted. All provided personal estimates will be erased.
    </AbstractModal>
  );
}
