// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
}

th {
  vertical-align: middle !important;
}

.game-filter {
  width: 240px;
  text-transform: capitalize;
}
.select-filter-label {
  font-weight: 700;
  margin-right: 5px;
}

`, "",{"version":3,"sources":["webpack://./src/components/dashboard/dashboard.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,YAAY;EACZ,0BAA0B;AAC5B;AACA;EACE,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":["table {\n  width: 100%;\n}\n\nth {\n  vertical-align: middle !important;\n}\n\n.game-filter {\n  width: 240px;\n  text-transform: capitalize;\n}\n.select-filter-label {\n  font-weight: 700;\n  margin-right: 5px;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
