import React from "react";

import { Box } from "@fuegokit/react";

import { GameFormConfig } from "../../types";
import { AdvancedSettingsSection } from "./AdvancedSettingsSection";
import { AutomationSection } from "./AutomationSection";
import { EstimationContext } from "./EstimationContext";
import { LayoutCustomization } from "./LayoutCustomization";

interface AdvancedSettingsFormProps {
  gameConfig: GameFormConfig;
  onConfigChange: (toUpdate: Partial<GameFormConfig>) => void;
}

export function AdvancedSettingsForm({ gameConfig, onConfigChange }: AdvancedSettingsFormProps) {
  return (
    <Box width="100%" marginTop="8px" paddingBottom="16px">
      <AdvancedSettingsSection
        title="Layout"
        content={<LayoutCustomization gameConfig={gameConfig} onConfigChange={onConfigChange} />}
      />
      <AdvancedSettingsSection
        title="Estimation context"
        content={<EstimationContext gameConfig={gameConfig} onConfigChange={onConfigChange} />}
      />
      <AdvancedSettingsSection
        title="Automation"
        content={<AutomationSection gameConfig={gameConfig} onConfigChange={onConfigChange} />}
      />
    </Box>
  );
}
