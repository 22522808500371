import React from "react";

import { Box } from "@fuegokit/react";

export function EmptySearchResultImage() {
  return (
    <Box py={4}>
      <svg width="177" height="92" viewBox="0 0 177 92" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M167.337 7.9458H16.9331V91.6144H167.337V7.9458Z" fill="#CFD4DB" />
        <path d="M167.338 7.9458H58.7676V46.7919H167.338V7.9458Z" fill="url(#paint0_linear_649_62001)" />
        <path
          d="M42.6717 7.94585C35.6218 7.93449 28.733 9.9757 22.9092 13.8016V24.6349C26.4486 20.8686 31.0688 18.1984 36.1849 16.9621C41.301 15.7259 46.683 15.9793 51.6495 17.6903C56.616 19.4012 60.9437 22.4927 64.0847 26.5735C67.2257 30.6543 69.0388 35.5408 69.2944 40.6144C69.55 45.688 68.2367 50.7206 65.5207 55.075C62.8047 59.4294 58.8081 62.9099 54.0369 65.0758C49.2657 67.2417 43.9345 67.9957 38.7181 67.2422C33.5017 66.4888 28.6348 64.2618 24.7334 60.8432L22.9092 59.0864V69.9196C27.4651 72.8682 32.6745 74.7488 38.1204 75.4108C43.5664 76.0729 49.0976 75.4982 54.2716 73.7325C59.4455 71.9669 64.1184 69.0594 67.9162 65.2428C71.7141 61.4261 74.5314 56.8063 76.1428 51.7531C77.7542 46.7 78.1148 41.3537 77.1959 36.1422C76.277 30.9307 74.104 25.9987 70.8509 21.7409C67.5977 17.4832 63.3547 14.0179 58.4615 11.6225C53.5683 9.227 48.1608 7.96787 42.6717 7.94585Z"
          fill="#CFD4DB"
        />
        <path d="M15.9284 59.084L0 75.0125L4.55097 79.5634L20.4794 63.635L15.9284 59.084Z" fill="#0065FF" />
        <path
          d="M38.7283 70.6971C55.5065 70.6971 69.108 57.0957 69.108 40.3174C69.108 23.5392 55.5065 9.93774 38.7283 9.93774C21.9501 9.93774 8.34863 23.5392 8.34863 40.3174C8.34863 57.0957 21.9501 70.6971 38.7283 70.6971Z"
          fill="#E5E8EC"
        />
        <path
          d="M68.7278 48.7839H25.8975V68.705H42.3324L58.7673 65.2188L60.7594 60.2386L68.7278 48.7839Z"
          fill="white"
        />
        <path d="M92.6329 25.8748H70.7197V31.8511H92.6329V25.8748Z" fill="white" />
        <path d="M146.42 48.7839H56.7749V53.7642H146.42V48.7839Z" fill="white" />
        <path d="M146.42 57.7485H56.7749V62.7288H146.42V57.7485Z" fill="white" />
        <path d="M146.42 66.7129H56.7749V71.6932H146.42V66.7129Z" fill="white" />
        <path d="M119.526 25.8748H97.6133V31.8511H119.526V25.8748Z" fill="white" />
        <path d="M146.42 25.8748H124.507V31.8511H146.42V25.8748Z" fill="white" />
        <path d="M63.7476 25.8748H25.8975V37.8274H63.7476V25.8748Z" fill="white" />
        <path
          d="M30.8774 33.6731V29.1247H32.455C33.2409 29.1247 33.7977 29.7141 33.7977 30.5778V30.5841C33.7977 31.4477 33.2409 32.0498 32.455 32.0498H31.3994V33.6731H30.8774ZM32.3216 29.629H31.3994V31.5455H32.3216C32.919 31.5455 33.2641 31.1893 33.2641 30.5904V30.5841C33.2641 29.9852 32.919 29.629 32.3216 29.629Z"
          fill="#44546F"
        />
        <path
          d="M34.5894 33.6731V30.2752H35.094V30.7795H35.1404C35.2593 30.4233 35.5522 30.2153 35.9756 30.2153C36.0713 30.2153 36.1786 30.2279 36.2279 30.2373V30.77C36.1235 30.7511 36.0278 30.7385 35.9176 30.7385C35.4362 30.7385 35.094 31.0695 35.094 31.5675V33.6731H34.5894Z"
          fill="#44546F"
        />
        <path
          d="M38.0491 33.733C37.1588 33.733 36.6078 33.0648 36.6078 31.9773V31.971C36.6078 30.8804 37.1588 30.2153 38.0491 30.2153C38.9394 30.2153 39.4904 30.8804 39.4904 31.971V31.9773C39.4904 33.0648 38.9394 33.733 38.0491 33.733ZM38.0491 33.2476C38.6407 33.2476 38.9742 32.7779 38.9742 31.9773V31.971C38.9742 31.1672 38.6407 30.7007 38.0491 30.7007C37.4575 30.7007 37.124 31.1672 37.124 31.971V31.9773C37.124 32.7779 37.4575 33.2476 38.0491 33.2476Z"
          fill="#44546F"
        />
        <path
          d="M40.5344 29.6195C40.343 29.6195 40.1864 29.4493 40.1864 29.2413C40.1864 29.0332 40.343 28.863 40.5344 28.863C40.7258 28.863 40.8824 29.0332 40.8824 29.2413C40.8824 29.4493 40.7258 29.6195 40.5344 29.6195ZM39.8268 34.8394H39.7398V34.3539H39.8152C40.1545 34.3539 40.2821 34.2247 40.2821 33.8496V30.2752H40.7867V33.8402C40.7867 34.5399 40.4822 34.8394 39.8268 34.8394Z"
          fill="#44546F"
        />
        <path
          d="M43.0167 33.733C42.1235 33.733 41.5841 33.0521 41.5841 31.9867V31.9836C41.5841 30.9339 42.1351 30.2153 42.9848 30.2153C43.8345 30.2153 44.3507 30.9024 44.3507 31.9174V32.1317H42.1003C42.1148 32.8346 42.4715 33.2444 43.0283 33.2444C43.4517 33.2444 43.7127 33.0269 43.7968 32.822L43.8084 32.7937H44.313L44.3072 32.8189C44.1999 33.2791 43.7533 33.733 43.0167 33.733ZM42.9819 30.7038C42.5179 30.7038 42.1641 31.0474 42.109 31.6936H43.8374C43.7852 31.0222 43.443 30.7038 42.9819 30.7038Z"
          fill="#44546F"
        />
        <path
          d="M46.4126 33.733C45.5368 33.733 44.9771 33.0427 44.9771 31.9647V31.9584C44.9771 30.9024 45.5339 30.2153 46.4097 30.2153C47.1695 30.2153 47.6248 30.6912 47.7176 31.2964L47.7205 31.3153H47.2188L47.2159 31.3059C47.1376 30.9718 46.8592 30.7007 46.4097 30.7007C45.8471 30.7007 45.4933 31.1924 45.4933 31.9584V31.9647C45.4933 32.7464 45.8529 33.2476 46.4097 33.2476C46.8302 33.2476 47.097 33.0458 47.213 32.6676L47.2188 32.6487L47.7176 32.6455L47.7118 32.6802C47.5842 33.2949 47.1637 33.733 46.4126 33.733Z"
          fill="#44546F"
        />
        <path
          d="M49.6258 33.6983C48.982 33.6983 48.7123 33.4398 48.7123 32.7905V30.7291H48.2193V30.2752H48.7123V29.3957H49.2343V30.2752H49.9187V30.7291H49.2343V32.6518C49.2343 33.0521 49.3619 33.2129 49.6867 33.2129C49.7766 33.2129 49.8259 33.2097 49.9187 33.2003V33.6668C49.8201 33.6857 49.7244 33.6983 49.6258 33.6983Z"
          fill="#44546F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.497 30.5805C55.4177 30.6676 55.3733 30.7851 55.3733 30.9076C55.3733 31.0301 55.4177 31.1477 55.497 31.2348L56.7438 32.602C56.8363 32.7011 56.9559 32.7504 57.0742 32.7504C57.1926 32.7504 57.3101 32.7011 57.4005 32.602L58.6435 31.2394C58.7226 31.1522 58.767 31.0347 58.767 30.9122C58.767 30.7898 58.7226 30.6722 58.6435 30.5851C58.6045 30.5421 58.5581 30.508 58.5069 30.4847C58.4557 30.4614 58.4007 30.4494 58.3452 30.4494C58.2897 30.4494 58.2348 30.4614 58.1836 30.4847C58.1324 30.508 58.086 30.5421 58.047 30.5851L57.0721 31.6535L56.0934 30.5805C56.0544 30.5377 56.0079 30.5037 55.9568 30.4805C55.9056 30.4573 55.8507 30.4454 55.7952 30.4454C55.7398 30.4454 55.6849 30.4573 55.6337 30.4805C55.5825 30.5037 55.536 30.5377 55.497 30.5805Z"
          fill="#626F86"
        />
        <path
          opacity="0.2"
          d="M43.2106 10.2368C34.5709 10.5237 26.3162 13.8798 19.9272 19.7028C13.5383 25.5258 9.4333 33.4349 8.34863 42.011C8.68303 48.0164 10.7925 53.7874 14.4099 58.5928C18.0272 63.3981 22.9895 67.0216 28.6682 69.0041C25.5181 67.0357 22.7996 64.4493 20.6768 61.4011C18.554 58.353 17.0709 54.9062 16.3172 51.269C15.5634 47.6318 15.5545 43.8795 16.2911 40.2388C17.0277 36.5981 18.4945 33.1443 20.6028 30.0862C22.7112 27.028 25.4175 24.4289 28.5582 22.4456C31.699 20.4624 35.209 19.1362 38.8765 18.5471C42.544 17.958 46.2929 18.1183 49.8967 19.0182C53.5006 19.9181 56.8847 21.5391 59.8447 23.7832C63.2959 26.1182 66.1596 29.2206 68.2115 32.8473C66.7067 27.0492 63.5375 21.8186 59.0947 17.8006C54.652 13.7827 49.1302 11.1533 43.2106 10.2368Z"
          fill="#091E42"
        />
        <path
          d="M39.2599 72.5974C32.7679 72.5974 26.4218 70.6723 21.0239 67.0656C15.6261 63.4588 11.419 58.3325 8.93462 52.3347C6.45026 46.3369 5.80024 39.7371 7.06675 33.3699C8.33327 27.0027 11.4594 21.1541 16.0499 16.5636C20.6404 11.9731 26.4891 8.84694 32.8563 7.58042C39.2235 6.31391 45.8232 6.96393 51.821 9.44829C57.8188 11.9326 62.9452 16.1398 66.5519 21.5376C70.1586 26.9355 72.0837 33.2816 72.0837 39.7736C72.0837 48.479 68.6255 56.8279 62.4698 62.9835C56.3142 69.1392 47.9653 72.5974 39.2599 72.5974ZM39.2599 12.8286C33.9307 12.8286 28.7211 14.4089 24.2901 17.3697C19.859 20.3304 16.4054 24.5386 14.366 29.4622C12.3266 34.3857 11.793 39.8035 12.8327 45.0303C13.8724 50.2571 16.4386 55.0582 20.2069 58.8265C23.9753 62.5948 28.7764 65.1611 34.0032 66.2007C39.23 67.2404 44.6477 66.7068 49.5713 64.6674C54.4948 62.628 58.703 59.1744 61.6638 54.7434C64.6245 50.3123 66.2048 45.1028 66.2048 39.7736C66.2048 32.6273 63.366 25.7738 58.3128 20.7206C53.2597 15.6675 46.4061 12.8286 39.2599 12.8286Z"
          fill="#0065FF"
        />
        <rect x="148.673" y="3.08813" width="28.3186" height="28.3186" rx="10" fill="#B9C0CA" />
        <path d="M167.337 7.9458H16.9331V91.6144H167.337V7.9458Z" fill="#CFD4DB" />
        <path d="M167.338 7.9458H58.7676V46.7919H167.338V7.9458Z" fill="url(#paint1_linear_649_62001)" />
        <path
          d="M42.6717 7.94585C35.6218 7.93449 28.733 9.9757 22.9092 13.8016V24.6349C26.4486 20.8686 31.0688 18.1984 36.1849 16.9621C41.301 15.7259 46.683 15.9793 51.6495 17.6903C56.616 19.4012 60.9437 22.4927 64.0847 26.5735C67.2257 30.6543 69.0388 35.5408 69.2944 40.6144C69.55 45.688 68.2367 50.7206 65.5207 55.075C62.8047 59.4294 58.8081 62.9099 54.0369 65.0758C49.2657 67.2417 43.9345 67.9957 38.7181 67.2422C33.5017 66.4888 28.6348 64.2618 24.7334 60.8432L22.9092 59.0864V69.9196C27.4651 72.8682 32.6745 74.7488 38.1204 75.4108C43.5664 76.0729 49.0976 75.4982 54.2716 73.7325C59.4455 71.9669 64.1184 69.0594 67.9162 65.2428C71.7141 61.4261 74.5314 56.8063 76.1428 51.7531C77.7542 46.7 78.1148 41.3537 77.1959 36.1422C76.277 30.9307 74.104 25.9987 70.8509 21.7409C67.5977 17.4832 63.3547 14.0179 58.4615 11.6225C53.5683 9.227 48.1608 7.96787 42.6717 7.94585Z"
          fill="#CFD4DB"
        />
        <path d="M15.9284 59.084L0 75.0125L4.55097 79.5634L20.4794 63.635L15.9284 59.084Z" fill="#8270DB" />
        <path
          d="M38.7283 70.6971C55.5065 70.6971 69.108 57.0957 69.108 40.3174C69.108 23.5392 55.5065 9.93774 38.7283 9.93774C21.9501 9.93774 8.34863 23.5392 8.34863 40.3174C8.34863 57.0957 21.9501 70.6971 38.7283 70.6971Z"
          fill="#E5E8EC"
        />
        <path
          d="M68.7278 48.7839H25.8975V68.705H42.3324L58.7673 65.2188L60.7594 60.2386L68.7278 48.7839Z"
          fill="white"
        />
        <path d="M92.6329 25.8748H70.7197V31.8511H92.6329V25.8748Z" fill="white" />
        <path d="M146.42 48.7839H56.7749V53.7642H146.42V48.7839Z" fill="white" />
        <path d="M146.42 57.7485H56.7749V62.7288H146.42V57.7485Z" fill="white" />
        <path d="M146.42 66.7129H56.7749V71.6932H146.42V66.7129Z" fill="white" />
        <path d="M119.526 25.8748H97.6133V31.8511H119.526V25.8748Z" fill="white" />
        <path d="M146.42 25.8748H124.507V31.8511H146.42V25.8748Z" fill="white" />
        <path d="M63.7476 25.8748H25.8975V37.8274H63.7476V25.8748Z" fill="white" />
        <path
          d="M30.8774 33.6731V29.1247H32.455C33.2409 29.1247 33.7977 29.7141 33.7977 30.5778V30.5841C33.7977 31.4477 33.2409 32.0498 32.455 32.0498H31.3994V33.6731H30.8774ZM32.3216 29.629H31.3994V31.5455H32.3216C32.919 31.5455 33.2641 31.1893 33.2641 30.5904V30.5841C33.2641 29.9852 32.919 29.629 32.3216 29.629Z"
          fill="#44546F"
        />
        <path
          d="M34.5894 33.6731V30.2752H35.094V30.7795H35.1404C35.2593 30.4233 35.5522 30.2153 35.9756 30.2153C36.0713 30.2153 36.1786 30.2279 36.2279 30.2373V30.77C36.1235 30.7511 36.0278 30.7385 35.9176 30.7385C35.4362 30.7385 35.094 31.0695 35.094 31.5675V33.6731H34.5894Z"
          fill="#44546F"
        />
        <path
          d="M38.0491 33.733C37.1588 33.733 36.6078 33.0648 36.6078 31.9773V31.971C36.6078 30.8804 37.1588 30.2153 38.0491 30.2153C38.9394 30.2153 39.4904 30.8804 39.4904 31.971V31.9773C39.4904 33.0648 38.9394 33.733 38.0491 33.733ZM38.0491 33.2476C38.6407 33.2476 38.9742 32.7779 38.9742 31.9773V31.971C38.9742 31.1672 38.6407 30.7007 38.0491 30.7007C37.4575 30.7007 37.124 31.1672 37.124 31.971V31.9773C37.124 32.7779 37.4575 33.2476 38.0491 33.2476Z"
          fill="#44546F"
        />
        <path
          d="M40.5344 29.6195C40.343 29.6195 40.1864 29.4493 40.1864 29.2413C40.1864 29.0332 40.343 28.863 40.5344 28.863C40.7258 28.863 40.8824 29.0332 40.8824 29.2413C40.8824 29.4493 40.7258 29.6195 40.5344 29.6195ZM39.8268 34.8394H39.7398V34.3539H39.8152C40.1545 34.3539 40.2821 34.2247 40.2821 33.8496V30.2752H40.7867V33.8402C40.7867 34.5399 40.4822 34.8394 39.8268 34.8394Z"
          fill="#44546F"
        />
        <path
          d="M43.0167 33.733C42.1235 33.733 41.5841 33.0521 41.5841 31.9867V31.9836C41.5841 30.9339 42.1351 30.2153 42.9848 30.2153C43.8345 30.2153 44.3507 30.9024 44.3507 31.9174V32.1317H42.1003C42.1148 32.8346 42.4715 33.2444 43.0283 33.2444C43.4517 33.2444 43.7127 33.0269 43.7968 32.822L43.8084 32.7937H44.313L44.3072 32.8189C44.1999 33.2791 43.7533 33.733 43.0167 33.733ZM42.9819 30.7038C42.5179 30.7038 42.1641 31.0474 42.109 31.6936H43.8374C43.7852 31.0222 43.443 30.7038 42.9819 30.7038Z"
          fill="#44546F"
        />
        <path
          d="M46.4126 33.733C45.5368 33.733 44.9771 33.0427 44.9771 31.9647V31.9584C44.9771 30.9024 45.5339 30.2153 46.4097 30.2153C47.1695 30.2153 47.6248 30.6912 47.7176 31.2964L47.7205 31.3153H47.2188L47.2159 31.3059C47.1376 30.9718 46.8592 30.7007 46.4097 30.7007C45.8471 30.7007 45.4933 31.1924 45.4933 31.9584V31.9647C45.4933 32.7464 45.8529 33.2476 46.4097 33.2476C46.8302 33.2476 47.097 33.0458 47.213 32.6676L47.2188 32.6487L47.7176 32.6455L47.7118 32.6802C47.5842 33.2949 47.1637 33.733 46.4126 33.733Z"
          fill="#44546F"
        />
        <path
          d="M49.6258 33.6983C48.982 33.6983 48.7123 33.4398 48.7123 32.7905V30.7291H48.2193V30.2752H48.7123V29.3957H49.2343V30.2752H49.9187V30.7291H49.2343V32.6518C49.2343 33.0521 49.3619 33.2129 49.6867 33.2129C49.7766 33.2129 49.8259 33.2097 49.9187 33.2003V33.6668C49.8201 33.6857 49.7244 33.6983 49.6258 33.6983Z"
          fill="#44546F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.497 30.5805C55.4177 30.6676 55.3733 30.7851 55.3733 30.9076C55.3733 31.0301 55.4177 31.1477 55.497 31.2348L56.7438 32.602C56.8363 32.7011 56.9559 32.7504 57.0742 32.7504C57.1926 32.7504 57.3101 32.7011 57.4005 32.602L58.6435 31.2394C58.7226 31.1522 58.767 31.0347 58.767 30.9122C58.767 30.7898 58.7226 30.6722 58.6435 30.5851C58.6045 30.5421 58.5581 30.508 58.5069 30.4847C58.4557 30.4614 58.4007 30.4494 58.3452 30.4494C58.2897 30.4494 58.2348 30.4614 58.1836 30.4847C58.1324 30.508 58.086 30.5421 58.047 30.5851L57.0721 31.6535L56.0934 30.5805C56.0544 30.5377 56.0079 30.5037 55.9568 30.4805C55.9056 30.4573 55.8507 30.4454 55.7952 30.4454C55.7398 30.4454 55.6849 30.4573 55.6337 30.4805C55.5825 30.5037 55.536 30.5377 55.497 30.5805Z"
          fill="#626F86"
        />
        <path
          opacity="0.2"
          d="M43.2106 10.2368C34.5709 10.5237 26.3162 13.8798 19.9272 19.7028C13.5383 25.5258 9.4333 33.4349 8.34863 42.011C8.68303 48.0164 10.7925 53.7874 14.4099 58.5928C18.0272 63.3981 22.9895 67.0216 28.6682 69.0041C25.5181 67.0357 22.7996 64.4493 20.6768 61.4011C18.554 58.353 17.0709 54.9062 16.3172 51.269C15.5634 47.6318 15.5545 43.8795 16.2911 40.2388C17.0277 36.5981 18.4945 33.1443 20.6028 30.0862C22.7112 27.028 25.4175 24.4289 28.5582 22.4456C31.699 20.4624 35.209 19.1362 38.8765 18.5471C42.544 17.958 46.2929 18.1183 49.8967 19.0182C53.5006 19.9181 56.8847 21.5391 59.8447 23.7832C63.2959 26.1182 66.1596 29.2206 68.2115 32.8473C66.7067 27.0492 63.5375 21.8186 59.0947 17.8006C54.652 13.7827 49.1302 11.1533 43.2106 10.2368Z"
          fill="#091E42"
        />
        <path
          d="M39.2599 72.5974C32.7679 72.5974 26.4218 70.6723 21.0239 67.0656C15.6261 63.4588 11.419 58.3325 8.93462 52.3347C6.45026 46.3369 5.80024 39.7371 7.06675 33.3699C8.33327 27.0027 11.4594 21.1541 16.0499 16.5636C20.6404 11.9731 26.4891 8.84694 32.8563 7.58042C39.2235 6.31391 45.8232 6.96393 51.821 9.44829C57.8188 11.9326 62.9452 16.1398 66.5519 21.5376C70.1586 26.9355 72.0837 33.2816 72.0837 39.7736C72.0837 48.479 68.6255 56.8279 62.4698 62.9835C56.3142 69.1392 47.9653 72.5974 39.2599 72.5974ZM39.2599 12.8286C33.9307 12.8286 28.7211 14.4089 24.2901 17.3697C19.859 20.3304 16.4054 24.5386 14.366 29.4622C12.3266 34.3857 11.793 39.8035 12.8327 45.0303C13.8724 50.2571 16.4386 55.0582 20.2069 58.8265C23.9753 62.5948 28.7764 65.1611 34.0032 66.2007C39.23 67.2404 44.6477 66.7068 49.5713 64.6674C54.4948 62.628 58.703 59.1744 61.6638 54.7434C64.6245 50.3123 66.2048 45.1028 66.2048 39.7736C66.2048 32.6273 63.366 25.7738 58.3128 20.7206C53.2597 15.6675 46.4061 12.8286 39.2599 12.8286Z"
          fill="#8270DB"
        />
        <rect x="148.673" y="3.08813" width="28.3186" height="28.3186" rx="8" fill="#B9C0CA" />
        <path
          d="M147 6.83789C147 3.52418 149.686 0.837891 153 0.837891H169C172.314 0.837891 175 3.52418 175 6.83789V22.8379C175 26.1516 172.314 28.8379 169 28.8379H153C149.686 28.8379 147 26.1516 147 22.8379V6.83789Z"
          fill="#6E5DC6"
        />
        <path
          d="M158.824 18.4004L158.812 17.7207C158.773 17.1504 158.82 16.6465 158.953 16.209C159.086 15.7715 159.312 15.373 159.633 15.0137C159.961 14.6543 160.391 14.3184 160.922 14.0059C161.43 13.7012 161.824 13.4238 162.105 13.1738C162.395 12.9238 162.598 12.6621 162.715 12.3887C162.832 12.1152 162.891 11.7949 162.891 11.4277V11.416C162.891 11.041 162.797 10.709 162.609 10.4199C162.422 10.1309 162.16 9.9043 161.824 9.74023C161.496 9.57617 161.109 9.49414 160.664 9.49414C160.219 9.49414 159.828 9.58398 159.492 9.76367C159.156 9.94336 158.891 10.1934 158.695 10.5137C158.5 10.8262 158.391 11.1855 158.367 11.5918L158.355 11.6738H155.18V11.5801C155.211 10.6582 155.449 9.83398 155.895 9.10742C156.348 8.38086 156.992 7.81055 157.828 7.39648C158.664 6.98242 159.68 6.77539 160.875 6.77539C161.992 6.77539 162.965 6.9668 163.793 7.34961C164.629 7.73242 165.277 8.26367 165.738 8.94336C166.207 9.62305 166.441 10.4121 166.441 11.3105V11.3223C166.441 12.2051 166.23 12.9668 165.809 13.6074C165.387 14.2402 164.762 14.7988 163.934 15.2832C163.418 15.5801 163.016 15.8574 162.727 16.1152C162.438 16.3652 162.234 16.6309 162.117 16.9121C162 17.1934 161.941 17.5176 161.941 17.8848V18.4004H158.824ZM160.453 24.0137C159.898 24.0137 159.434 23.8379 159.059 23.4863C158.684 23.127 158.496 22.6816 158.496 22.1504C158.496 21.6191 158.684 21.1777 159.059 20.8262C159.434 20.4746 159.898 20.2988 160.453 20.2988C161.008 20.2988 161.469 20.4746 161.836 20.8262C162.211 21.1777 162.398 21.6191 162.398 22.1504C162.398 22.6816 162.211 23.127 161.836 23.4863C161.469 23.8379 161.008 24.0137 160.453 24.0137Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_649_62001"
            x1="113.023"
            y1="46.7919"
            x2="113.023"
            y2="-8.46255"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E5E8EC" stopOpacity="0.1" />
            <stop offset="0.08" stopColor="#E1E4E9" stopOpacity="0.27" />
            <stop offset="0.18" stopColor="#DCE0E5" stopOpacity="0.46" />
            <stop offset="0.29" stopColor="#D8DCE2" stopOpacity="0.63" />
            <stop offset="0.4" stopColor="#D5D9DF" stopOpacity="0.76" />
            <stop offset="0.53" stopColor="#D2D7DD" stopOpacity="0.87" />
            <stop offset="0.66" stopColor="#D0D5DC" stopOpacity="0.94" />
            <stop offset="0.8" stopColor="#CFD4DB" stopOpacity="0.99" />
            <stop offset="1" stopColor="#CFD4DB" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_649_62001"
            x1="113.023"
            y1="46.7919"
            x2="113.023"
            y2="-8.46255"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E5E8EC" stopOpacity="0.1" />
            <stop offset="0.08" stopColor="#E1E4E9" stopOpacity="0.27" />
            <stop offset="0.18" stopColor="#DCE0E5" stopOpacity="0.46" />
            <stop offset="0.29" stopColor="#D8DCE2" stopOpacity="0.63" />
            <stop offset="0.4" stopColor="#D5D9DF" stopOpacity="0.76" />
            <stop offset="0.53" stopColor="#D2D7DD" stopOpacity="0.87" />
            <stop offset="0.66" stopColor="#D0D5DC" stopOpacity="0.94" />
            <stop offset="0.8" stopColor="#CFD4DB" stopOpacity="0.99" />
            <stop offset="1" stopColor="#CFD4DB" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}
