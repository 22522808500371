import React, { useEffect, useState } from "react";

import { getBoardById, getJiraSprintById, getJiraSprints } from "../../services/jira-api";
import { StringOptionType } from "../../types";

interface SprintProviderProps {
  selectedSprintId: string | undefined;
  children: ({
    sprints,
    selectedBoard,
  }: {
    selectedSprintOption: StringOptionType | null;
    sprints: StringOptionType[];
    selectedBoard: StringOptionType | null;
    setSelectedBoard: (option: StringOptionType | null) => void;
  }) => React.ReactElement;
}

export function SprintProvider({ selectedSprintId, children }: SprintProviderProps) {
  const [selectedBoard, setSelectedBoard] = useState<StringOptionType | null>(null);
  const [sprints, setSprints] = useState<StringOptionType[]>([]);

  useEffect(() => {
    void (async () => {
      if (selectedBoard) {
        try {
          const loadedSprints = await loadSprints(selectedBoard.value);
          setSprints(loadedSprints);
        } catch (err) {
          console.error("Failed to load sprints", err);
          setSprints([]);
        }
      }
    })();
  }, [selectedBoard]);

  useEffect(() => {
    void (async () => {
      if (selectedSprintId) {
        const sprint = await getJiraSprintById(selectedSprintId);
        const board = await getBoardById(sprint.originBoardId);
        setSelectedBoard({ label: board.name, value: board.id });
      }
    })();
  }, [selectedSprintId]);

  const selectedSprintOption = sprints.find((sprint) => sprint.value === selectedSprintId) ?? null;

  return <>{children({ selectedSprintOption, sprints, selectedBoard, setSelectedBoard })}</>;
}

async function loadSprints(boardId: string) {
  return getJiraSprints(boardId).then((data) => data.values.map((s) => ({ label: s.name, value: s.id.toString() })));
}
