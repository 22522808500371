import { useCallback } from "react";

import { getFormStateFromGame } from "../../../components/game/EditGameModal/hooks/useFormGameConfig";
import { Game, StartGameAnalyticsProps } from "../../../types";
import { StartGameClicked } from "../../ampli";
import { useGetGameConfigAmplitudeProperties } from "../useGetGameConfigAmplitueProperties";
import { useRecordEvent } from "./useRecordEvent";

export function useStartGameEvent() {
  const recordEvent = useRecordEvent(StartGameClicked);
  const getGameConfigAmplitudeProperties = useGetGameConfigAmplitudeProperties();

  return useCallback(
    (game: Game, analyticsProps: StartGameAnalyticsProps) => {
      const gameFormState = getFormStateFromGame(game.configuration, game.participants);
      const gameConfigProperties = getGameConfigAmplitudeProperties(gameFormState);

      recordEvent({ ...gameConfigProperties, ...analyticsProps });
    },
    [getGameConfigAmplitudeProperties, recordEvent],
  );
}
