import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Button from "@atlaskit/button";
import { Checkbox } from "@atlaskit/checkbox";
import { Label } from "@atlaskit/form";
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "@atlaskit/modal-dialog";
import Textfield from "@atlaskit/textfield";

import { useGameClonedEvent } from "../../../analytics/hooks/events/useGameClonedEvent";
import { useGameRemovedEvent } from "../../../analytics/hooks/events/useGameRemovedEvent";
import { useFinishGame } from "../../../hooks/useFinishGame";
import { useApplicationContext } from "../../../providers/ApplicationContextProvider";
import { useGameClone, useGameDelete, useGetGameById } from "../../../services/firebase";
import { showFlag } from "../../../services/jira-api";
import { Game, GameState } from "../../../types";

const CLONE_BACKLOG = "clone_backlog";
const ModalBodyContent = styled.div`
  margin-bottom: 16px;
`;
const CheckBoxWrapper = styled.div`
  padding-top: 4px;
`;

interface ModalProps {
  isWarning?: boolean;
  width?: "small" | "medium" | "large" | "x-large";
  title?: string;
  onConfirm?: () => void | Promise<void> | undefined;
  onClose: () => void;
  children?: React.ReactNode;
}

export function AbstractModal({ isWarning = false, width, title, onClose, onConfirm, children }: ModalProps) {
  const action = useCallback(async () => {
    await onConfirm?.();
  }, [onConfirm]);

  return (
    <Modal onClose={onClose} width={width}>
      <ModalHeader>
        <ModalTitle appearance={isWarning ? "warning" : undefined}>{title}</ModalTitle>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Button appearance="subtle" onClick={onClose}>
          Cancel
        </Button>
        <Button
          appearance={isWarning ? "warning" : "primary"}
          onClick={() => void action()}
          testId="confirmation-button"
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export function CloneGameModal() {
  const navigate = useNavigate();
  const { userAccountId } = useApplicationContext();
  const cloneGame = useGameClone();
  const getGameById = useGetGameById();
  const [game, setGame] = useState<Game>();

  const [copyBacklog, setCopyBacklog] = useState(localStorage.getItem(CLONE_BACKLOG));
  const [clonedGameName, setClonedGameName] = useState<string>("");
  const { id } = useParams() as { id: string };
  const recordGameClonedEvent = useGameClonedEvent();

  const shouldCopyBacklog = useMemo(() => copyBacklog === "true", [copyBacklog]);

  useEffect(() => {
    void (async () => {
      if (id) {
        const game = await getGameById(id);
        setGame(game);
        setClonedGameName(`Clone of ${game.configuration.name}`);
      }
    })();
  }, [getGameById, id]);

  const updateClonedGame = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setClonedGameName(value);
  };

  const backlogSize = useMemo(() => {
    return Object.keys(game?.configuration.backlog || {}).length;
  }, [game?.configuration]);

  const onConfirm = async () => {
    try {
      if (game) {
        const gameDetails: Game = {
          creator: userAccountId,
          configuration: {
            ...game.configuration,
            backlog: shouldCopyBacklog && game.configuration.backlog ? game.configuration.backlog : [],
            name: clonedGameName,
            admins: { ...game.configuration.admins, [userAccountId]: true },
          },
          created: Date.now(),
          updated: Date.now(),
          state: GameState.ACTIVE,
          participants: game.participants,
          timer: {
            autoStartOnNextRound: false,
            stopTimestamp: -1,
            selectedDuration: 60_000,
            customDurationValue: 120_000,
          },
        };
        const { key: gameId } = await cloneGame(gameDetails);
        if (gameId) {
          recordGameClonedEvent(gameDetails, { game_id: gameId, cloned_game_id: id });
        }
        showFlag("Game cloned successfully", "", "success");

        navigate(`/game/${gameId}${shouldCopyBacklog ? "" : "/openAddIssue"}`, {
          replace: true,
        });
      }
    } catch (err) {
      showFlag("Failed to clone game", "", "error");
    }
  };

  const setCopyBacklogValue = useCallback(() => {
    const value = shouldCopyBacklog ? "false" : "true";
    localStorage.setItem(CLONE_BACKLOG, value);
    setCopyBacklog(value);
  }, [shouldCopyBacklog]);

  return (
    <AbstractModal title="Clone game" onClose={() => navigate("/")} onConfirm={onConfirm}>
      <ModalBodyContent>By confirming the game will be cloned with similar settings and game backlog.</ModalBodyContent>
      <Label htmlFor="game-name">Game name</Label>
      <Textfield name="game-name" id="game-name" value={clonedGameName} onChange={updateClonedGame} />
      <CheckBoxWrapper>
        <Checkbox
          name="include-backlog"
          isChecked={shouldCopyBacklog}
          label={`Include game backlog [${backlogSize} issues]`}
          onChange={setCopyBacklogValue}
        />
      </CheckBoxWrapper>
    </AbstractModal>
  );
}

export function FinishGameModal({ onListUpdated }: { onListUpdated: VoidFunction }) {
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const finishGame = useFinishGame(id);

  const onConfirm = async () => {
    try {
      await finishGame();
      onListUpdated();
      showFlag("Game finished", "", "success");
    } catch (err) {
      showFlag("Failed to finish game", "", "error");
    } finally {
      navigate("/");
    }
  };

  return (
    <AbstractModal title="Finish game" width="small" onClose={() => navigate("/")} onConfirm={onConfirm}>
      By confirming game will be finished. No further changes to the game will be possible without restoring it. All
      unfinished stories will remain in estimation backlog.
    </AbstractModal>
  );
}

export interface DeleteSingleGameModalProps {
  onListUpdated: (gameId: string) => void;
}

export function DeleteSingleGameModal({ onListUpdated }: DeleteSingleGameModalProps) {
  const navigate = useNavigate();
  const deleteGames = useGameDelete();
  const { id } = useParams() as { id: string };
  const recordGameRemovedEvent = useGameRemovedEvent();

  async function onGamesDelete() {
    try {
      await recordGameRemovedEvent(id);
      await deleteGames([id]);
      onListUpdated(id);
      showFlag("Game deleted successfully", "The selected game has been successfully deleted", "success");
    } catch (e) {
      console.error(e);
      showFlag("Failed to delete game", "An error occurred while trying to delete game", "error");
    } finally {
      navigate("/");
    }
  }

  return (
    <AbstractModal
      title={`Selected game will be permanently deleted`}
      width="small"
      onClose={() => navigate("/")}
      onConfirm={onGamesDelete}
      isWarning={true}
    >
      {`You're about to permanently delete the game, its progress, and all of its data.`}
    </AbstractModal>
  );
}

interface DeleteMultipleGamesModalProps {
  selected: string[];
  onListUpdated: VoidFunction;
}

export function DeleteMultipleGamesModal({ selected, onListUpdated }: DeleteMultipleGamesModalProps) {
  const navigate = useNavigate();
  const deleteGames = useGameDelete();
  const suffix = selected.length > 1 ? "games" : "game";

  if (!selected.length) {
    return <Navigate to={"../.."} />;
  }

  async function onGamesDelete() {
    try {
      await deleteGames(selected);
      onListUpdated();
      if (selected.length > 1) {
        showFlag("Games deleted successfully", "The selected games have been successfully deleted", "success");
      } else {
        showFlag("Game deleted successfully", "The selected game has been successfully deleted", "success");
      }
    } catch (e) {
      console.error(e);
      if (selected.length > 1) {
        showFlag("Failed to delete game(s)", "An error occurred while trying to delete selected games", "error");
      } else {
        showFlag("Failed to delete games", "An error occurred while trying to delete game", "error");
      }
    } finally {
      navigate("/");
    }
  }

  return (
    <AbstractModal
      title={`Selected ${suffix} will be permanently deleted`}
      width="small"
      onClose={() => navigate("/")}
      onConfirm={onGamesDelete}
      isWarning={true}
    >
      {selected.length > 1
        ? `You're about to permanently delete the selected games, their progress, and all of their data.`
        : `You're about to permanently delete the game, its progress, and all of its data.`}
    </AbstractModal>
  );
}
