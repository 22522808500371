import React from "react";

import { Box } from "@fuegokit/react";

import { GameParticipant, JiraUser } from "../../../types";
import { ParticipantDetails } from "./ParticipantDetails";

interface ParticipantsListProps {
  participants: GameParticipant[];
  usersDetails: Record<string, JiraUser>;
  isExpanded: boolean;
  isSpectatorsList?: boolean;
}

export function ParticipantsList({ participants, usersDetails, isExpanded }: Readonly<ParticipantsListProps>) {
  return (
    <Box paddingX="8px" paddingY="4px">
      {participants.map((participant) => (
        <ParticipantDetails
          key={participant.accountId}
          participant={participant}
          participantDetails={usersDetails[participant.accountId]}
          isExpanded={isExpanded}
        />
      ))}
    </Box>
  );
}
