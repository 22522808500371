import styled from "styled-components";

const Divider = styled.div`
  border-radius: 1px;
  background: var(--ds-border, rgba(9, 30, 67, 0.14));
  height: 2px;
  width: 100%;
`;

export { Divider };
