import { useState } from "react";

interface LayoutState {
  [panelKey: string]: boolean;
}

const LAYOUT_STATE_KEY = "planning-poker-layout-state";

const DEFAULT_LAYOUT_STATE: LayoutState = {
  details: true,
  description: true,
};

const getState = () => {
  const state = localStorage.getItem(LAYOUT_STATE_KEY);
  if (!state) {
    return DEFAULT_LAYOUT_STATE;
  }
  return JSON.parse(state) as LayoutState;
};

const setState = (state: LayoutState) => {
  localStorage.setItem(LAYOUT_STATE_KEY, JSON.stringify(state));
};

export function useLayoutState() {
  const [layoutState, setLayoutState] = useState(getState());

  const togglePanel = (panelKey: string) => {
    setLayoutState((prev) => {
      const isPanelOpen = prev[panelKey] !== undefined ? !prev[panelKey] : true;
      const update = { ...prev, [panelKey]: isPanelOpen };
      setState(update);
      return update;
    });
  };
  return { layoutState, togglePanel };
}
