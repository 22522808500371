import React, { useCallback, useEffect, useMemo, useState } from "react";

import Avatar from "@atlaskit/avatar";
import Select, { ActionMeta, components, MultiValueRemoveProps } from "@atlaskit/select";
import { Box } from "@fuegokit/react";

import { useUsersStore } from "../../providers/UserStoreContextProvider";
import { JiraUser } from "../../types";

type UserOptionProperties = Omit<UserOption, "value" | "initialData">;

const UserSelectOption = ({ label, avatar }: UserOptionProperties) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }} data-private>
      <div style={{ marginLeft: "2px" }}>{avatar}</div>
      <div style={{ marginLeft: "8px" }}>{label}</div>
    </div>
  );
};

interface UserOption {
  label: string;
  value: string;
  avatar: JSX.Element;
  initialData: JiraUser;
}

interface UserPickerProps {
  selectedUsers: JiraUser[];
  onSelectedUsersChange: (newUsers: JiraUser[], actionMeta?: ActionMeta) => void;
  isOptionClearable?: (option: UserOption) => boolean;
}

const createUserPickerOption = (user: JiraUser) => ({
  label: user.displayName,
  value: user.accountId,
  avatar: <Avatar src={user.avatarUrls["24x24"]} name={user.displayName} size="xsmall" appearance="circle" />,
  initialData: user,
});

const renderUserOption = (option: UserOption) => (
  <UserSelectOption label={option.label} avatar={option.avatar} key={option.value} />
);

const UserPicker = ({ selectedUsers, onSelectedUsersChange, isOptionClearable }: UserPickerProps) => {
  const [users, setUsers] = useState<JiraUser[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { searchResults, queryUserPickerData, isLoading } = useUsersStore();

  const getAndSetUsers = useCallback(
    (query: string) => {
      queryUserPickerData(query).then(setUsers).catch(console.error);
    },
    [queryUserPickerData],
  );

  useEffect(() => {
    if (!isLoading) {
      if (searchQuery === "") {
        setUsers(searchResults[""] ?? []);
      } else {
        getAndSetUsers(searchQuery);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, searchQuery]);

  const userOptions = useMemo(
    () => users.filter((user) => user.accountType === "atlassian").map(createUserPickerOption),
    [users],
  );
  const selectedUsersValues = useMemo(() => selectedUsers.map(createUserPickerOption), [selectedUsers]);

  const onChange = useCallback(
    (data: Readonly<UserOption[]>, actionMeta: ActionMeta) =>
      onSelectedUsersChange(
        data.map((u) => u.initialData),
        actionMeta,
      ),
    [onSelectedUsersChange],
  );

  const isClearable = useMemo(
    () => (isOptionClearable ? selectedUsersValues.some(isOptionClearable) : true),
    [isOptionClearable, selectedUsersValues],
  );

  const customComponents = useMemo(() => {
    if (!isOptionClearable) return undefined;
    function MultiValueRemove(props: Readonly<MultiValueRemoveProps<UserOption>>) {
      if (!isOptionClearable?.(props.data)) return <Box width="4px" />;
      return <components.MultiValueRemove {...props} />;
    }
    return { MultiValueRemove };
  }, [isOptionClearable]);

  return (
    <Select
      options={userOptions}
      maxMenuHeight={120}
      placeholder="Type to search..."
      value={selectedUsersValues}
      onChange={onChange}
      formatOptionLabel={renderUserOption}
      isMulti
      onInputChange={setSearchQuery}
      isLoading={isLoading}
      isClearable={isClearable}
      components={customComponents}
    />
  );
};

export default UserPicker;
