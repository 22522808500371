import { goOffline, goOnline } from "firebase/database";
import { useCallback } from "react";
import { useDatabase } from "reactfire";

const FIVE_MINUTES = 60 * 5 * 1000;
export const useOnIdleTabGoOffline = (ms = FIVE_MINUTES) => {
  const db = useDatabase();
  return useCallback(() => {
    let timer: ReturnType<typeof setTimeout>;
    const listener = () => {
      if (document.visibilityState === "hidden") {
        timer = setTimeout(() => {
          goOffline(db);
        }, ms);
      } else {
        clearTimeout(timer);
        goOnline(db);
      }
    };
    document.addEventListener("visibilitychange", listener);
    return () => {
      document.removeEventListener("visibilitychange", listener);
    };
  }, [db, ms]);
};
