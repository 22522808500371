import { JIRA_FIELD_SCHEMA_ITEMS, JIRA_FIELD_TYPE, JiraField } from "../types";
import { CUSTOM_FIELD } from "./backlog";
import { isStringTypeSupported, SPRINT_FIELD_SCHEMA } from "./index";

export const isParagraphField = (field: JiraField) => field.schema?.custom === CUSTOM_FIELD.PARAGRAPH;

export const isFieldIdSupported = (field: JiraField) => field.id === "parent";

const SUPPORTED_COLUMN_TYPES: JIRA_FIELD_TYPE[] = [
  JIRA_FIELD_TYPE.STRING,
  JIRA_FIELD_TYPE.NUMBER,
  JIRA_FIELD_TYPE.OPTION,
  JIRA_FIELD_TYPE.USER,
  JIRA_FIELD_TYPE.ARRAY,
  JIRA_FIELD_TYPE.DATE,
  JIRA_FIELD_TYPE.DATETIME,
  JIRA_FIELD_TYPE.TIME_TRACKING,
];

const isTypeSupported = (field: JiraField, excludedColumns: string[]) =>
  field.schema?.type && SUPPORTED_COLUMN_TYPES.includes(field.schema.type) && !excludedColumns.includes(field.id);

const SUPPORTED_ARRAY_TYPES: JIRA_FIELD_SCHEMA_ITEMS[] = [
  JIRA_FIELD_SCHEMA_ITEMS.STRING,
  JIRA_FIELD_SCHEMA_ITEMS.VERSION,
  JIRA_FIELD_SCHEMA_ITEMS.JSON,
  JIRA_FIELD_SCHEMA_ITEMS.COMPONENT,
];

const isArrayTypeSupported = (field: JiraField) =>
  !(
    field.schema?.type === JIRA_FIELD_TYPE.ARRAY &&
    (!field.schema?.items || !SUPPORTED_ARRAY_TYPES.includes(field.schema.items))
  );

const isArrayJsonSchemaSupported = (field: JiraField) =>
  !(
    field.schema?.type === JIRA_FIELD_TYPE.ARRAY &&
    field.schema.items === JIRA_FIELD_SCHEMA_ITEMS.JSON &&
    field.schema.custom !== SPRINT_FIELD_SCHEMA
  );

export const isFieldTypeSupported = (field: JiraField, excludedColumns: string[]) =>
  isTypeSupported(field, excludedColumns) &&
  isArrayTypeSupported(field) &&
  isStringTypeSupported(field) &&
  isArrayJsonSchemaSupported(field);
