import React, { useCallback, useState } from "react";

import { IconButton } from "@atlaskit/button/new";
import VidVolumeFullIcon from "@atlaskit/icon/glyph/vid-volume-full";
import VidVolumeMutedIcon from "@atlaskit/icon/glyph/vid-volume-muted";

const IS_MUTED_LS_KEY = "isTimerMuted";

interface ButtonMute {
  audio: HTMLAudioElement;
}

export function ButtonMute({ audio }: ButtonMute) {
  const [muted, setMuted] = useState(localStorage.getItem(IS_MUTED_LS_KEY));
  const isMuted = muted === "true";
  audio.muted = isMuted;

  const mute = useCallback(() => {
    localStorage.setItem(IS_MUTED_LS_KEY, "true");
    setMuted("true");
  }, []);
  const unmute = useCallback(() => {
    localStorage.setItem(IS_MUTED_LS_KEY, "false");
    setMuted("false");
  }, []);

  return (
    <IconButton
      icon={isMuted ? VidVolumeMutedIcon : VidVolumeFullIcon}
      label={isMuted ? "Unmute" : "Mute"}
      onClick={isMuted ? unmute : mute}
    />
  );
}
