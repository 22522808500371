import { useCallback } from "react";

import { useApplicationContext } from "../../providers/ApplicationContextProvider";
import { JSONObject, JSONValue } from "../../types";
import { JiraApiError } from "../jira-api";

export function useGetUserProperty() {
  const { userAccountId } = useApplicationContext();

  const getUserProperties = useCallback(
    async <TValue extends JSONValue = JSONValue>(propertyKey: string) => {
      try {
        const request = await AP.request({
          url: `/rest/api/3/user/properties/${propertyKey}?accountId=${encodeURIComponent(userAccountId)}`,
          type: "GET",
          contentType: "application/json",
        });

        const payload = JSON.parse(request.body) as JSONObject<TValue>;

        return payload?.value;
      } catch (err) {
        throw new JiraApiError(err, [propertyKey]);
      }
    },
    [userAccountId],
  );

  return getUserProperties;
}

export function useSetUserProperty() {
  const { userAccountId } = useApplicationContext();

  const setUserProperty = useCallback(
    async <TValue extends JSONValue = JSONValue>(propertyKey: string, propertyValue: TValue) => {
      try {
        await AP.request({
          url: `/rest/api/3/user/properties/${propertyKey}?accountId=${encodeURIComponent(userAccountId)}`,
          type: "PUT",
          contentType: "application/json",
          data: JSON.stringify(propertyValue),
        });
      } catch (err) {
        throw new JiraApiError(err, Object.keys(propertyValue));
      }
    },
    [userAccountId],
  );

  return setUserProperty;
}

// Might not be used, but useful for testing
export function useDeleteUserProperty() {
  const { userAccountId } = useApplicationContext();

  const deleteUserProperty = useCallback(
    async (propertyKey: string) => {
      try {
        await AP.request({
          url: `/rest/api/3/user/properties/${propertyKey}?accountId=${encodeURIComponent(userAccountId)}`,
          type: "DELETE",
          contentType: "application/json",
        });
      } catch (err) {
        throw new JiraApiError(err, [propertyKey]);
      }
    },
    [userAccountId],
  );

  return deleteUserProperty;
}
