import React, { createContext, useContext, useEffect, useMemo, useState } from "react";

import { ErrorMessage } from "../components/shared/ErrorMessage";
import { LoadingPage } from "../components/shared/LoadingPage";
import { AccessDeniedMessage } from "../components/shared/messages/AccessDeniedMessage";
import { UnlicensedMessage } from "../components/shared/messages/UnlicensedMessage";
import { getApplicationContext } from "../services/api";
import {
  getAddonInfo,
  getJiraContext,
  getJiraLocation,
  getJiraStatuses,
  getProject,
  getUserLocale,
} from "../services/jira-api";
import { AddonInfo, ApplicationContext, JiraContext, JiraProject, JiraStatus } from "../types";
import { getErrorStatus } from "../utils";

interface Context extends ApplicationContext, JiraContext, AddonInfo {
  project?: JiraProject;
  statuses: JiraStatus[];
  userLocale: string;
  location: string;
}

const ApplicationContext = createContext<Context | null>(null);

interface IssueDataProviderProps {
  children?: React.ReactNode;
}

export const ApplicationContextProvider = ({ children }: IssueDataProviderProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [appContext, setAppContext] = useState<ApplicationContext>();
  const [jiraContext, setJiraContext] = useState<JiraContext>();
  const [project, setProject] = useState<JiraProject | undefined>();
  const [statuses, setStatuses] = useState<JiraStatus[]>([]);
  const [userLocale, setUserLocale] = useState("en-US");
  const [error, setError] = useState<unknown>();
  const [location, setLocation] = useState<string>();
  const [licenseContext, setLicenseContext] = useState<AddonInfo | undefined>(undefined);
  useEffect(() => {
    void (async () => {
      try {
        const [appContext, jiraContext, userLocale, jiraLocation] = await Promise.all([
          getApplicationContext(),
          getJiraContext(),
          getUserLocale(),
          getJiraLocation(),
        ]);
        const projectKey = jiraContext.jira?.project?.key;
        const [project, statuses, addonInfo] = await Promise.all([
          projectKey ? getProject(projectKey) : Promise.resolve(undefined),
          getJiraStatuses(),
          getAddonInfo(appContext.addonKey),
        ]);
        setAppContext(appContext);
        setJiraContext(jiraContext);
        setProject(project);
        setStatuses(statuses);
        setUserLocale(userLocale.replace(/_/g, "-"));
        setLocation(jiraLocation);
        setLicenseContext(addonInfo);
      } catch (e) {
        setError(e);
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const context = useMemo(
    () => ({
      ...jiraContext,
      ...appContext,
      ...licenseContext,
      project,
      statuses,
      userLocale,
      location,
    }),
    [appContext, jiraContext, licenseContext, location, project, statuses, userLocale],
  );

  if (error) {
    if (getErrorStatus(error) === 403) {
      return <AccessDeniedMessage />;
    }
    if (getErrorStatus(error) === 402) {
      return <UnlicensedMessage />;
    }
    throw error;
  }

  if (isLoading) {
    return <LoadingPage />;
  }
  if (!appContext || !jiraContext) {
    return (
      <ErrorMessage
        title="Error retrieving application context"
        text="Unexpected error happened while retrieving data from api."
      />
    );
  }
  return <ApplicationContext.Provider value={context as Context}>{children}</ApplicationContext.Provider>;
};

export function useApplicationContext() {
  const context = useContext(ApplicationContext);
  if (!context) {
    throw new Error("Issue context not initialized, probably context provider is missing");
  }
  return context;
}
