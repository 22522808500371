import React from "react";

import EmptyState from "@atlaskit/empty-state";

import { EmptyFiltersImage } from "./EmptyFiltersImage";
import { EmptySearchResultImage } from "./EmptySearchResultImage";

export function EmptyResult({ jql, count, loading }: { jql?: string; count: number; loading: boolean }) {
  if (!jql) {
    return (
      <EmptyState
        header="No filters selected"
        description="Choose filters to narrow down issues that can be added to game backlog"
        headingLevel={2}
        renderImage={() => <EmptyFiltersImage />}
      />
    );
  } else if (count === 0 && !loading) {
    return (
      <EmptyState
        header="No issues found"
        description={"No issues match applied filters. Change filter selection to show more issues"}
        headingLevel={2}
        renderImage={() => <EmptySearchResultImage />}
      />
    );
  } else {
    return null;
  }
}
