import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "@atlaskit/button";
import AddIcon from "@atlaskit/icon/glyph/add";
import { Box } from "@fuegokit/react";

import { NewGameClicked } from "../../analytics/ampli";
import { useRecordEvent } from "../../analytics/hooks/events/useRecordEvent";

const WelcomeScreenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const AbsoluteBackground = styled.div`
  position: absolute;
  inset: 0;
  z-index: -1;
  background: url(/images/welcome_screen_background.svg);
  background-repeat: no-repeat;
  background-size: contain;
`;

const Card = styled.div`
  border-radius: 16px;
  width: 580px;
  box-shadow: var(--ds-shadow-raised, 0 1px 1px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31));
  background-color: var(--ds-surface-raised, #ffffff);
  padding: 40px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  color: var(--ds-text, #000000);

  img {
    width: 124px;
    height: 124px;
  }
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-top: 12px;

  h1 {
    font-size: 28px;
    margin-bottom: 8px;
    line-height: 40px;
  }

  p {
    font-size: 14px;
    line-height: 24px;
  }
`;

export function WelcomeScreen() {
  const navigate = useNavigate();
  const recordNewGameClickedEvent = useRecordEvent(NewGameClicked);

  function onCreateGame() {
    recordNewGameClickedEvent({});
    navigate("/create-game");
  }

  return (
    <WelcomeScreenContainer>
      <AbsoluteBackground />
      <Card>
        <Box display="flex">
          <img src="/icon/planning-poker-logo.svg" alt="Planning Poker logo" />
          <Message>
            <h1>Welcome to Planning Poker</h1>
            <p>
              It seems you don&apos;t have any games yet. <br />
              Why don&apos;t you create one?
            </p>
          </Message>
        </Box>
        <Button
          appearance="primary"
          iconBefore={<AddIcon label="Create new game" size="small" />}
          onClick={onCreateGame}
          testId="create-new-game"
        >
          New game
        </Button>
      </Card>
    </WelcomeScreenContainer>
  );
}
