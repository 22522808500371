import { ItemState, Votes } from "@appfire/poker-core";

import { addIssueComment, debouncedShowFlag, EstimationValue, updateIssueField } from "../services/jira-api";
import { Game, GameConfig, GameFormConfig, Voting } from "../types";
import { CardType } from "../types/common";
import { TIME_FIELDS } from ".";

export function buildShareGameUrl(gameId: string, addonKey: string, displayUrl: string) {
  const gameUrl = new URL(`${displayUrl}/plugins/servlet/ac/${addonKey}/agile-estimation`);
  gameUrl.hash = `!/game/${gameId}`;
  return gameUrl.toString();
}

export function isGameAdmin(userId: string, admins?: Record<string, boolean>) {
  return Object.keys(admins || {}).includes(userId);
}

export const isGameFormConfigValid = (gameFormConfig: GameFormConfig) => {
  if (!gameFormConfig.name.length || !gameFormConfig.cards.length) return false;
  if (gameFormConfig.enableEstimationContext && gameFormConfig.limitEstimationContext) {
    return Boolean(gameFormConfig.estimationContextCustomQuery?.length);
  }

  return true;
};

export const debouncedShowFlagGameDisconnected = () =>
  debouncedShowFlag(
    "You've been disconnected",
    "Either Planning Poker game was deleted, or you were removed from the participants list by the game admin",
    "info",
  );

export function preparePokerCoreVoting(voting?: Record<string, Voting>) {
  return voting
    ? Object.entries(voting).reduce<Votes>((votes, [issueId, currentVoting]) => {
        votes[issueId] = {
          ...currentVoting,
          state: currentVoting?.state || ItemState.PERSONAL_ESTIMATION,
        };
        return votes;
      }, {})
    : undefined;
}

export function saveEstimation(issueKey: string, estimationFieldId: string, value: EstimationValue) {
  switch (estimationFieldId) {
    case "comments": {
      const body = "This issue was estimated with value: " + String(value);
      return addIssueComment(issueKey, body);
    }
    case "timeoriginalestimate":
    case "timeestimate":
      return updateIssueField(issueKey, { timetracking: { [TIME_FIELDS[estimationFieldId]]: value } });
    default:
      return updateIssueField(issueKey, { [estimationFieldId]: value });
  }
}

export function prepareCards(gameConfig: GameConfig): CardType[] {
  const isCoffeeEnabled = gameConfig.coffeeCardsEnabled;
  const additionalCards = isCoffeeEnabled ? ["coffee", "?"] : [];
  const cardDeck = [...additionalCards, ...gameConfig.cards];
  return cardDeck.map((item) => {
    const pair = item.split("=");
    const label = pair[0].trim();
    const value = pair[1] ? pair[1].trim() : label;

    return { label: label, value: value };
  });
}

export function getCardValues(cardDeck: string[]): string[] {
  return cardDeck.map((item) => {
    const pair = item.split("=");
    const label = pair[0].trim();
    const value = pair[1] ? pair[1].trim() : label;

    return value;
  });
}

export function didUserVote(activeItemId: string | undefined, voting: Game["voting"], userAccountId: string) {
  return activeItemId && voting?.[activeItemId]?.votes?.[userAccountId] !== undefined;
}
