import React from "react";

import { Box } from "@fuegokit/react";

import { ChangelogEntry } from "./ChangelogEntry";
import { ChangelogItem } from "./index";

const date = new Date("2022-08-02");

function July2022() {
  return (
    <ChangelogEntry title="July 2022 update" date={date}>
      <p>Planning Poker has received the following improvements and features: </p>
      <ul>
        <li>
          The <strong>game layout</strong> has been reworked to minimize scrolling and optimize information
          representation. The changes include a new <strong>Participants panel</strong>, reworked{" "}
          <strong>Game controls</strong>, and optimized horizontal space
        </li>
        <li>
          The <strong>voting panel</strong> has been updated bringing multiple improvements. The ability to{" "}
          <strong>provide estimates after revealing cards</strong> has been added;
        </li>
        <li>
          The <strong>backlog filtering</strong> experience has been reworked following Jira&apos;s Advanced issue
          search approach;
        </li>
        <li>The game creation flow has been improved with the new participants invitation step.</li>
      </ul>
      <Box p={4}>
        <img src="/images/whats-new-july.png" alt="whats-new-july" width="100%" />
      </Box>
    </ChangelogEntry>
  );
}

export default {
  Component: July2022,
  date,
} as ChangelogItem;
