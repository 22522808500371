import React, { forwardRef, PropsWithChildren, useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Breadcrumbs, { BreadcrumbsItem } from "@atlaskit/breadcrumbs";
import CopyIcon from "@atlaskit/icon/glyph/copy";
import { token } from "@atlaskit/tokens";
import Tooltip from "@atlaskit/tooltip";

import { useGameUrl } from "../../../dashboard/cells/useGameUrl";
import { useGameData } from "../../GameProvider";

const LeftSideContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  /* Fixes breadcrumbs separator */
  nav > ol > li::after {
    width: auto !important;
    min-width: 8px !important;
  }
`;

const LinkWithFlex = styled(Link)`
  display: flex;
  gap: 4px;
`;

const CopyGameUrlButton = styled.button`
  all: unset;

  cursor: pointer;
`;

const NAVIGATE_STATE = { from: "refresh" };

export function LeftSide() {
  const { game, gameId } = useGameData();
  const { copyGameUrl } = useGameUrl(gameId);

  const onLinkClick = useCallback(() => {
    history.pushState(NAVIGATE_STATE, "");
    window.location.reload();
  }, []);

  return (
    <LeftSideContainer>
      <Breadcrumbs>
        <BreadcrumbsItem
          component={forwardRef<HTMLAnchorElement, PropsWithChildren<unknown>>(function LinkWithRef(props, ref) {
            return <LinkWithFlex ref={ref} to="/" {...props} data-testid="dashboard-link" />;
          })}
          iconBefore={<img src="/icon/planning-poker-logo.png" width="24px" />}
          text="Planning Poker"
        />
        <BreadcrumbsItem
          component={forwardRef<HTMLAnchorElement, PropsWithChildren<unknown>>(function LinkWithRef(props, ref) {
            return (
              <LinkWithFlex
                ref={ref}
                to={`/game/${gameId}`}
                {...props}
                data-testid={`game-name-${game.configuration.name}`}
              />
            );
          })}
          text={game.configuration.name}
          onClick={onLinkClick}
          data-private
        />
      </Breadcrumbs>
      <Tooltip content="Copy game URL">
        <CopyGameUrlButton onClick={copyGameUrl}>
          <CopyIcon primaryColor={token("color.icon.subtle")} label="Copy game URL" />
        </CopyGameUrlButton>
      </Tooltip>
    </LeftSideContainer>
  );
}
