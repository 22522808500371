import React, { useMemo } from "react";

import Tooltip from "@atlaskit/tooltip";

import { useApplicationContext } from "../../../providers/ApplicationContextProvider";
import { getDateTimeStringFromTimestamp, getFormattedDate } from "../../../utils";

interface DateCellProps {
  timestamp: number;
}

export function DateCell({ timestamp }: DateCellProps) {
  const { userLocale } = useApplicationContext();
  const tooltipContent = useMemo(() => getDateTimeStringFromTimestamp(timestamp, userLocale), [timestamp, userLocale]);
  const content = useMemo(() => getFormattedDate(timestamp), [timestamp]);
  return (
    <Tooltip content={tooltipContent}>
      <div data-private>{content}</div>
    </Tooltip>
  );
}
