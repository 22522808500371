import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Game, GameFormConfig } from "../../../../types";
import { isGameFormConfigValid } from "../../../../utils/game";

const removeParticipantsInstances = (gameParticipants: Game["participants"]) =>
  Object.entries(gameParticipants).map(([key, value]) => [
    key,
    { accountId: value.accountId, isSpectator: value.isSpectator },
  ]);

export const getFormStateFromGame = (gameConfig: Game["configuration"], participants: Game["participants"]) => ({
  ...gameConfig,
  backlogColumnIds: gameConfig.backlogColumnIds || [],
  layoutFields: gameConfig.layoutFields || [],
  estimatedLabel: gameConfig.estimatedLabel || null,
  estimatedSprintId: gameConfig.estimatedSprintId || null,
  estimatedTransition: gameConfig.estimatedTransition || null,
  skippedLabel: gameConfig.skippedLabel || null,
  participants,
});

export const useFormGameConfig = (game: Game) => {
  const initialGameFormConfig = useMemo((): GameFormConfig => {
    return getFormStateFromGame(game.configuration, game.participants);
  }, [game.configuration, game.participants]);
  const [formState, setFormState] = useState<GameFormConfig>(initialGameFormConfig);
  // Participants can join when we are in configuration, it should update then to include them
  useEffect(() => {
    setFormState((prev) => ({ ...prev, participants: { ...game.participants, ...prev.participants } }));
  }, [game.participants]);

  // Participants instances change and their exact values aren't relevant for this comparison
  const isDirty = useMemo(() => {
    return !isEqual(
      { ...initialGameFormConfig, participants: removeParticipantsInstances(initialGameFormConfig.participants) },
      { ...formState, participants: removeParticipantsInstances(formState.participants) },
    );
  }, [initialGameFormConfig, formState]);
  const isValid = useMemo(() => isGameFormConfigValid(formState), [formState]);

  const onChange = useCallback((toUpdate: Partial<GameFormConfig>) => {
    setFormState((prev) => ({ ...prev, ...toUpdate }));
  }, []);

  return {
    formState,
    isDirty,
    isValid,
    onChange,
  };
};
