import React from "react";
import styled from "styled-components";

import { InfoTooltip } from "./InfoTooltip";

const FormLabelContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

interface FormLabelProps {
  text: string;
  tooltip?: string;
}

export function FormLabel({ text, tooltip }: Readonly<FormLabelProps>) {
  return (
    <FormLabelContainer>
      {text}
      {tooltip && <InfoTooltip message={tooltip} />}
    </FormLabelContainer>
  );
}
