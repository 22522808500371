import React, { useEffect, useState } from "react";
import { Router, To } from "react-router-dom";

function toPathname(to: To) {
  return typeof to === "string" ? to : to.pathname || "/";
}

function getAtlassianNavigator(urlBasename: string) {
  return {
    createHref: (to: To) => {
      const href = new URL(urlBasename);
      href.hash = `!${toPathname(to)}`;
      return href.toString();
    },
    go: AP.history.go,
    push: (to: To) => AP.history.pushState(toPathname(to)),
    replace: (to: To) => AP.history.replaceState(toPathname(to)),
  };
}

function replaceHash(hash: string | null) {
  window.location.hash = hash || "/";
}

export function ConnectRouter({ children }: { children: React.ReactNode }) {
  const [location, setLocation] = useState("/");
  const [urlBasename, setUrlBasename] = useState("/");

  useEffect(() => {
    AP.history.getState("all", function ({ hash, href }) {
      setUrlBasename(href);
      setLocation(hash || "/");
      replaceHash(hash);
    });
    AP.history.popState((event) => {
      setLocation(event.newURL);
      replaceHash(event.hash);
    });
  }, []);
  return (
    AP && (
      <Router navigator={getAtlassianNavigator(urlBasename)} location={location}>
        {children}
      </Router>
    )
  );
}
