import React, { useMemo } from "react";

import Lozenge, { ThemeAppearance } from "@atlaskit/lozenge";

interface VotingCounterProps {
  votedParticipantsCount: number;
  votingParticipantsCount: number;
}

export function VotingCounter({ votedParticipantsCount, votingParticipantsCount }: Readonly<VotingCounterProps>) {
  const appearance = useMemo<ThemeAppearance>(() => {
    if (!votedParticipantsCount) {
      return "default";
    }
    if (votedParticipantsCount < votingParticipantsCount) {
      return "inprogress";
    }
    return "success";
  }, [votedParticipantsCount, votingParticipantsCount]);

  return (
    <Lozenge isBold appearance={appearance}>
      {votedParticipantsCount}/{votingParticipantsCount} VOTED
    </Lozenge>
  );
}
