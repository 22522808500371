import React, { ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import styled from "styled-components";

import { AkEditorCloseIcon } from "@fuegokit/fuegoicons-react";
import { AtlasButton, Box, Heading } from "@fuegokit/react";

import "./full-page-dialog.css";

import { FULL_PAGE_DIALOG_CONTROLS_WIDTH, FULL_PAGE_DIALOG_MAX_WIDTH } from "../../../utils";
import { Divider } from "../Divider";

const CONTENT_SWITCH_TRANSITION_TIMEOUT = 300;
const APPEAR_TRANSITION_TIMEOUT = 200;

const FullPageDialogContainer = styled.div`
  &.full-page-dialog-enter {
    opacity: 0;
  }

  &.full-page-dialog-enter-active {
    opacity: 1;
    transition: opacity ${APPEAR_TRANSITION_TIMEOUT}ms cubic-bezier(0.2, 0, 0, 1);
  }

  &.full-page-dialog-exit {
    opacity: 1;
  }

  &.full-page-dialog-exit-active {
    opacity: 0;
    transition: opacity ${APPEAR_TRANSITION_TIMEOUT}ms cubic-bezier(0.2, 0, 0, 1);
  }
`;

interface FullPageDialogProps {
  isOpen: boolean;
  headerTitle: string;
  content: ReactNode;
  controls: ReactNode;
  onClose: () => void;
  maxWidth?: string;
  transitionKey?: string;
}

const ContentContainer = styled.div<{ $maxWidth: string }>`
  min-width: ${FULL_PAGE_DIALOG_CONTROLS_WIDTH};
  max-width: ${(props) => props.$maxWidth};

  &.full-page-dialog-content-enter {
    opacity: 0;
    transform: translateX(50px);
  }

  &.full-page-dialog-content-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition:
      opacity ${CONTENT_SWITCH_TRANSITION_TIMEOUT}ms,
      transform ${CONTENT_SWITCH_TRANSITION_TIMEOUT}ms;
  }

  &.full-page-dialog-content-exit {
    opacity: 1;
    transform: translateX(0);
  }

  &.full-page-dialog-content-exit-active {
    opacity: 0;
    transform: translateX(-50px);
  }

  &.full-page-dialog-content-enter-active,
  &.full-page-dialog-content-exit-active {
    transition:
      opacity ${CONTENT_SWITCH_TRANSITION_TIMEOUT}ms,
      transform ${CONTENT_SWITCH_TRANSITION_TIMEOUT}ms;
  }
`;

export function FullPageDialog({
  isOpen,
  headerTitle,
  content,
  controls,
  onClose,
  maxWidth,
  transitionKey,
}: Readonly<FullPageDialogProps>) {
  const transitionNodeRef = useRef(null);

  const closeIconCb = () => <AkEditorCloseIcon />;

  // Hide all content under the dialog and remove overflow from it
  useEffect(() => {
    if (!isOpen) return;

    /* This is the solution for POK-855, when client uses microsoft defender for cloud apps, 
    automatic resize to parent doesn't work and had to call it manually when using full page modal */
    window.AP.sizeToParent(false);

    const rootElement = document.getElementById("root");
    if (!rootElement) {
      throw new Error("Root element must always be available");
    }

    const prevValueOverflow = document.body.style.overflow;
    const prevValueZIndex = rootElement.style.zIndex;

    document.body.style.overflow = "hidden";
    rootElement.style.zIndex = "-1";

    return () => {
      document.body.style.overflow = prevValueOverflow;
      rootElement.style.zIndex = prevValueZIndex;
    };
  });

  return createPortal(
    <CSSTransition
      in={isOpen}
      nodeRef={transitionNodeRef}
      timeout={APPEAR_TRANSITION_TIMEOUT}
      classNames="full-page-dialog"
      unmountOnExit
      appear
    >
      <FullPageDialogContainer ref={transitionNodeRef} className="full-page-dialog-container">
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={transitionKey ?? "1"}
            timeout={CONTENT_SWITCH_TRANSITION_TIMEOUT}
            classNames="full-page-dialog-content"
            unmountOnExit
          >
            <ContentContainer className="content-container" $maxWidth={maxWidth ?? FULL_PAGE_DIALOG_MAX_WIDTH}>
              <div className="header-container">
                <Heading variant="h700">{headerTitle}</Heading>
                <AtlasButton appearance="subtle" leadingIcon={closeIconCb} onPress={onClose} />
              </div>
              <Box display="flex" overflow="hidden" height="100%">
                {content}
              </Box>
            </ContentContainer>
          </CSSTransition>
        </SwitchTransition>
        <div className="controls-container">
          <Divider />
          {controls}
        </div>
      </FullPageDialogContainer>
    </CSSTransition>,
    document.body,
  );
}
