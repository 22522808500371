import { useCallback, useRef } from "react";

import { useRefetchIssuesById } from "../../../../hooks/useRefetchIssuesById";
import { showFlag } from "../../../../services/jira-api";
import { Game } from "../../../../types";

const findSavedEstimateChange = (prevVoting: Game["voting"], newVoting: Game["voting"]) =>
  Object.entries(newVoting ?? {}).find(
    ([issueKey, voting]) => voting?.savedEstimate !== prevVoting?.[issueKey]?.savedEstimate,
  );

export const useSavedEstimationFlag = (voting?: Game["voting"]) => {
  const prevVoting = useRef<Game["voting"]>(voting);
  const { refetchIssue } = useRefetchIssuesById();

  const checkVotingSavedEstimateChange = useCallback(() => {
    if (!voting) return;
    const updatedVoting = findSavedEstimateChange(prevVoting.current, voting);
    if (updatedVoting) {
      const [issueKey, voting] = updatedVoting;
      void refetchIssue(issueKey);
      showFlag(
        "Final estimation saved",
        `Admin saved ${voting.savedEstimate} as final estimate of ${issueKey}`,
        "info",
        true,
      );
    }
    prevVoting.current = voting;
  }, [refetchIssue, voting]);

  return checkVotingSavedEstimateChange;
};
