import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@atlaskit/button";
import { AkEditorAddIcon } from "@fuegokit/fuegoicons-react";
import { Box } from "@fuegokit/react";

import { NewGameClicked } from "../../analytics/ampli";
import { useDashboardOpenedEvent } from "../../analytics/hooks/events/useDashboardOpenedEvent";
import { useRecordEvent } from "../../analytics/hooks/events/useRecordEvent";
import { GamesList } from "./GamesList";

export const Dashboard = () => {
  const navigate = useNavigate();
  useDashboardOpenedEvent();
  const recordNewGameClickedEvent = useRecordEvent(NewGameClicked);

  const onClick = useCallback(() => {
    recordNewGameClickedEvent({});
    navigate("create-game");
  }, [navigate, recordNewGameClickedEvent]);

  return (
    <Box display="flex" flexDirection="column" sx={{ gap: "16px" }}>
      <Box width="100%">
        <Button appearance="primary" iconBefore={<AkEditorAddIcon />} onClick={onClick} testId="create-new-game">
          Create game
        </Button>
      </Box>
      <GamesList />
    </Box>
  );
};
