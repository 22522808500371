import { useMemo } from "react";

import { useApplicationContext } from "../../../../providers/ApplicationContextProvider";
import { useGameData } from "../../GameProvider";

export function useIsCurrentUserSpectator() {
  const { game } = useGameData();
  const { userAccountId } = useApplicationContext();

  return useMemo(() => game.participants[userAccountId]?.isSpectator, [game.participants, userAccountId]);
}
