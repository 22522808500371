import { useCallback } from "react";

import { getFormStateFromGame } from "../../../components/game/EditGameModal/hooks/useFormGameConfig";
import { Game } from "../../../types";
import { GameCloned, GameClonedProperties } from "../../ampli";
import { useGetGameConfigAmplitudeProperties } from "../useGetGameConfigAmplitueProperties";
import { useRecordEvent } from "./useRecordEvent";

type GameCloneEventProps = Pick<GameClonedProperties, "game_id" | "cloned_game_id">;

export function useGameClonedEvent() {
  const recordEvent = useRecordEvent(GameCloned);
  const getGameConfigAmplitudeProperties = useGetGameConfigAmplitudeProperties();

  return useCallback(
    (game: Game, additionalProperties: GameCloneEventProps) => {
      const gameFormState = getFormStateFromGame(game.configuration, game.participants);
      const properties = getGameConfigAmplitudeProperties(gameFormState);
      recordEvent({ ...properties, ...additionalProperties });
    },
    [getGameConfigAmplitudeProperties, recordEvent],
  );
}
