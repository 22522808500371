import React from "react";
import styled from "styled-components";

import { LeftSide } from "./components/LeftSide";
import { RightSide } from "./components/RightSide";

export const GAME_HEADER_HEIGHT = "40px";

const GameHeaderContainer = styled.div`
  position: sticky;
  top: 0;
  padding: 0 40px;
  border-block-end: 1px solid var(--ds-border, #ebecf0);
  height: ${GAME_HEADER_HEIGHT};
  background: var(--ds-surface, #fff);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  z-index: var(--z-index-layer-2);
`;

export function GameHeader() {
  return (
    <GameHeaderContainer>
      <LeftSide />
      <RightSide />
    </GameHeaderContainer>
  );
}
