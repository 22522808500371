import React, { MouseEvent } from "react";
import styled from "styled-components";

import Tooltip from "@atlaskit/tooltip";
import { AkInviteTeamIcon } from "@fuegokit/fuegoicons-react";
import { AtlasButton } from "@fuegokit/react";

import { ExpandTransition } from "./ExpandTransition";
import { useToggleRole } from "./hooks/useToggleRole";

interface ChangeRoleButtonProps {
  isUserSpectator: boolean;
  isExpanded: boolean;
}

const ButtonWrapper = styled.div`
  padding: 11px 8px 4px;

  > button {
    gap: 4px;
  }
`;

export function ChangeRoleButton({ isUserSpectator, isExpanded }: Readonly<ChangeRoleButtonProps>) {
  const buttonText = `Become ${isUserSpectator ? "estimator" : "spectator"}`;
  const toggleRole = useToggleRole();

  const onButtonClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    void toggleRole();
  };

  return (
    <Tooltip content={isExpanded ? "" : buttonText}>
      <ButtonWrapper>
        <AtlasButton onClick={onButtonClick} leadingIcon={AkInviteTeamIcon} appearance="subtle">
          <ExpandTransition $isExpanded={isExpanded}>{isExpanded ? buttonText : ""}</ExpandTransition>
        </AtlasButton>
      </ButtonWrapper>
    </Tooltip>
  );
}
