import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Field } from "@atlaskit/form";
import { ActionMeta } from "@atlaskit/select";

import { useUsersStore } from "../../providers/UserStoreContextProvider";
import { GameFormConfig, GameParticipant, JiraUser } from "../../types";
import { normalizeBy } from "../../utils";
import UserPicker from "../shared/UserPicker";

interface ParticipantsSelectorsProps {
  gameConfig: GameFormConfig;
  gameCreatorId: string;
  onConfigChange: (toUpdate: Partial<GameFormConfig>) => void;
}

function groupByUserId(array: string[]) {
  return array.reduce<Record<string, boolean>>((result, id) => {
    result[id] = true;
    return result;
  }, {});
}

export function ParticipantsSelectors({
  gameConfig,
  onConfigChange,
  gameCreatorId,
}: Readonly<ParticipantsSelectorsProps>) {
  const [selectedAdmins, setSelectedAdmins] = useState<JiraUser[]>([]);
  const [selectedEstimators, setSelectedEstimators] = useState<JiraUser[]>([]);
  const [selectedSpectators, setSelectedSpectators] = useState<JiraUser[]>([]);
  const admins = useMemo(() => Object.keys(gameConfig.admins || {}), [gameConfig.admins]);
  const { queryByAccountIds } = useUsersStore();

  useEffect(() => {
    void (async () => {
      const adminsDetails = await queryByAccountIds(admins);
      setSelectedAdmins(adminsDetails);
    })();
  }, [queryByAccountIds, admins]);

  useEffect(() => {
    void (async () => {
      const participantsDetails = await queryByAccountIds(Object.keys(gameConfig.participants));
      const normalizedParticipantsDetails = normalizeBy<JiraUser, "accountId">(participantsDetails, "accountId");
      const { estimatorsDetails, spectatorsDetails } = Object.values(gameConfig.participants).reduce<{
        estimatorsDetails: JiraUser[];
        spectatorsDetails: JiraUser[];
      }>(
        (acc, curr) => {
          const retrievedUser = normalizedParticipantsDetails[curr.accountId];
          if (curr.isSpectator && retrievedUser) {
            acc.spectatorsDetails.push(retrievedUser);
          } else if (retrievedUser) {
            acc.estimatorsDetails.push(retrievedUser);
          }
          return acc;
        },
        { estimatorsDetails: [], spectatorsDetails: [] },
      );
      setSelectedEstimators(estimatorsDetails);
      setSelectedSpectators(spectatorsDetails);
    })();
  }, [queryByAccountIds, gameConfig.participants]);

  const onParticipantsChange = useCallback(
    (actionMeta?: ActionMeta, isSpectatorsSelect: boolean = false) => {
      if (!actionMeta) return;
      if (actionMeta.action === "clear") {
        const filteredParticipants = Object.values(gameConfig.participants).reduce<Record<string, GameParticipant>>(
          (acc, curr) => {
            if (curr.accountId === gameCreatorId && curr.isSpectator === isSpectatorsSelect)
              acc[gameCreatorId] = { ...curr, isSpectator: !curr.isSpectator };
            else if (curr.isSpectator !== isSpectatorsSelect) acc[curr.accountId] = curr;
            return acc;
          },
          {},
        );
        onConfigChange({
          participants: filteredParticipants,
          admins: groupByUserId(admins.filter((admin) => admin === gameCreatorId || filteredParticipants[admin])),
        });
      } else if (actionMeta.removedValue?.value === gameCreatorId) {
        onConfigChange({
          participants: {
            ...gameConfig.participants,
            [gameCreatorId]: {
              ...gameConfig.participants[gameCreatorId],
              isSpectator: !gameConfig.participants[gameCreatorId].isSpectator,
            },
          },
        });
      } else if (actionMeta.action === "select-option") {
        if (typeof actionMeta.option?.value !== "string") {
          throw new Error("User accountId isn't string");
        }

        onConfigChange({
          participants: {
            ...gameConfig.participants,
            [actionMeta.option.value]: {
              ...gameConfig.participants?.[actionMeta.option.value],
              accountId: actionMeta.option.value,
              isSpectator: isSpectatorsSelect,
            },
          },
        });
      } else if (actionMeta.action === "remove-value") {
        const { [actionMeta.removedValue?.value as string]: userToDelete, ...rest } = gameConfig.participants;
        onConfigChange({
          participants: rest,
          admins: groupByUserId(admins.filter((admin) => admin !== userToDelete.accountId)),
        });
      }
    },
    [admins, gameConfig.participants, onConfigChange, gameCreatorId],
  );

  const onAdminsSelectChange = useCallback(
    (newUsers: JiraUser[], actionMeta?: ActionMeta) => {
      const updates: Partial<GameFormConfig> = {
        admins: newUsers.length ? groupByUserId(newUsers.map((u) => u.accountId)) : { [gameCreatorId]: true },
      };
      if (actionMeta?.action === "select-option") {
        const accountId = actionMeta.option?.value as string;
        const foundParticipant = gameConfig.participants[accountId];
        if (!foundParticipant) {
          updates.participants = {
            ...gameConfig.participants,
            [actionMeta.option?.value as string]: { accountId: actionMeta.option?.value as string, isSpectator: false },
          };
        }
      }
      onConfigChange(updates);
    },
    [gameConfig.participants, onConfigChange, gameCreatorId],
  );

  return (
    <>
      <Field name="game-administrators" label="Game administrators">
        {() => (
          <UserPicker
            selectedUsers={selectedAdmins}
            onSelectedUsersChange={onAdminsSelectChange}
            isOptionClearable={(option) => option.value !== gameCreatorId}
          />
        )}
      </Field>
      <Field name="game-estimators" label="Estimators">
        {() => (
          <UserPicker
            selectedUsers={selectedEstimators}
            onSelectedUsersChange={(_newUsers, actionMeta) => {
              onParticipantsChange(actionMeta);
            }}
          />
        )}
      </Field>
      <Field name="game-spectators" label="Spectators">
        {() => (
          <UserPicker
            selectedUsers={selectedSpectators}
            onSelectedUsersChange={(_newUsers, actionMeta) => {
              onParticipantsChange(actionMeta, true);
            }}
          />
        )}
      </Field>
    </>
  );
}
