import React, { ReactNode, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { useApplicationContext } from "../../providers/ApplicationContextProvider";
import { useOnConnectionStateChange, useParticipantInstanceRegister } from "../../services/firebase/participants";
import { showFlag } from "../../services/jira-api";
import { LoadingPage } from "./LoadingPage";
import { ParticipantsPresenceErrorBoundary } from "./ParticipantsPresenceErrorBoundary";

interface ParticipantPresenceControllerProps {
  children: ReactNode;
}

export function ParticipantPresenceController({ children }: ParticipantPresenceControllerProps) {
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();

  const { userAccountId } = useApplicationContext();
  const participantInstanceRegister = useParticipantInstanceRegister(id, userAccountId);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { data: isConnected } = useOnConnectionStateChange();

  useEffect(() => {
    let participantInstanceRegisterOff: () => Promise<void>;
    void (async () => {
      if (isConnected) {
        try {
          participantInstanceRegisterOff = await participantInstanceRegister(isConnected);
        } catch (e) {
          setIsError(true);
          showFlag(
            "Unable to join game",
            "Either Planning Poker game doesn’t exist or you are not invited to a private game",
            "error",
          );
        } finally {
          setIsLoading(false);
        }
      }
    })();
    return () => {
      void participantInstanceRegisterOff?.();
    };
  }, [isConnected, participantInstanceRegister, userAccountId, id]);

  if (isLoading) {
    return <LoadingPage text="Joining game..." />;
  }

  if (isError) {
    return <Navigate to={"/"} />;
  }

  return <ParticipantsPresenceErrorBoundary navigate={navigate}>{children}</ParticipantsPresenceErrorBoundary>;
}
