import React, { PropsWithChildren } from "react";

import { Box } from "@fuegokit/react";

import { formatDate } from "../../utils/attachments";

export function ChangelogEntry({ title, date, children }: PropsWithChildren<{ title: string; date: Date }>) {
  return (
    <Box mb={6}>
      <Box mb={4} display="flex" flexDirection="column">
        <h1>{title}</h1>
        <Box alignSelf="flex-end" mt={2}>
          <small>{formatDate(date)}</small>
        </Box>
      </Box>
      <div>{children}</div>
    </Box>
  );
}
