import { useCallback } from "react";

import { useGameData } from "../../../components/game/GameProvider";
import { useIsCurrentUserSpectator } from "../../../components/game/ParticipantsPanel/hooks/useIsCurrentUserSpectator";
import { CardsRevealed } from "../../ampli";
import { useRecordEvent } from "./useRecordEvent";

export function useRecordCardsRevealedEvent() {
  const { game, gameId } = useGameData();
  const isCurrentUserSpectator = useIsCurrentUserSpectator();
  const recordEvent = useRecordEvent(CardsRevealed);

  return useCallback(
    () => recordEvent({ game_id: gameId, is_spectator: isCurrentUserSpectator, issue_id: game.activeItemId! }),
    [game.activeItemId, gameId, isCurrentUserSpectator, recordEvent],
  );
}
