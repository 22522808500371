import React, { useMemo } from "react";

import { ParticipantVote } from "@appfire/poker-core";
import { Box } from "@fuegokit/react";

import "./cards.css";

import { useApplicationContext } from "../../../providers/ApplicationContextProvider";
import { CardType, GamePersona } from "../../../types/common";
import { RevealedCard } from "./RevealedCard";
import { UnrevealedVotes } from "./UnrevealedVotes";

interface CardDeckProps {
  cards: CardType[];
  isEditMode?: boolean;
  participantVotes: ParticipantVote<GamePersona, CardType, string>[];
  selected?: CardType;
  onCardClicked: (card: CardType) => void;
  onCardMouseEnter?: (estimate: string) => void;
  onCardMouseLeave?: () => void;
  onEdit: () => void;
}

export function CardDeck({
  cards,
  isEditMode,
  participantVotes,
  selected,
  onCardClicked,
  onCardMouseEnter,
  onCardMouseLeave,
  onEdit,
}: Readonly<CardDeckProps>) {
  const { userAccountId } = useApplicationContext();
  const shouldRenderCardDeck = useMemo(() => {
    return !selected || isEditMode;
  }, [selected, isEditMode]);
  const currentUserVote = useMemo(
    () => participantVotes.find((vote) => vote.participant.id === userAccountId),
    [participantVotes, userAccountId],
  );

  const otherVotes = useMemo(
    () => participantVotes.filter((vote) => vote.participant.id !== userAccountId),
    [userAccountId, participantVotes],
  );

  return (
    <Box display="flex" justifyContent="center" flexWrap="wrap" className="card-wrapper">
      {shouldRenderCardDeck ? (
        cards.map((card) => (
          <RevealedCard
            selected={selected?.value === card.value}
            className={`card pointer ${selected?.value === card.value ? "selected" : ""}`}
            key={card.value}
            card={card}
            onCardClicked={onCardClicked}
            onCardMouseEnter={onCardMouseEnter}
            onCardMouseLeave={onCardMouseLeave}
          />
        ))
      ) : (
        <UnrevealedVotes votes={otherVotes}>
          <RevealedCard
            className="selected card card-non-interactive"
            isEditable
            selected
            card={selected!}
            participantDetails={currentUserVote?.participant}
            onEditClick={onEdit}
          />
        </UnrevealedVotes>
      )}
    </Box>
  );
}
