import axios, { AxiosRequestConfig } from "axios";

import { ApplicationContext, GameWithId, JiraTransition } from "../types";
import { getJiraToken } from "./jira-api";

interface JSONResponse {
  data?: unknown;
}

async function authenticatedApiRequest<T>(
  url: string,
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH" = "GET",
  requestData?: unknown,
) {
  try {
    const token = await getJiraToken();
    const headers = {
      Authorization: `JWT ${token}`,
      contentType: "application/json",
    };

    const config: AxiosRequestConfig = {
      method: method.toUpperCase(),
      url,
      headers,
      data: requestData ?? undefined,
    };

    const { data }: JSONResponse = await axios(config);
    return data as T;
  } catch (error) {
    console.error("Error making API request:", error);
    throw error;
  }
}

export async function getApplicationContext(): Promise<ApplicationContext> {
  return authenticatedApiRequest<ApplicationContext>("/api/context");
}

export async function getTransitions(): Promise<JiraTransition[]> {
  return authenticatedApiRequest<JiraTransition[]>("/api/transitions");
}

export async function getGamesForUser(): Promise<GameWithId[]> {
  return authenticatedApiRequest(`/api/games`, "GET");
}

export async function postGameNotification(gameId: string): Promise<unknown> {
  return authenticatedApiRequest(`/api/games/${gameId}/notification`, "POST");
}
