import React, { Dispatch, SetStateAction, useMemo } from "react";

import { Box } from "@fuegokit/react";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useVirtualizer, useWindowVirtualizer } from "@tanstack/react-virtual";
import { ColumnDef } from "@tanstack/table-core";

import { DraggableList } from "../../dnd/hooks";
import { AbstractItem } from "../../types";
import { TableRowMemoized } from "./TableRow";

interface ItemsTableProps<TItem extends AbstractItem> {
  isLoading: boolean;
  children?: React.ReactNode;
  setRowSelection: Dispatch<SetStateAction<Record<string, boolean>>>;
  rowSelection: Record<string, boolean>;
  columns: ColumnDef<TItem, string>[];
  items: TItem[];
  disabledItemIds: string[];
  draggable?: boolean;
  onOrderChange?: (items: TItem[]) => void;
  DraggedItemPreview?: React.ComponentType<{ item: TItem }>;
  onRowClick?: (item: TItem) => void;
  isWindowVirtualizer?: boolean;
}

export function ItemsTable<TItem extends AbstractItem>({
  isLoading,
  items,
  columns,
  rowSelection,
  setRowSelection,
  children,
  disabledItemIds,
  onOrderChange,
  draggable = false,
  DraggedItemPreview,
  onRowClick,
  isWindowVirtualizer,
}: ItemsTableProps<TItem>) {
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const LOADING_ITEMS = Array.from(
    { length: 19 },
    (_, index) =>
      ({
        key: index.toString(),
      }) as TItem,
  );
  const table = useReactTable<TItem>({
    data: isLoading ? LOADING_ITEMS : items,
    columns,
    state: {
      rowSelection,
    },
    enableRowSelection: (row) => !disabledItemIds.includes(row.id),
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.key,
    getCoreRowModel: getCoreRowModel(),
  });
  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: React.useCallback(() => 34, []),
    getScrollElement: React.useCallback(() => tableContainerRef.current, []),
    overscan: 5,
  });

  const windowVirtualizer = useWindowVirtualizer({
    count: rows.length,
    estimateSize: React.useCallback(() => 34, []),
    overscan: 20,
    scrollMargin: tableContainerRef.current?.offsetTop ?? 0,
  });

  const virtualizer = useMemo(
    () => (isWindowVirtualizer ? windowVirtualizer : rowVirtualizer),
    [isWindowVirtualizer, rowVirtualizer, windowVirtualizer],
  );

  return (
    <DraggableList items={items} scrollContainerRef={tableContainerRef} onOrderChange={onOrderChange}>
      <div id="container-table" ref={tableContainerRef}>
        <Box className="issues-table">
          <Box className="issues-table-header">
            {table.getHeaderGroups().map((headerGroup) => (
              <Box className="issues-table-header-row" key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Box className={`issues-table-header-cell ${header.id}`} key={header.id} width={header.getSize()}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
          <Box className="issues-table-body" height={`${rowVirtualizer.getTotalSize()}px`}>
            {virtualizer.getVirtualItems().map((virtualRow) => {
              const row = rows[virtualRow.index];
              return (
                <TableRowMemoized<TItem>
                  key={row.id}
                  row={row}
                  scrollMargin={virtualizer.options.scrollMargin}
                  start={virtualRow.start}
                  index={virtualRow.index}
                  draggable={draggable}
                  onClick={onRowClick ? () => onRowClick(row.original) : undefined}
                  isSelected={row.getIsSelected()}
                  isDisabled={disabledItemIds.includes(row.id)}
                  DraggedItemPreview={DraggedItemPreview}
                />
              );
            })}
          </Box>
          {!isLoading && items.length === 0 && (
            <Box className="empty-items-table">
              <img src="/icon/no-issues.svg" alt="no-issues" />
            </Box>
          )}
        </Box>
        {items.length > 0 && children}
      </div>
    </DraggableList>
  );
}
