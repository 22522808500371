import React, { useMemo } from "react";
import styled, { css } from "styled-components";

import { ReferenceItemsComponentTypeProps } from "@appfire/poker-core/lib/types/ReferenceItemsComponentType";
import Button from "@atlaskit/button/new";
import ChevronDownIcon from "@atlaskit/icon/glyph/chevron-down";
import ChevronRightIcon from "@atlaskit/icon/glyph/chevron-right";
import { themeGet } from "@fuegokit/react";

import { JiraIssue } from "../../../../../types";
import { ContentStateManager } from "./components/ContentStateManager";
import { useIsExpandedState } from "./hooks/useIsExpandedState";

export const IGNORED_ESTIMATE_REFERENCES = ["coffee", "?"];

interface ReferenceIssuesPanelContainerProps {
  $isExpanded?: boolean;
}

const ReferenceIssuesPanelContainer = styled.section<ReferenceIssuesPanelContainerProps>`
  margin-bottom: 24px;
  border: 1px solid ${themeGet("colors.border.default")};
  border-radius: 6px;

  .btn-collapsible-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
  }

  .reference-issues-container {
    overflow: hidden;

    transition: height 200ms ease-in-out;
    ${(p) =>
      p.$isExpanded
        ? css`
            height: 120px;
          `
        : css`
            height: 0;
          `}
  }
`;

export function ReferenceIssuesPanel({
  areReferenceItemsExpanded: areReferenceItemsEnabled,
  currentReferences,
  currentReferencesContext,
  isLoading,
}: ReferenceItemsComponentTypeProps<JiraIssue>) {
  const { isExpanded, toggleIsExpanded } = useIsExpandedState();
  const checkedCurrentReferencesContext = useMemo(() => {
    if (!currentReferencesContext || IGNORED_ESTIMATE_REFERENCES.includes(currentReferencesContext.estimate)) {
      return null;
    }

    return currentReferencesContext;
  }, [currentReferencesContext]);

  if (!areReferenceItemsEnabled) return null;

  return (
    <ReferenceIssuesPanelContainer $isExpanded={isExpanded}>
      <Button
        iconBefore={isExpanded ? ChevronDownIcon : ChevronRightIcon}
        appearance="subtle"
        shouldFitContainer
        onClick={toggleIsExpanded}
      >
        <span className="btn-collapsible-content">
          {checkedCurrentReferencesContext
            ? `Issues already estimated as: ${checkedCurrentReferencesContext.estimate}`
            : "Estimation context will appear here"}
        </span>
      </Button>
      <div className="reference-issues-container">
        <ContentStateManager
          isLoading={isLoading}
          currentReferences={currentReferences}
          currentReferencesContext={checkedCurrentReferencesContext}
        />
      </div>
    </ReferenceIssuesPanelContainer>
  );
}
