import React from "react";
import { useNavigate } from "react-router-dom";

import { IconButton } from "@atlaskit/button/new";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import CopyIcon from "@atlaskit/icon/glyph/copy";
import ExportIcon from "@atlaskit/icon/glyph/export";
import FlagFilledIcon from "@atlaskit/icon/glyph/flag-filled";
import LinkIcon from "@atlaskit/icon/glyph/link";
import MoreIcon from "@atlaskit/icon/glyph/more";
import TrashIcon from "@atlaskit/icon/glyph/trash";

import { useCSVExport } from "../../../hooks/useCSVExport";
import { useApplicationContext } from "../../../providers/ApplicationContextProvider";
import { Game, GameState } from "../../../types";
import { isGameAdmin } from "../../../utils/game";
import { useGameUrl } from "./useGameUrl";

interface MoreActionsCellProps {
  gameId: string;
  game: Game;
}

export function MoreActionsCell({ gameId, game }: MoreActionsCellProps) {
  const { userAccountId, isJiraAdmin } = useApplicationContext();
  const navigate = useNavigate();

  const { copyGameUrl } = useGameUrl(gameId);
  const exportCSVFile = useCSVExport(gameId, game);

  const canManageGame = isGameAdmin(userAccountId, game.configuration.admins) || isJiraAdmin;

  return (
    <>
      <DropdownMenu<HTMLButtonElement>
        placement="bottom-end"
        trigger={({ triggerRef, ...props }) => (
          <IconButton
            {...props}
            icon={MoreIcon}
            appearance="subtle"
            label="More actions"
            ref={triggerRef}
            testId={`game-${game.configuration.name}-more-actions-button`}
          />
        )}
      >
        <DropdownItemGroup>
          <DropdownItem
            elemBefore={<CopyIcon label="Clone game" size="medium" />}
            onClick={() => navigate(`/clone-game/${gameId}`)}
          >
            Clone game configuration
          </DropdownItem>
          <DropdownItem elemBefore={<LinkIcon label="Copy URL" size="medium" />} onClick={copyGameUrl}>
            Copy game URL
          </DropdownItem>
          <DropdownItem
            elemBefore={<ExportIcon label="Exsport results as CSV" size="medium" />}
            onClick={exportCSVFile}
          >
            Export results (CSV)
          </DropdownItem>
          {canManageGame && game.state !== GameState.FINISHED && (
            <DropdownItem
              elemBefore={<FlagFilledIcon label="Finish game" size="medium" />}
              onClick={() => navigate(`/finish-game/${gameId}`)}
            >
              Finish game
            </DropdownItem>
          )}
          {canManageGame && (
            <DropdownItem
              elemBefore={<TrashIcon label="Delete game" size="medium" />}
              onClick={() => navigate(`/delete-game/${gameId}`)}
              testId="delete-game-dropdown-item"
            >
              Delete game
            </DropdownItem>
          )}
        </DropdownItemGroup>
      </DropdownMenu>
    </>
  );
}
