import { JiraField } from "../types";
import { secondsToJiraTimeRepresentation } from ".";

export function getEstimationValue(estimationValue?: unknown, estimationField?: JiraField) {
  if (estimationValue == undefined) return "-";

  if (typeof estimationValue === "number" && estimationField?.id?.includes("time")) {
    return secondsToJiraTimeRepresentation(estimationValue);
  }

  if (typeof estimationValue === "string" || typeof estimationValue === "number") {
    return String(estimationValue);
  }

  if (typeof estimationValue === "object" && "value" in estimationValue) {
    return String(estimationValue.value);
  }

  return "-";
}
