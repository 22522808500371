import React, { Dispatch, SetStateAction, useMemo } from "react";

import { Box } from "@fuegokit/react";

import { useJiraDataStore } from "../../providers/JiraDataStoreProvider";
import { JiraIssue } from "../../types";
import { createColumn, getCheckboxColumn } from "./headers";
import { IssuesTable } from "./IssuesTable";

interface BacklogTableProps {
  setRowSelection: Dispatch<SetStateAction<Record<string, boolean>>>;
  rowSelection: Record<string, boolean>;
  issues: JiraIssue[];
  isLoadingIssues: boolean;
  estimationFieldId?: string;
  columnsIds: string[];
  onOrderChange: (items: JiraIssue[]) => void;
}

export const BacklogTable = ({
  issues,
  setRowSelection,
  rowSelection,
  columnsIds,
  estimationFieldId,
  isLoadingIssues,
  onOrderChange,
}: BacklogTableProps) => {
  const { fields = [] } = useJiraDataStore();
  const columns = useMemo(
    () => [
      getCheckboxColumn({ isLoading: isLoadingIssues, disabledItemIds: [], hideCheckbox: true }),
      ...columnsIds.map((columnId) =>
        createColumn({ columnId, fields, estimationFieldId, isLoading: isLoadingIssues }),
      ),
    ],
    [columnsIds, fields, estimationFieldId, isLoadingIssues],
  );

  const nothingSelected = Object.keys(rowSelection).length === 0;

  return (
    <Box minHeight={0} pb={5} className={nothingSelected ? "hoverable-checkboxes" : ""}>
      <IssuesTable
        disabledItemIds={[]}
        isLoading={isLoadingIssues}
        columns={columns}
        issues={issues}
        fields={fields}
        draggable={true}
        onOrderChange={onOrderChange}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />
    </Box>
  );
};
