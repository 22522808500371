import { useCallback, useEffect, useMemo, useState } from "react";

import { useTimerUpdateStopTimestamp } from "../../../../../../services/firebase";
import { useGameData } from "../../../../GameProvider";

interface UseTimerRunningState {
  audio: HTMLAudioElement;
  onTimerEnd?: () => void;
}

export const useTimerRunningState = ({ audio, onTimerEnd }: UseTimerRunningState) => {
  const { game, gameId } = useGameData();
  const { stopTimestamp, selectedDuration } = game.timer;

  const [startValue, setStartValue] = useState(stopTimestamp - Date.now());
  const [currentValue, setCurrentValue] = useState(stopTimestamp - Date.now());
  const displayCurrentValue = useMemo(() => `${Math.ceil(currentValue / 1000)}s`, [currentValue]);

  // Sync timer with stopTimestamp
  useEffect(() => {
    const newValue = stopTimestamp - Date.now();

    setStartValue(newValue);
    setCurrentValue(newValue);
  }, [stopTimestamp]);
  // Countdown the timer
  useEffect(() => {
    if (currentValue < 0) return;

    const timeoutId = setTimeout(() => {
      setCurrentValue((prev) => prev - 1000);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [currentValue]);
  // Trigger audio and ending action
  useEffect(() => {
    if (startValue < 0) return;

    const displayStartValue = `${Math.ceil(startValue / 1000)}s`;

    if (displayStartValue === displayCurrentValue) {
      audio.src = "/sounds/timer_start.mp3";
      // Ignore play errors (for example user didn't interact with the page yet)
      void audio.play().catch(() => {});
    } else if (displayCurrentValue === "10s") {
      audio.src = "/sounds/timer_end_close.mp3";
      void audio.play().catch(() => {});
    } else if (displayCurrentValue === "0s") {
      audio.src = "/sounds/timer_end.mp3";
      void audio.play().catch(() => {});
      onTimerEnd?.();
    }
    // Adding rest of the dependencies will trigger this at wrong time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayCurrentValue]);

  const updateStopTimestamp = useTimerUpdateStopTimestamp(gameId);
  const startTimer = useCallback(() => {
    void updateStopTimestamp(Date.now() + selectedDuration);
  }, [selectedDuration, updateStopTimestamp]);
  const stopTimer = useCallback(() => {
    void updateStopTimestamp(-1);
  }, [updateStopTimestamp]);

  return {
    isRunning: currentValue > 0,
    value: currentValue,
    displayValue: displayCurrentValue,
    startTimer,
    stopTimer,
  };
};
