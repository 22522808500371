import React, { useCallback, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { ModalTransition } from "@atlaskit/modal-dialog";

import { useGameEditedEvent } from "../../../analytics/hooks/events/useGameEditedEvent";
import { useApplicationContext } from "../../../providers/ApplicationContextProvider";
import { useGameUpdate } from "../../../services/firebase";
import { debouncedShowFlag } from "../../../services/jira-api";
import { FULL_PAGE_DIALOG_CONTROLS_WIDTH } from "../../../utils";
import { AbstractModal } from "../../dashboard/modals/modals";
import { GameConfigForm } from "../../GameConfigForm/GameConfigForm";
import { FullPageDialog } from "../../shared/FullPageDialog/FullPageDialog";
import { useGameData } from "../GameProvider";
import { ModalControls } from "./components/ModalControls";
import { useFormGameConfig } from "./hooks/useFormGameConfig";

export function EditGameModal() {
  const { game, gameId } = useGameData();
  const { isJiraAdmin, userAccountId } = useApplicationContext();
  const recordEditGameEvent = useGameEditedEvent();

  const { formState, isDirty, isValid, onChange } = useFormGameConfig(game);
  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();
  const navigateToGame = useCallback(() => {
    navigate(`/game/${gameId}`);
  }, [gameId, navigate]);

  const navigateToDashboard = useCallback(() => navigate(`/`), [navigate]);

  const [isLeaveFormModalOpen, setIsLeaveFormModalOpen] = useState(false);
  const closeModal = useCallback(() => {
    if (isDirty && !isLeaveFormModalOpen) {
      return setIsLeaveFormModalOpen(true);
    }

    navigateToGame();
  }, [isDirty, isLeaveFormModalOpen, navigateToGame]);

  const editGame = useGameUpdate(gameId);
  const updateGameConfig = useCallback(async () => {
    if (!isDirty) {
      return navigateToGame();
    }
    setIsSaving(true);
    await editGame(formState);
    recordEditGameEvent(formState, gameId);
    const isUserInParticipantsList = Boolean(formState.participants[userAccountId]);
    if (isUserInParticipantsList) {
      navigateToGame();
    } else {
      navigateToDashboard();
    }
    setIsSaving(false);
  }, [editGame, formState, gameId, isDirty, navigateToDashboard, navigateToGame, recordEditGameEvent, userAccountId]);

  if (!isSaving && !isJiraAdmin && !game.configuration.admins[userAccountId]) {
    void debouncedShowFlag(
      "Incorrect user permissions",
      "You do not have the permission to open this game configuration",
      "info",
    );
    return <Navigate to={`/game/${gameId}`} />;
  }

  return (
    <>
      <FullPageDialog
        headerTitle="Game settings"
        maxWidth={FULL_PAGE_DIALOG_CONTROLS_WIDTH}
        content={<GameConfigForm gameConfig={formState} onConfigChange={onChange} gameCreatorId={game.creator} />}
        controls={
          <ModalControls
            isSaveDisabled={!isDirty || !isValid}
            onClose={closeModal}
            onSave={() => void updateGameConfig()}
          />
        }
        isOpen
        onClose={closeModal}
      />
      <ModalTransition>
        {isLeaveFormModalOpen ? (
          <AbstractModal
            title="Leave game settings"
            isWarning
            onClose={() => setIsLeaveFormModalOpen(false)}
            onConfirm={() => void closeModal()}
          >
            By confirming, you will leave the game settings and lose unsaved changes.
          </AbstractModal>
        ) : null}
      </ModalTransition>
    </>
  );
}
