import React from "react";

import { Box, Heading } from "@fuegokit/react";

interface AdvancedSettingsSectionProps {
  content: React.ReactElement;
  title: string;
}

export function AdvancedSettingsSection({ content, title }: AdvancedSettingsSectionProps) {
  return (
    <Box display="flex" flexDirection="column" gridGap={16} pb="16px">
      <Heading as="h2" variant="h500">
        {title}
      </Heading>
      {content}
    </Box>
  );
}
