import React, { useMemo } from "react";

import { Card, GroupedVote } from "@appfire/poker-core";

import { useApplicationContext } from "../../../providers/ApplicationContextProvider";
import { GamePersona } from "../../../types/common";
import { RevealedCard } from "../Cards/RevealedCard";
import { MoreVotesIndicator } from "./MoreVotesIndicator";

interface CardGroup {
  groupedVote: GroupedVote<GamePersona, Card>;
  onEditClick: () => void;
}

const CARDS_IN_GROUP_LIMIT = 10;

export function CardsGroup({ groupedVote, onEditClick }: CardGroup) {
  const { userAccountId } = useApplicationContext();

  const visibleParticipantsVotes = useMemo(
    () =>
      groupedVote.participants.length > CARDS_IN_GROUP_LIMIT
        ? groupedVote.participants.slice(-1 * (CARDS_IN_GROUP_LIMIT - 1))
        : groupedVote.participants,
    [groupedVote.participants],
  );
  const hiddenParticipantsVotes = useMemo(
    () =>
      groupedVote.participants.length > CARDS_IN_GROUP_LIMIT
        ? groupedVote.participants.slice(0, -1 * (CARDS_IN_GROUP_LIMIT - 1))
        : [],
    [groupedVote.participants],
  );

  return (
    <div className="group-card-container">
      {visibleParticipantsVotes.map((participantVote) => (
        <RevealedCard
          key={participantVote.id}
          card={groupedVote.card || { value: groupedVote.value, label: groupedVote.value }}
          participantDetails={participantVote}
          isInSummary
          isEditable={participantVote.id === userAccountId}
          onEditClick={onEditClick}
          className="card card-summary"
        />
      ))}
      {hiddenParticipantsVotes.length ? <MoreVotesIndicator notVisibleParticipants={hiddenParticipantsVotes} /> : null}
    </div>
  );
}
