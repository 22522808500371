import React from "react";
import styled from "styled-components";

const calculateSmallFont = (length: number, async?: boolean) => {
  switch (length) {
    case 1:
    case 2:
      return async ? "16px" : "24px";
    case 3:
      return async ? "12px" : "16px";
    default:
      return "9px";
  }
};

interface VoteProps {
  $length: number;
  $isAsync?: boolean;
}

const Vote = styled.span<VoteProps>`
  text-align: center;
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: ${(props) => calculateSmallFont(props.$length, props.$isAsync)};
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface VoteBadgeProps {
  className?: string;
  label: string;
  isAsync?: boolean;
}
export function VoteBadge({ className, label, isAsync }: VoteBadgeProps) {
  return (
    <Vote className={className} data-testid="vote-badge" $length={label.replaceAll(".", "").length} $isAsync={isAsync}>
      {label}
    </Vote>
  );
}
