import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";

import Tooltip from "@atlaskit/tooltip";

import { useEstimationField } from "../../../hooks/useEstimationField";
import { JiraIssue } from "../../../types";
import { getEstimationValue } from "../../../utils/estimation-values";
import { useGameIssues } from "../../game/GameIssuesProvider";

interface GameEstimationFieldProps {
  issue: JiraIssue;
  estimationFieldId: string;
}

const EstimationWrapper = styled.div<{ $isLong: boolean; $isEstimated: boolean }>`
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: ${(props) => (props.$isLong ? "flex-start" : "center")};
  align-items: center;
  background-color: ${(props) =>
    props.$isEstimated
      ? "var( --ds-background-accent-green-subtle, #4BCE97)"
      : "var(--ds-background-neutral, #091e420f)"};
  border-radius: 3px;
  overflow: hidden;
  padding: 0 2px;
`;

export function GameEstimationFieldCell({ issue, estimationFieldId }: Readonly<GameEstimationFieldProps>) {
  const ref = useRef<HTMLDivElement | null>(null);
  const estimationField = useEstimationField(estimationFieldId);
  const estimationFieldValue = getEstimationValue(issue.fields[estimationFieldId], estimationField);
  const { estimatedIssues } = useGameIssues();
  const [isLongText, setIsLongText] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setIsLongText(ref.current.scrollWidth > ref.current.clientWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimationFieldValue, ref.current]);

  const tooltipText = useMemo(
    () => (isLongText ? `${estimationField?.name} (${estimationFieldValue})` : estimationField?.name),
    [isLongText, estimationField?.name, estimationFieldValue],
  );

  const isEstimated = useMemo(
    () => estimatedIssues[issue.key]?.savedEstimate === estimationFieldValue,
    [estimatedIssues, estimationFieldValue, issue.key],
  );

  return (
    <EstimationWrapper $isLong={Boolean(isLongText)} $isEstimated={isEstimated} ref={ref}>
      <Tooltip content={tooltipText}>
        <div>{estimationFieldValue}</div>
      </Tooltip>
    </EstimationWrapper>
  );
}
