import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@atlaskit/button";
import { Box } from "@fuegokit/react";

import { BacklogEdited } from "../analytics/ampli";
import { useRecordEvent } from "../analytics/hooks/events/useRecordEvent";
import { Backlog } from "../components/backlog/Backlog";
import { AbstractModal } from "../components/dashboard/modals/modals";
import { useGameData } from "../components/game/GameProvider";
import { FullPageDialog } from "../components/shared/FullPageDialog/FullPageDialog";
import { useQueryIssuesById } from "../hooks/useQueryIssuesById";
import { useUpdateGameConfiguration } from "../services/firebase";
import { GameConfig, JiraIssue } from "../types";

export function EditBacklog() {
  const navigate = useNavigate();
  const { game, gameId } = useGameData();
  const [isDirty, setIsDirty] = useState(false);
  const [isLeaveWithoutSavingOpen, setIsLeaveWithoutSavingOpen] = useState(false);
  const [configuration, setConfiguration] = useState<GameConfig>(game.configuration);
  const updateGameConfiguration = useUpdateGameConfiguration(gameId);
  const { issues, setIssues, isLoadingIssues, setIssueKeys } = useQueryIssuesById(configuration);
  const recordBacklogEditedEvent = useRecordEvent(BacklogEdited);

  const onConfigChange = useCallback((toUpdate: Partial<GameConfig>) => {
    setConfiguration((prev) => ({ ...prev, ...toUpdate }));
  }, []);

  useEffect(() => {
    onConfigChange({ backlog: issues.map((i) => i.key) });
  }, [issues, onConfigChange]);

  const onOrderChange = useCallback(
    (items: JiraIssue[]) => {
      setIsDirty(true);
      setIssues?.(items);
    },
    [setIssues],
  );

  function onSave() {
    const { backlog = [], backlogColumnIds = [], jql } = configuration;
    const preSaveBacklogIssuesCount = game.configuration.backlog?.length ?? 0;
    void updateGameConfiguration({ backlog, backlogColumnIds, jql });
    recordBacklogEditedEvent({
      game_id: gameId,
      issue_number: backlog.length,
      previous_issue_number: preSaveBacklogIssuesCount,
    });
    navigate("./..");
  }

  const onBacklogEdited = useCallback(() => {
    setIsDirty(true);
  }, []);

  const onLeaveConfirm = useCallback(() => {
    if (isDirty && !isLeaveWithoutSavingOpen) {
      return setIsLeaveWithoutSavingOpen(true);
    }
    navigate("./..");
  }, [isDirty, isLeaveWithoutSavingOpen, navigate]);

  const isDisabled = !isDirty;

  return (
    <>
      <FullPageDialog
        isOpen={true}
        onClose={onLeaveConfirm}
        headerTitle={`${game.configuration.name} backlog setup`}
        controls={
          <Box display="flex" p={3} width="100%" maxWidth="525px" justifyContent="space-between">
            <Button onClick={onLeaveConfirm}>Close</Button>
            <Button appearance={isDirty ? "primary" : "default"} isDisabled={isDisabled} onClick={onSave}>
              Save & close
            </Button>
          </Box>
        }
        maxWidth="1069px"
        content={
          <Backlog
            gameConfig={configuration}
            onConfigChange={onConfigChange}
            issues={issues}
            onOrderChange={onOrderChange}
            setIssueKeys={setIssueKeys}
            isLoadingIssues={isLoadingIssues}
            onBacklogChange={onBacklogEdited}
          />
        }
      />
      {isLeaveWithoutSavingOpen && (
        <AbstractModal
          title="Leave backlog edit"
          width="small"
          isWarning
          onClose={() => setIsLeaveWithoutSavingOpen(false)}
          onConfirm={onLeaveConfirm}
        >
          You have unsaved changes on this page. If you leave now, your changes will be lost.
        </AbstractModal>
      )}
    </>
  );
}
