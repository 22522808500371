import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { getUsers, searchUsers } from "../services/jira-api";
import { JiraUser } from "../types";
import { separateFoundAndMissing } from "../utils";

interface UsersDataStoreContextData {
  searchResults: Record<string, JiraUser[]>;
  isLoading: boolean;
  queryUserPickerData: (query: string) => Promise<JiraUser[]>;
  queryByAccountIds: (accountIds: string[]) => Promise<JiraUser[]>;
}

const UsersDataStoreContext = createContext<UsersDataStoreContextData | null>(null);

interface UsersDataStoreContextProviderProps {
  children?: React.ReactNode;
}

export const UsersDataStoreContextProvider = ({ children }: UsersDataStoreContextProviderProps) => {
  const [searchResults, setSearchResults] = useState<Record<string, JiraUser[]>>({});
  const [usersByAccountIds, setUsersByAccountIds] = useState<Record<string, JiraUser>>({});
  const [isLoading, setIsLoading] = useState(false);

  const setNewSearchResult = useCallback(
    (query: string, result: JiraUser[]) => {
      setSearchResults((prev) => ({ ...prev, [query]: result }));
      const groupedByIds = result.reduce<Record<string, JiraUser>>((acc, curr) => {
        acc[curr.accountId] = curr;
        return acc;
      }, {});
      setUsersByAccountIds((prev) => ({ ...prev, ...groupedByIds }));
    },
    [setSearchResults],
  );

  useEffect(() => {
    void (async () => {
      setIsLoading(true);
      const initialSearchResult = await searchUsers("");
      setNewSearchResult("", initialSearchResult);
      setIsLoading(false);
    })();
  }, [setNewSearchResult]);

  const queryUserPickerData = useCallback(
    async (query: string) => {
      if (searchResults[query]) return searchResults[query];
      const result = await searchUsers(query);
      setNewSearchResult(query, result);
      return result;
    },
    [searchResults, setNewSearchResult],
  );

  const queryByAccountIds = useCallback(
    async (accountIds: string[]) => {
      const { foundItems, missingIds } = separateFoundAndMissing<JiraUser>(accountIds, usersByAccountIds);
      if (!missingIds.length) return foundItems;
      const missingUsers = await getUsers(missingIds);
      setUsersByAccountIds((prev) => ({ ...prev, ...missingUsers }));
      return [...foundItems, ...Object.values(missingUsers)];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(usersByAccountIds)],
  );

  const value = useMemo(
    () => ({
      isLoading,
      searchResults,
      queryUserPickerData,
      queryByAccountIds,
    }),
    [isLoading, searchResults, queryUserPickerData, queryByAccountIds],
  );

  return <UsersDataStoreContext.Provider value={value}>{children}</UsersDataStoreContext.Provider>;
};

export function useUsersStore() {
  const context = useContext(UsersDataStoreContext);
  if (!context) {
    throw new Error("User store context not initialized, probably context provider is missing");
  }
  return context;
}
