import { useEffect } from "react";

import { useGameData } from "../../../components/game/GameProvider";
import { GameJoined } from "../../ampli";
import { useRecordEvent } from "./useRecordEvent";

export function useGameJoinedEvent() {
  const recordEvent = useRecordEvent(GameJoined);
  const { gameId } = useGameData();

  useEffect(() => {
    const historyState = history.state as { from: string } | null;
    switch (historyState?.from) {
      case "dashboard":
        recordEvent({ game_id: gameId, route: "dashboard" });
        break;
      case undefined:
        recordEvent({ game_id: gameId, route: "link" });
    }
  }, [gameId, recordEvent]);
}
