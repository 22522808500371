import React, { ChangeEvent, useEffect, useState } from "react";

import { ErrorMessage, Field } from "@atlaskit/form";
import TextField from "@atlaskit/textfield";
import { Text } from "@fuegokit/react";

interface NameFieldProps {
  onChange: (value: string) => void;
  value: string;
}

enum NameInputError {
  EMPTY,
  TOO_LONG,
}

const InputLimitIndicator = ({ count }: { count: number }) => <Text paddingRight={1}>{count}/80</Text>;

export function NameField({ onChange, value }: Readonly<NameFieldProps>) {
  const [error, setError] = useState<NameInputError | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (isDirty && !value) {
      setError(NameInputError.EMPTY);
    } else if (value.length > 80) {
      setError(NameInputError.TOO_LONG);
    } else {
      setError(null);
    }
  }, [value, isDirty]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsDirty(true);
    onChange(e.target.value);
  };

  return (
    <Field name="name" label="Game name" isRequired>
      {() => (
        <>
          <TextField
            placeholder="i.e. Team [Name] Game [Date]"
            onChange={onInputChange}
            value={value}
            isInvalid={error !== null}
            maxLength={80}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            elemAfterInput={isFocused ? <InputLimitIndicator count={value.length} /> : null}
            testId="name-input"
          />
          {error === NameInputError.EMPTY && <ErrorMessage>Game name is required to proceed.</ErrorMessage>}
          {error === NameInputError.TOO_LONG && <ErrorMessage>Characters limit exceeded.</ErrorMessage>}
        </>
      )}
    </Field>
  );
}
