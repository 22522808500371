import { Game } from "../../types";
import { DECKS } from "../../utils";

export const analyticsFallback = (value: string | undefined) => value ?? "unavailable";

export const getDeckTypeValue = (cards: string[]) => {
  const [deckName] = Object.entries(DECKS).find(([, deckCards]) => cards.join("") === deckCards.join("")) ?? [];
  switch (deckName) {
    case "fibonacci":
      return "Fibonacci";
    case "modified-fibonacci":
      return "Modified Fibonacci";
    case "t-shirt":
      return "T-Shirt";
    case "labeled-t-shirt":
      return "Labeled T-Shirt";
    case "1-12":
      return "Hours";
    default:
      return "Custom";
  }
};

export const getActiveParticipantsCount = (game: Game) =>
  Object.values(game.participants).filter((participant) => participant.instances).length;

export const getEstimatedIssuesCount = (game: Game) =>
  Object.values(game.voting ?? {}).filter((voting) => voting.savedEstimate).length;
