import React from "react";

import Lozenge from "@atlaskit/lozenge";

import { JiraIssue } from "../../../types";
import { getIssueStatusColorAndBackgroundColor } from "../../../utils/colors";

interface StatusLabelProps {
  issue: JiraIssue;
  maxWidth?: number;
}

export function StatusLabel({ issue, maxWidth = 200 }: Readonly<StatusLabelProps>) {
  const [color, backgroundColor] = getIssueStatusColorAndBackgroundColor(issue);
  return (
    <Lozenge maxWidth={maxWidth} style={{ color, backgroundColor }}>
      <span data-private>{issue.fields.status?.name}</span>
    </Lozenge>
  );
}
