import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";

import Avatar, { AvatarPropTypes } from "@atlaskit/avatar";
import Tooltip from "@atlaskit/tooltip";

interface AvatarWithIconProps {
  icon?: ReactElement;
  tooltipContent?: ReactNode;
}

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: -1px;
  right: 0px;
`;

export function AvatarWithIcon({ icon, tooltipContent, ...avatarProps }: AvatarPropTypes & AvatarWithIconProps) {
  return (
    <Container data-private>
      <Tooltip content={tooltipContent ? <span data-private>{tooltipContent}</span> : undefined}>
        <Avatar {...avatarProps} />
      </Tooltip>
      {icon && <IconWrapper>{icon}</IconWrapper>}
    </Container>
  );
}
