import React from "react";

import { ChangelogEntry } from "./ChangelogEntry";
import { ChangelogItem } from "./index";

const date = new Date("2022-02-22");

function February2022() {
  return (
    <ChangelogEntry title="February 2022 update" date={date}>
      <p>Planning Poker has received multiple experience improvements, including:</p>
      <ul>
        <li>
          <strong>Joining games:</strong> sharable game URL from the address bar,<i> Share game</i> option;
        </li>
        <li>
          <strong>Creating games:</strong> new experience for selecting estimation field, card deck, and managing
          labels; new labeled T-Shirt sizes template, expandable and collapsable<i> Advanced settings</i>;
        </li>
        <li>
          <strong>Editing backlog:</strong> reworked backlog selection experience is now intuitive to Jira users, empty
          backlog instructions, maximum backlog size warning, default Epic column in the backlog;
        </li>
        <li>
          <strong>In-game experience:</strong> new experience for<i> Spectators</i>, ability to assign participant role
          by game moderators, reworked estimation results area;
        </li>
        <li>
          <strong>Dashboard experience:</strong> improved game list filtering.
        </li>
      </ul>
    </ChangelogEntry>
  );
}

export default {
  Component: February2022,
  date,
} as ChangelogItem;
