import { useCallback, useEffect } from "react";

import { useApplicationContext } from "../../../providers/ApplicationContextProvider";
import { getProject } from "../../../services/jira-api";
import { DashboardOpened } from "../../ampli";
import { useRecordEvent } from "./useRecordEvent";

export function useDashboardOpenedEvent() {
  const recordEvent = useRecordEvent(DashboardOpened);
  const { project, location } = useApplicationContext();

  const recordProjectContextEvent = useCallback(
    (projectId: string) =>
      void (async () => {
        const projectDetails = await getProject(projectId);
        recordEvent({
          route: "board_menu",
          project_id: projectId,
          project_type: projectDetails?.projectTypeKey,
          software_team_managed_project: projectDetails?.simplified,
        });
      })(),
    [recordEvent],
  );

  useEffect(() => {
    if (location.includes("planning-poker-project-view")) {
      project ? recordProjectContextEvent(String(project.id)) : recordEvent({ route: "board_menu" });
    } else {
      recordEvent({ route: "apps_menu" });
    }
  }, [location, project, recordEvent, recordProjectContextEvent]);
}
