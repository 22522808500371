import { GameConfig } from "../types";
import { getErrorMessage } from "../utils";
import { getIssueTransitions, moveIssuesToSprint, showFlag, transitionIssue, updateIssue } from "./jira-api";

type AutomationProperty = keyof Pick<
  GameConfig,
  "estimatedLabel" | "estimatedTransition" | "estimatedSprintId" | "skippedLabel"
>;

type Status = "error" | "success";

interface AutomationResponse {
  message: string;
  status: Status;
}

type AutomationCallback = (issueKey: string, gameConfiguration: GameConfig) => Promise<AutomationResponse>;

async function addLabelsAutomation(issueKey: string, labelsString: string): Promise<AutomationResponse> {
  try {
    const labelsToAdd = labelsString.split(",");
    const labels = labelsToAdd.map((label) => ({ add: label.trim() }));
    const update = { labels };
    await updateIssue(issueKey, update);
    return { status: "success", message: `Added labels: ${labelsToAdd.join(", ")} to issue: ${issueKey}.` };
  } catch (error) {
    return { status: "error", message: getErrorMessage(error) };
  }
}

const transitionIssueAutomation: AutomationCallback = async (issueKey, gameConfiguration) => {
  try {
    const transitions = await getIssueTransitions(issueKey);
    const transitionToState = gameConfiguration.estimatedTransition;
    const transition = transitions.find((transition) => transition.name === transitionToState);
    if (!transition)
      return {
        status: "error",
        message: `Transition ${transitionToState} is not valid for this issue`,
      };
    await transitionIssue(issueKey, transition.id);
    return { status: "success", message: `Issue ${issueKey} transitioned to state ${transition.to.name}.` };
  } catch (error) {
    return { status: "error", message: getErrorMessage(error) };
  }
};

const moveIssueToSprintAutomation: AutomationCallback = async (issueKey, gameConfiguration) => {
  if (!gameConfiguration.estimatedSprintId) {
    throw new Error("estimatedSprintId needed for moveIssueToSprintAutomation!");
  }
  try {
    await moveIssuesToSprint(gameConfiguration.estimatedSprintId, [issueKey]);
    return {
      status: "success",
      message: `Issue ${issueKey} moved to sprint with id: ${gameConfiguration.estimatedSprintId}`,
    };
  } catch (error) {
    return { status: "error", message: getErrorMessage(error) };
  }
};

const estimatedLabelsAutomation: AutomationCallback = (issueKey, gameConfiguration) => {
  if (!gameConfiguration.estimatedLabel) {
    throw new Error("estimatedLabel missing in game configuration");
  }
  return addLabelsAutomation(issueKey, gameConfiguration.estimatedLabel);
};

const skippedLabelAutomation: AutomationCallback = (issueKey, gameConfiguration) => {
  if (!gameConfiguration.skippedLabel) {
    throw new Error("skippedLabel missing in game configuration");
  }
  return addLabelsAutomation(issueKey, gameConfiguration.skippedLabel);
};

const AUTOMATION_CALLBACKS: Record<AutomationProperty, AutomationCallback> = {
  estimatedLabel: estimatedLabelsAutomation,
  estimatedTransition: transitionIssueAutomation,
  estimatedSprintId: moveIssueToSprintAutomation,
  skippedLabel: skippedLabelAutomation,
};

export async function runAutomations(
  issueKey: string,
  gameConfiguration: GameConfig,
  automations: AutomationProperty[],
  refetchIssue: (issueKey: string) => Promise<void>,
) {
  const automationsToPerform = automations.filter((automation) => gameConfiguration[automation]);
  if (!automationsToPerform.length) return;
  const promises = automationsToPerform.map((automation) =>
    AUTOMATION_CALLBACKS[automation](issueKey, gameConfiguration),
  );
  const results = await Promise.all(promises);
  results.forEach(({ message, status }) =>
    showFlag(status == "error" ? "Failed to perform automation" : "Automation applied", message, status),
  );
  void refetchIssue(issueKey);
}

const SAVE_ESTIMATE_AUTOMATIONS: AutomationProperty[] = ["estimatedLabel", "estimatedTransition", "estimatedSprintId"];
const SKIP_ISSUE_ESTIMATIONS: AutomationProperty[] = ["skippedLabel"];

export const runSaveEstimateAutomation = (
  issueKey: string,
  gameConfiguration: GameConfig,
  refetchIssue: (issueId: string) => Promise<void>,
) => runAutomations(issueKey, gameConfiguration, SAVE_ESTIMATE_AUTOMATIONS, refetchIssue);

export const runSkipIssueAutomation = (
  issueKey: string,
  gameConfiguration: GameConfig,
  refetchIssue: (issueId: string) => Promise<void>,
) => runAutomations(issueKey, gameConfiguration, SKIP_ISSUE_ESTIMATIONS, refetchIssue);
