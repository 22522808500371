import React, { ReactElement } from "react";
import styled from "styled-components";

import Tooltip from "@atlaskit/tooltip";
import { Box, themeGet } from "@fuegokit/react";

import { capitalizeFirstLetter } from "../../../utils";
import { ExpandTransition } from "./ExpandTransition";

interface GroupHeaderProps {
  isExpanded: boolean;
  groupCount: number;
  groupName: string;
  icon: ReactElement;
}

const HeaderText = styled.div`
  color: ${themeGet("colors.text.subtle")};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  margin-left: 8px;
  white-space: nowrap;
`;

export function GroupHeader({ isExpanded, groupCount, groupName, icon }: Readonly<GroupHeaderProps>) {
  return (
    <Box
      paddingX="12px"
      paddingY="8px"
      display="flex"
      justifyContent={isExpanded ? "space-between" : "center"}
      alignItems="center"
    >
      <Tooltip content={isExpanded ? "" : `Online ${groupName}s: ${groupCount}. Click to expand`}>
        <Box display="flex" alignItems="center">
          {icon}
          <HeaderText>
            {groupCount}
            <ExpandTransition $isExpanded={isExpanded}>
              {isExpanded && ` ${capitalizeFirstLetter(groupName)}${groupCount !== 1 ? "s" : ""}`}
            </ExpandTransition>
          </HeaderText>
        </Box>
      </Tooltip>
    </Box>
  );
}
