import React from "react";
import styled from "styled-components";

import Spinner from "@atlaskit/spinner";
import { Box } from "@fuegokit/react";

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

interface LoadingPageProps {
  text?: string;
}

export function LoadingPage({ text }: LoadingPageProps) {
  return (
    <Center>
      <Spinner size="large" />
      {text && <Box p={4}>{text}</Box>}
    </Center>
  );
}
