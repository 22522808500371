/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull frontend_ts'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 39
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/implementation/frontend_ts)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: 'b2d4ddfc1fd1a4d1109f6489ee7a2cd3',
  development: 'bb7e04c1e4d5500e0d474f011d080692'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '39',
    branch: 'main',
    source: 'frontend_ts',
    versionId: '7587b7a2-f148-4b30-a45d-543790163e0b'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface AsyncConfigChangedProperties {
  /**
   * The dropdown value of the **Card deck**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Fibonacci, T-Shirt, Labeled T-Shirt, Hours, Custom, Modified Fibonacci |
   */
  deck_type: "Fibonacci" | "T-Shirt" | "Labeled T-Shirt" | "Hours" | "Custom" | "Modified Fibonacci";
  /**
   * The name of the custom field selected in the **Estimation field** dropdown
   */
  estimation_field: string;
  /**
   * State of the **Hide "Async mode" tab on issue details page** feature from app's global config
   */
  hide_async_enabled: boolean;
}

export interface BacklogEditedProperties {
  /**
   * Unique identifier of an estimation game
   */
  game_id: string;
  /**
   * The number of issues added to the game during the game creation process
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  issue_number: number;
  /**
   * The number of issues added that were in the game before saving backlog edits
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  previous_issue_number: number;
}

export interface CardsRevealedProperties {
  /**
   * Unique identifier of an estimation game
   */
  game_id: string;
  is_spectator: boolean;
  /**
   * Unique identifier of a Jira issue
   */
  issue_id: string;
}

export interface DashboardOpenedProperties {
  /**
   * Unique identifier of a board from which the user navigated to the dashboard.
   */
  project_id?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | software, service_desk, business, product_discovery |
   */
  project_type?: "software" | "service_desk" | "business" | "product_discovery";
  /**
   * How did the user get to the dashboard?
   *
   * 1. App's menu
   *
   * 2. Board menu
   *
   * 3. Link
   *
   * How did the user get to the game?
   *
   * 1. Dashboard
   *
   * 2. Link
   *
   * 3. Mobile view
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | apps_menu, board_menu, link, dashboard, mobile_view |
   */
  route: "apps_menu" | "board_menu" | "link" | "dashboard" | "mobile_view";
  software_team_managed_project?: boolean;
}

export interface EditIssueClickedProperties {
  /**
   * Unique identifier of an estimation game
   */
  game_id: string;
  /**
   * The property is TRUE when the event comes from a participant from the game admin list.
   */
  is_game_admin: boolean;
  /**
   * Unique identifier of a Jira issue
   */
  issue_id: string;
}

export interface EstimateTabClickedProperties {
  /**
   * Type of action on the Estimate (Async) tab from the issue details view.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | estimate, show_estimate, show_all_estimates, reset_all_estimates, cancel_my_estimate |
   */
  action_type: "estimate" | "show_estimate" | "show_all_estimates" | "reset_all_estimates" | "cancel_my_estimate";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  issue_id: string;
}

export interface EstimateTabOpenedProperties {
  /**
   * Unique identifier of a Jira issue
   */
  issue_id: string;
}

export interface FinalEstimateSavedProperties {
  /**
   * The total number of online participants when the event happened.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  active_participants: number;
  /**
   * Estimation value (even if it's labeled still using the value) the card that the participant clicked or admin selected to be saved
   */
  estimation_value: string;
  /**
   * Unique identifier of an estimation game
   */
  game_id: string;
  /**
   * The property is TRUE when the event comes from a participant from the game admin list.
   */
  is_game_admin: boolean;
  is_spectator: boolean;
  /**
   * Unique identifier of a Jira issue
   */
  issue_id: string;
}

export interface GameClonedProperties {
  admin_password_set?: boolean;
  /**
   * The number of game admins added through the "Multiple admins" field
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  admins_count: number;
  /**
   * "True" if the creator has clicked on the **Advanced settings** tab at least 1 time
   */
  advanced_expanded?: boolean;
  /**
   * Property to determine if the game creator has added at least 1 automation through the following inputs:
   *
   * * label estimated
   *
   * * label skipped
   *
   * * label removed
   *
   * * issue status estimates
   *
   * * issue status removed
   *
   * * move to sprint
   */
  any_automation_applied: boolean;
  /**
   * **Round auto start** option is on
   */
  autopickup?: boolean;
  cloned_game_id: string;
  coffee_card_enabled: boolean;
  /**
   * The list of fields added to the **Custom fields below** input
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  custom_fields_bottom?: string[];
  /**
   * The list of fields added to the **Custom fields above** input
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  custom_fields_top?: string[];
  /**
   * The property is TRUE if labels are used in the **Card deck** input
   */
  deck_mapping: boolean;
  /**
   * The dropdown value of the **Card deck**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Fibonacci, T-Shirt, Labeled T-Shirt, Hours, Custom, Modified Fibonacci |
   */
  deck_type: "Fibonacci" | "T-Shirt" | "Labeled T-Shirt" | "Hours" | "Custom" | "Modified Fibonacci";
  /**
   * The name of the custom field selected in the **Estimation field** dropdown
   */
  estimation_field: string;
  /**
   * Unique identifier of an estimation game
   */
  game_id: string;
  has_name: boolean;
  /**
   * The number of issues added to the game during the game creation process
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  issue_number: number;
  /**
   * The property is TRUE when sprint in **Move issue to sprint after estimation** in Advanced settings was selected.
   */
  move_to_sprint: boolean;
  /**
   * The property is TRUE when the **Notify participants** input is not empty
   */
  notify_participants: boolean;
  /**
   * The property is TRUE when the **Private game** feature is active
   */
  private_game: boolean;
  /**
   * The property is TRUE when the **Timer auto start** feature is active
   */
  timer_autostart?: boolean;
  /**
   * The value from the **Timer duration** field
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  timer_duration?: number;
  /**
   * The property is TRUE when the **Timer sound** feature is active
   */
  timer_sound?: boolean;
}

export interface GameEditedProperties {
  admin_password_set?: boolean;
  /**
   * The number of game admins added through the "Multiple admins" field
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  admins_count: number;
  /**
   * "True" if the creator has clicked on the **Advanced settings** tab at least 1 time
   */
  advanced_expanded?: boolean;
  /**
   * Property to determine if the game creator has added at least 1 automation through the following inputs:
   *
   * * label estimated
   *
   * * label skipped
   *
   * * label removed
   *
   * * issue status estimates
   *
   * * issue status removed
   *
   * * move to sprint
   */
  any_automation_applied: boolean;
  /**
   * **Round auto start** option is on
   */
  autopickup?: boolean;
  coffee_card_enabled: boolean;
  /**
   * The list of fields added to the **Custom fields below** input
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  custom_fields_bottom?: string[];
  /**
   * The list of fields added to the **Custom fields above** input
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  custom_fields_top?: string[];
  /**
   * The property is TRUE if labels are used in the **Card deck** input
   */
  deck_mapping: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  deck_type: string;
  /**
   * The name of the custom field selected in the **Estimation field** dropdown
   */
  estimation_field: string;
  /**
   * Unique identifier of an estimation game
   */
  game_id: string;
  has_name: boolean;
  /**
   * The number of issues added to the game during the game creation process
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  issue_number: number;
  /**
   * The property is TRUE when sprint in **Move issue to sprint after estimation** in Advanced settings was selected.
   */
  move_to_sprint: boolean;
  /**
   * The property is TRUE when the **Notify participants** input is not empty
   */
  notify_participants: boolean;
  /**
   * The property is TRUE when the **Private game** feature is active
   */
  private_game: boolean;
  /**
   * The property is TRUE when the **Timer auto start** feature is active
   */
  timer_autostart?: boolean;
  /**
   * The value from the **Timer duration** field
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  timer_duration?: number;
  /**
   * The property is TRUE when the **Timer sound** feature is active
   */
  timer_sound?: boolean;
}

export interface GameFinishedProperties {
  /**
   * The total number of online participants when the event happened.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  active_participants: number;
  /**
   * The total number of issues estimated during this game
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  estimated_issues: number;
  /**
   * Unique identifier of an estimation game
   */
  game_id: string;
}

export interface GameJoinedProperties {
  /**
   * Unique identifier of an estimation game
   */
  game_id: string;
  /**
   * How did the user get to the dashboard?
   *
   * 1. App's menu
   *
   * 2. Board menu
   *
   * 3. Link
   *
   * How did the user get to the game?
   *
   * 1. Dashboard
   *
   * 2. Link
   *
   * 3. Mobile view
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | apps_menu, board_menu, link, dashboard, mobile_view |
   */
  route: "apps_menu" | "board_menu" | "link" | "dashboard" | "mobile_view";
}

export interface GameRemovedProperties {
  /**
   * Unique identifier of an estimation game
   */
  game_id: string;
  /**
   * State of the game
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Active, Finished |
   */
  game_state: "Active" | "Finished";
}

export interface GameReopenedProperties {
  /**
   * Unique identifier of an estimation game
   */
  game_id: string;
}

export interface GlobalConfigChangedProperties {
  /**
   * State of the **Allow users to change mobile URL** feature from app's global config
   */
  allow_change_url?: boolean;
  /**
   * State of the **Disable mobile version** feature from app's global config
   */
  disable_mobile_version?: boolean;
  /**
   * Stats of the **User permissions will be checked** feature from the app's global config
   */
  user_permissions_enabled: boolean;
}

export interface GlobalConfigOpenedProperties {
  /**
   * State of the **Allow users to change mobile URL** feature from app's global config
   */
  allow_change_url?: boolean;
  /**
   * The dropdown value of the **Card deck**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Fibonacci, T-Shirt, Labeled T-Shirt, Hours, Custom, Modified Fibonacci |
   */
  deck_type: "Fibonacci" | "T-Shirt" | "Labeled T-Shirt" | "Hours" | "Custom" | "Modified Fibonacci";
  /**
   * State of the **Disable mobile version** feature from app's global config
   */
  disable_mobile_version?: boolean;
  /**
   * The name of the custom field selected in the **Estimation field** dropdown
   */
  estimation_field: string;
  /**
   * State of the **Hide "Async mode" tab on issue details page** feature from app's global config
   */
  hide_async_enabled: boolean;
  /**
   * Stats of the **User permissions will be checked** feature from the app's global config
   */
  user_permissions_enabled: boolean;
}

export interface IssueQuickAddedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  action: string;
  /**
   * Unique identifier of an estimation game
   */
  game_id: string;
}

export interface IssuesAddedProperties {
  /**
   * Unique identifier of an estimation game
   */
  game_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  issues_number: number;
}

export interface IssueSelectedProperties {
  /**
   * Unique identifier of an estimation game
   */
  game_id: string;
  /**
   * Unique identifier of a Jira issue
   */
  issue_id: string;
}

export interface IssuesEstimatedHiddenProperties {
  estimated_hidden: boolean;
}

export interface OrderByProperties {
  data_type: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | ASC, DESC |
   */
  direction: "ASC" | "DESC";
  /**
   * Unique identifier of an estimation game
   */
  game_id: string;
}

export interface PersonalVoteProvidedProperties {
  /**
   * Estimation value (even if it's labeled still using the value) the card that the participant clicked or admin selected to be saved
   */
  estimation_value: string;
  /**
   * Unique identifier of an estimation game
   */
  game_id: string;
  /**
   * The property is TRUE when the event comes from a participant from the game admin list.
   */
  is_game_admin: boolean;
  /**
   * Unique identifier of a Jira issue
   */
  issue_id: string;
}

export interface ReplayClickedProperties {
  /**
   * Unique identifier of an estimation game
   */
  game_id: string;
  is_spectator: boolean;
  /**
   * Unique identifier of a Jira issue
   */
  issue_id: string;
}

export interface StartGameClickedProperties {
  admin_password_set?: boolean;
  /**
   * The number of game admins added through the "Multiple admins" field
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  admins_count: number;
  /**
   * "True" if the creator has clicked on the **Advanced settings** tab at least 1 time
   */
  advanced_expanded?: boolean;
  /**
   * Property to determine if the game creator has added at least 1 automation through the following inputs:
   *
   * * label estimated
   *
   * * label skipped
   *
   * * label removed
   *
   * * issue status estimates
   *
   * * issue status removed
   *
   * * move to sprint
   */
  any_automation_applied: boolean;
  /**
   * **Round auto start** option is on
   */
  autopickup?: boolean;
  coffee_card_enabled: boolean;
  /**
   * The list of fields added to the **Custom fields** in **Add custom fields to game layout** in Advanced settings
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  custom_fields?: string[];
  /**
   * The list of fields added to the **Custom fields below** input
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  custom_fields_bottom?: string[];
  /**
   * The list of fields added to the **Custom fields above** input
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  custom_fields_top?: string[];
  /**
   * The property is TRUE if labels are used in the **Card deck** input
   */
  deck_mapping: boolean;
  /**
   * The dropdown value of the **Card deck**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Fibonacci, T-Shirt, Labeled T-Shirt, Hours, Custom, Modified Fibonacci |
   */
  deck_type: "Fibonacci" | "T-Shirt" | "Labeled T-Shirt" | "Hours" | "Custom" | "Modified Fibonacci";
  /**
   * The name of the custom field selected in the **Estimation field** dropdown
   */
  estimation_field: string;
  /**
   * Unique identifier of an estimation game
   */
  game_id: string;
  has_name: boolean;
  /**
   * The number of issues added to the game during the game creation process
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  issue_number: number;
  /**
   * The property is TRUE when sprint in **Move issue to sprint after estimation** in Advanced settings was selected.
   */
  move_to_sprint: boolean;
  /**
   * The property is TRUE when the **Notify participants** input is not empty
   */
  notify_participants: boolean;
  /**
   * The property is TRUE when the **Private game** feature is active
   */
  private_game: boolean;
  /**
   * The property is TRUE when the **Timer auto start** feature is active
   */
  timer_autostart?: boolean;
  /**
   * The value from the **Timer duration** field
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  timer_duration?: number;
  /**
   * The property is TRUE when the **Timer sound** feature is active
   */
  timer_sound?: boolean;
}

export interface VoteEditedProperties {
  /**
   * Estimation value (even if it's labeled still using the value) the card that the participant clicked or admin selected to be saved
   */
  estimation_value: string;
  /**
   * Unique identifier of an estimation game
   */
  game_id: string;
  /**
   * The property is TRUE when the event comes from a participant from the game admin list.
   */
  is_game_admin: boolean;
  /**
   * Unique identifier of a Jira issue
   */
  issue_id: string;
}

export class AsyncConfigChanged implements BaseEvent {
  event_type = 'AsyncConfigChanged';

  constructor(
    public event_properties: AsyncConfigChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BacklogEdited implements BaseEvent {
  event_type = 'BacklogEdited';

  constructor(
    public event_properties: BacklogEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CardsRevealed implements BaseEvent {
  event_type = 'CardsRevealed';

  constructor(
    public event_properties: CardsRevealedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DashboardOpened implements BaseEvent {
  event_type = 'DashboardOpened';

  constructor(
    public event_properties: DashboardOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditIssueClicked implements BaseEvent {
  event_type = 'EditIssueClicked';

  constructor(
    public event_properties: EditIssueClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EstimateTabClicked implements BaseEvent {
  event_type = 'EstimateTabClicked';

  constructor(
    public event_properties: EstimateTabClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EstimateTabOpened implements BaseEvent {
  event_type = 'EstimateTabOpened';

  constructor(
    public event_properties: EstimateTabOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FinalEstimateSaved implements BaseEvent {
  event_type = 'FinalEstimateSaved';

  constructor(
    public event_properties: FinalEstimateSavedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GameCloned implements BaseEvent {
  event_type = 'GameCloned';

  constructor(
    public event_properties: GameClonedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GameEdited implements BaseEvent {
  event_type = 'GameEdited';

  constructor(
    public event_properties: GameEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GameFinished implements BaseEvent {
  event_type = 'GameFinished';

  constructor(
    public event_properties: GameFinishedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GameJoined implements BaseEvent {
  event_type = 'GameJoined';

  constructor(
    public event_properties: GameJoinedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GameRemoved implements BaseEvent {
  event_type = 'GameRemoved';

  constructor(
    public event_properties: GameRemovedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GameReopened implements BaseEvent {
  event_type = 'GameReopened';

  constructor(
    public event_properties: GameReopenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GlobalConfigChanged implements BaseEvent {
  event_type = 'GlobalConfigChanged';

  constructor(
    public event_properties: GlobalConfigChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GlobalConfigOpened implements BaseEvent {
  event_type = 'GlobalConfigOpened';

  constructor(
    public event_properties: GlobalConfigOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IssueQuickAdded implements BaseEvent {
  event_type = 'IssueQuickAdded';

  constructor(
    public event_properties: IssueQuickAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IssuesAdded implements BaseEvent {
  event_type = 'IssuesAdded';

  constructor(
    public event_properties: IssuesAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IssueSelected implements BaseEvent {
  event_type = 'IssueSelected';

  constructor(
    public event_properties: IssueSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IssuesEstimatedHidden implements BaseEvent {
  event_type = 'IssuesEstimatedHidden';

  constructor(
    public event_properties: IssuesEstimatedHiddenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NewGameClicked implements BaseEvent {
  event_type = 'NewGameClicked';
}

export class OrderBy implements BaseEvent {
  event_type = 'OrderBy';

  constructor(
    public event_properties: OrderByProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PersonalVoteProvided implements BaseEvent {
  event_type = 'PersonalVoteProvided';

  constructor(
    public event_properties: PersonalVoteProvidedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReplayClicked implements BaseEvent {
  event_type = 'ReplayClicked';

  constructor(
    public event_properties: ReplayClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StartGameClicked implements BaseEvent {
  event_type = 'StartGameClicked';

  constructor(
    public event_properties: StartGameClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VoteEdited implements BaseEvent {
  event_type = 'VoteEdited';

  constructor(
    public event_properties: VoteEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * AsyncConfigChanged
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/AsyncConfigChanged)
   *
   * Global configuration -> **Async Mode Configuration** -> Save clicked after changes have been done
   *
   * Owner: Lex Kovalenko
   *
   * @param properties The event's properties (e.g. deck_type)
   * @param options Amplitude event options.
   */
  asyncConfigChanged(
    properties: AsyncConfigChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AsyncConfigChanged(properties), options);
  }

  /**
   * BacklogEdited
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/BacklogEdited)
   *
   * "Save changes" from "Configure backlog" (opened by clicking on Edit backlog") has been clicked
   *
   * Owner: Lex Kovalenko
   *
   * @param properties The event's properties (e.g. game_id)
   * @param options Amplitude event options.
   */
  backlogEdited(
    properties: BacklogEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BacklogEdited(properties), options);
  }

  /**
   * CardsRevealed
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/CardsRevealed)
   *
   * Personal estimation results have been revealed: game admin has clicked on the Reveal button during the personal estimation phase
   *
   * Owner: Lex Kovalenko
   *
   * @param properties The event's properties (e.g. game_id)
   * @param options Amplitude event options.
   */
  cardsRevealed(
    properties: CardsRevealedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CardsRevealed(properties), options);
  }

  /**
   * DashboardOpened
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/DashboardOpened)
   *
   * Planning Poker Dashboard - the home page - opened
   *
   * Owner: Lex Kovalenko
   *
   * @param properties The event's properties (e.g. project_id)
   * @param options Amplitude event options.
   */
  dashboardOpened(
    properties: DashboardOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DashboardOpened(properties), options);
  }

  /**
   * EditIssueClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/EditIssueClicked)
   *
   * "Edit issue" button has been clicked from inside the game.
   *
   * Owner: Lex Kovalenko
   *
   * @param properties The event's properties (e.g. game_id)
   * @param options Amplitude event options.
   */
  editIssueClicked(
    properties: EditIssueClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditIssueClicked(properties), options);
  }

  /**
   * EstimateTabClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/EstimateTabClicked)
   *
   * Type of action on the Estimate (Async) tab from the issue details view.
   *
   * Owner: Lex Kovalenko
   *
   * @param properties The event's properties (e.g. action_type)
   * @param options Amplitude event options.
   */
  estimateTabClicked(
    properties: EstimateTabClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EstimateTabClicked(properties), options);
  }

  /**
   * EstimateTabOpened
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/EstimateTabOpened)
   *
   * Planning Poker from Issue details -> Estimation (Async) tab opened.
   *
   * Owner: Lex Kovalenko
   *
   * @param properties The event's properties (e.g. issue_id)
   * @param options Amplitude event options.
   */
  estimateTabOpened(
    properties: EstimateTabOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new EstimateTabOpened(properties), options);
  }

  /**
   * FinalEstimateSaved
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/FinalEstimateSaved)
   *
   * Final Estimate Saved by clicking on the save button by the game admin
   *
   * Owner: Lex Kovalenko
   *
   * @param properties The event's properties (e.g. active_participants)
   * @param options Amplitude event options.
   */
  finalEstimateSaved(
    properties: FinalEstimateSavedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FinalEstimateSaved(properties), options);
  }

  /**
   * GameCloned
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/GameCloned)
   *
   * The game has been created by using the clone button from the dashboard
   *
   * Owner: Lex Kovalenko
   *
   * @param properties The event's properties (e.g. admin_password_set)
   * @param options Amplitude event options.
   */
  gameCloned(
    properties: GameClonedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GameCloned(properties), options);
  }

  /**
   * GameEdited
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/GameEdited)
   *
   * "Save changes" from "Edit game" has been clicked
   *
   * Owner: Lex Kovalenko
   *
   * @param properties The event's properties (e.g. admin_password_set)
   * @param options Amplitude event options.
   */
  gameEdited(
    properties: GameEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GameEdited(properties), options);
  }

  /**
   * GameFinished
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/GameFinished)
   *
   * Finish game clicked + confirmation approved.
   *
   * Owner: Lex Kovalenko
   *
   * @param properties The event's properties (e.g. active_participants)
   * @param options Amplitude event options.
   */
  gameFinished(
    properties: GameFinishedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GameFinished(properties), options);
  }

  /**
   * GameJoined
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/GameJoined)
   *
   * A participant joins the game
   *
   * Owner: Lex Kovalenko
   *
   * @param properties The event's properties (e.g. game_id)
   * @param options Amplitude event options.
   */
  gameJoined(
    properties: GameJoinedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GameJoined(properties), options);
  }

  /**
   * GameRemoved
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/GameRemoved)
   *
   * Delete game clicked from the dashboard
   *
   * Owner: Lex Kovalenko
   *
   * @param properties The event's properties (e.g. game_id)
   * @param options Amplitude event options.
   */
  gameRemoved(
    properties: GameRemovedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GameRemoved(properties), options);
  }

  /**
   * GameReopened
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/GameReopened)
   *
   * "Reopen game" clicked after finishing the game
   *
   * Owner: Lex Kovalenko
   *
   * @param properties The event's properties (e.g. game_id)
   * @param options Amplitude event options.
   */
  gameReopened(
    properties: GameReopenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GameReopened(properties), options);
  }

  /**
   * GlobalConfigChanged
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/GlobalConfigChanged)
   *
   * Global configuration -> Configuration -> Save clicked after changes have been done
   *
   * Owner: Lex Kovalenko
   *
   * @param properties The event's properties (e.g. allow_change_url)
   * @param options Amplitude event options.
   */
  globalConfigChanged(
    properties: GlobalConfigChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GlobalConfigChanged(properties), options);
  }

  /**
   * GlobalConfigOpened
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/GlobalConfigOpened)
   *
   * global configuration page has loaded after clicking on the "Configure" from the apps management page
   *
   * Owner: Lex Kovalenko
   *
   * @param properties The event's properties (e.g. allow_change_url)
   * @param options Amplitude event options.
   */
  globalConfigOpened(
    properties: GlobalConfigOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GlobalConfigOpened(properties), options);
  }

  /**
   * IssueQuickAdded
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/IssueQuickAdded)
   *
   * "Quick add" button has been clicked or input has been submited
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  issueQuickAdded(
    properties: IssueQuickAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new IssueQuickAdded(properties), options);
  }

  /**
   * IssuesAdded
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/IssuesAdded)
   *
   * The number of issues added to the game during the game creation process
   *
   * @param properties The event's properties (e.g. game_id)
   * @param options Amplitude event options.
   */
  issuesAdded(
    properties: IssuesAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new IssuesAdded(properties), options);
  }

  /**
   * IssueSelected
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/IssueSelected)
   *
   * Issue has been selected for estimation by a game admin
   *
   * Owner: Lex Kovalenko
   *
   * @param properties The event's properties (e.g. game_id)
   * @param options Amplitude event options.
   */
  issueSelected(
    properties: IssueSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new IssueSelected(properties), options);
  }

  /**
   * IssuesEstimatedHidden
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/IssuesEstimatedHidden)
   *
   * **Hide issues estimated during this game was toggled inside the session.**
   *
   * @param properties The event's properties (e.g. estimated_hidden)
   * @param options Amplitude event options.
   */
  issuesEstimatedHidden(
    properties: IssuesEstimatedHiddenProperties,
    options?: EventOptions,
  ) {
    return this.track(new IssuesEstimatedHidden(properties), options);
  }

  /**
   * NewGameClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/NewGameClicked)
   *
   * User clicks on New game button from dashboard - the sign up moment of AJM
   *
   * Owner: Lex Kovalenko
   *
   * @param options Amplitude event options.
   */
  newGameClicked(
    options?: EventOptions,
  ) {
    return this.track(new NewGameClicked(), options);
  }

  /**
   * OrderBy
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/OrderBy)
   *
   * Event triggered when user clicks on the 'order by' in the filtering mode in the add issues to the game backlog
   *
   * @param properties The event's properties (e.g. data_type)
   * @param options Amplitude event options.
   */
  orderBy(
    properties: OrderByProperties,
    options?: EventOptions,
  ) {
    return this.track(new OrderBy(properties), options);
  }

  /**
   * PersonalVoteProvided
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/PersonalVoteProvided)
   *
   * Game participant has clicked on an estimation card during the personal estimation phase
   *
   * Owner: Lex Kovalenko
   *
   * @param properties The event's properties (e.g. estimation_value)
   * @param options Amplitude event options.
   */
  personalVoteProvided(
    properties: PersonalVoteProvidedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PersonalVoteProvided(properties), options);
  }

  /**
   * ReplayClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/ReplayClicked)
   *
   * Personal estimation results have been reset: game admin has clicked on the Replay button during the final estimation phase
   *
   * Owner: Lex Kovalenko
   *
   * @param properties The event's properties (e.g. game_id)
   * @param options Amplitude event options.
   */
  replayClicked(
    properties: ReplayClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReplayClicked(properties), options);
  }

  /**
   * StartGameClicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/StartGameClicked)
   *
   * The game has been created after clicking on the "Next: Start game" from the game creation flow.
   *
   * Owner: Lex Kovalenko
   *
   * @param properties The event's properties (e.g. admin_password_set)
   * @param options Amplitude event options.
   */
  startGameClicked(
    properties: StartGameClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new StartGameClicked(properties), options);
  }

  /**
   * VoteEdited
   *
   * [View in Tracking Plan](https://data.amplitude.com/appfire/Planning%20Poker%20Cloud/events/main/latest/VoteEdited)
   *
   * A new estimate has been selected after "Change estimate" button was clicked during the final estimation phase.
   *
   * Owner: Lex Kovalenko
   *
   * @param properties The event's properties (e.g. estimation_value)
   * @param options Amplitude event options.
   */
  voteEdited(
    properties: VoteEditedProperties,
    options?: EventOptions,
  ) {
    return this.track(new VoteEdited(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
