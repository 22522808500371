// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-page-dialog-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 24px;
    position: fixed;
    left: 0;
    top: 0;
    background: var(--ds-surface, #fff);
    justify-content: space-between;
    overflow: auto;
}

.header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.controls-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 1 auto;
    background: var(--ds-surface, #fff);
}

.content-container {
    width: 100%;
    flex: 1 1 auto;
    min-height: 0;
    display: flex;
    flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/FullPageDialog/full-page-dialog.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,iBAAiB;IACjB,eAAe;IACf,OAAO;IACP,MAAM;IACN,mCAAmC;IACnC,8BAA8B;IAC9B,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,cAAc;IACd,mCAAmC;AACvC;;AAEA;IACI,WAAW;IACX,cAAc;IACd,aAAa;IACb,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".full-page-dialog-container {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    padding-top: 24px;\n    position: fixed;\n    left: 0;\n    top: 0;\n    background: var(--ds-surface, #fff);\n    justify-content: space-between;\n    overflow: auto;\n}\n\n.header-container {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 16px;\n}\n\n.controls-container {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    flex: 0 1 auto;\n    background: var(--ds-surface, #fff);\n}\n\n.content-container {\n    width: 100%;\n    flex: 1 1 auto;\n    min-height: 0;\n    display: flex;\n    flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
