import { useCallback } from "react";

import { useGameData } from "../../../components/game/GameProvider";
import { useIsCurrentUserSpectator } from "../../../components/game/ParticipantsPanel/hooks/useIsCurrentUserSpectator";
import { FinalEstimateSaved, FinalEstimateSavedProperties } from "../../ampli";
import { getActiveParticipantsCount } from "../../utils";
import { useRecordEvent } from "./useRecordEvent";

export function useFinalEstimateSavedEvent() {
  const { game, gameId, isGameAdmin } = useGameData();
  const recordEvent = useRecordEvent(FinalEstimateSaved);
  const isCurrentUserSpectator = useIsCurrentUserSpectator();

  return useCallback(
    (props: Pick<FinalEstimateSavedProperties, "issue_id" | "estimation_value">) =>
      recordEvent({
        active_participants: getActiveParticipantsCount(game),
        game_id: gameId,
        is_game_admin: isGameAdmin,
        is_spectator: isCurrentUserSpectator,
        ...props,
      }),
    [game, gameId, isCurrentUserSpectator, isGameAdmin, recordEvent],
  );
}
