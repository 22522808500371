import { useCallback } from "react";

import { useApplicationContext } from "../../../../providers/ApplicationContextProvider";
import { useUpdateParticipant } from "../../../../services/firebase";
import { useGameData } from "../../GameProvider";
import { useIsCurrentUserSpectator } from "./useIsCurrentUserSpectator";

export function useToggleRole() {
  const { gameId } = useGameData();
  const { userAccountId } = useApplicationContext();
  const updateUserRole = useUpdateParticipant(gameId);
  const isCurrentUserSpectator = useIsCurrentUserSpectator();

  return useCallback(
    () => updateUserRole(userAccountId, { isSpectator: !isCurrentUserSpectator }),
    [isCurrentUserSpectator, updateUserRole, userAccountId],
  );
}
