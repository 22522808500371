import React, { createContext, useCallback, useContext, useMemo, useState } from "react";

import { getEpicsInBatches, getIssuesByIds } from "../services/jira-api";
import { EpicDetails, JiraField, JiraIssue } from "../types";
import { hasEpic, ISSUE_COLOR_FIELD_SCHEMA, normalizeBy } from "../utils";

interface EpicsDetailsStoreContextData {
  epics: Record<string, EpicDetails>;
  loadMissingEpics: (issues: JiraIssue[], fields: JiraField[]) => Promise<void>;
}

const EpicsDetailsStoreContext = createContext<EpicsDetailsStoreContextData | null>(null);

interface EpicsDetailsStoreContextProviderProps {
  children?: React.ReactNode;
}

function mapJiraIssueToEpicDetails(issue: JiraIssue, issueColorFieldId?: string): EpicDetails {
  return {
    id: Number(issue.id),
    name: issue.fields.summary,
    summary: issue.fields.summary,
    color: {
      key: (issue.fields[issueColorFieldId ?? ""] ?? "purple") as string,
    },
  };
}

export const EpicsDetailsStoreContextProvider = ({ children }: EpicsDetailsStoreContextProviderProps) => {
  const [epics, setEpics] = useState<Record<string, EpicDetails>>({});

  const loadMissingEpics = useCallback(
    async (issues: JiraIssue[], fields: JiraField[]) => {
      const issueColorField = fields.find((f) => f.schema?.custom === ISSUE_COLOR_FIELD_SCHEMA);
      const { nextGenEpicsIds, classicEpicsIds } = issues.reduce<{
        nextGenEpicsIds: string[];
        classicEpicsIds: string[];
      }>(
        (acc, curr) => {
          if (!hasEpic(curr) || epics[curr.fields.parent?.id as string]) return acc;
          if (curr.fields.project?.simplified) acc.nextGenEpicsIds.push(curr.fields.parent?.id as string);
          else acc.classicEpicsIds.push(curr.fields.parent?.id as string);
          return acc;
        },
        { nextGenEpicsIds: [], classicEpicsIds: [] },
      );
      const missingNextGenEpics = (
        await getIssuesByIds([...new Set(nextGenEpicsIds)], issueColorField ? [issueColorField?.id] : [])
      ).map((e) => mapJiraIssueToEpicDetails(e, issueColorField?.id));
      const missingClassicEpics = await getEpicsInBatches([...new Set(classicEpicsIds)]);
      const normalizedEpics = normalizeBy<EpicDetails, "id">([...missingNextGenEpics, ...missingClassicEpics], "id");
      setEpics((prev) => ({ ...prev, ...normalizedEpics }));
    },
    [epics],
  );

  const store = useMemo(
    () => ({
      epics,
      loadMissingEpics,
    }),
    [epics, loadMissingEpics],
  );
  return <EpicsDetailsStoreContext.Provider value={store}>{children}</EpicsDetailsStoreContext.Provider>;
};

export function useEpicsDetails() {
  const context = useContext(EpicsDetailsStoreContext);
  if (!context) {
    throw new Error("Epics details store context not initialized, probably context provider is missing");
  }
  return context;
}
