import React, { useMemo } from "react";
import styled from "styled-components";

import { IconButton } from "@atlaskit/button/new";
import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import MoreIcon from "@atlaskit/icon/glyph/more";
import Lozenge from "@atlaskit/lozenge";
import Skeleton from "@atlaskit/skeleton";

import { useRemoveParticipant, useUpdateParticipant } from "../../../services/firebase";
import { GameParticipant, JiraUser } from "../../../types";
import { didUserVote, isGameAdmin } from "../../../utils/game";
import { AvatarWithIcon } from "../../shared/UserAvatar/AvatarWithIcon";
import { useGameData } from "../GameProvider";
import { expandTransitionStyles } from "./ExpandTransition";

interface ParticipantDetailsProps {
  participant: GameParticipant;
  isExpanded: boolean;
  participantDetails?: JiraUser;
}

const FlexCenteredRow = styled.div`
  display: flex;
  align-items: center;
`;

const ParticipantRowContainer = styled(FlexCenteredRow)<{ $isExpanded: boolean }>`
  padding: 4px 0;
  width: 100%;
  justify-content: ${(props) => (props.$isExpanded ? "space-between" : "center")};
`;

const ParticipantDetailsContainer = styled(FlexCenteredRow)<{ $isOnline: boolean }>`
  gap: 8px;
  white-space: nowrap;
  opacity: ${(props) => (props.$isOnline ? "1" : "0.6")};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TransitionWrapper = styled.div<{ $isExpanded: boolean }>`
  ${expandTransitionStyles}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AdminActionsContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const ADMIN_BORDER_COLOR = "#6E5DC6";

function AdminCrownIcon() {
  return <img src="/images/crown_icon.svg" alt="Admin" width={14} height={14} />;
}

function ParticipantLoader({ isExpanded }: { isExpanded: boolean }) {
  return (
    <ParticipantRowContainer $isExpanded={isExpanded}>
      <ParticipantDetailsContainer $isOnline>
        <Skeleton width="36px" height="39px" borderRadius="50%" isShimmering />
        {isExpanded && <Skeleton width="125px" height="20px" isShimmering borderRadius="3px" />}
      </ParticipantDetailsContainer>
    </ParticipantRowContainer>
  );
}

export function ParticipantDetails({ participantDetails, isExpanded, participant }: Readonly<ParticipantDetailsProps>) {
  const { game, gameId, isGameAdmin: isCurrentUserGameAdmin } = useGameData();
  const updateParticipant = useUpdateParticipant(gameId);
  const removeParticipant = useRemoveParticipant(gameId);

  const isDisplayedUserGameAdmin = useMemo(
    () => isGameAdmin(participant.accountId, game.configuration.admins),
    [game.configuration.admins, participant.accountId],
  );

  const hasUserVoted = useMemo(
    () => didUserVote(game.activeItemId, game.voting, participant.accountId),
    [game.activeItemId, game.voting, participant.accountId],
  );

  const isUserOnline = Boolean(participant.instances);

  const userAdditionalInfo = useMemo(() => {
    const additionalInfoValues = [];
    if (isDisplayedUserGameAdmin) additionalInfoValues.push("Game admin");
    if (!isUserOnline) additionalInfoValues.push("Offline");
    return additionalInfoValues.length ? ": " + additionalInfoValues.join(", ") : "";
  }, [isDisplayedUserGameAdmin, isUserOnline]);

  if (!participantDetails) {
    return <ParticipantLoader isExpanded={isExpanded} />;
  }

  const onSpectatorSwitch = () =>
    void updateParticipant(participant.accountId, { isSpectator: !participant.isSpectator });

  const onRemoveClick = () => void removeParticipant(participant.accountId);

  const tooltipContent = `${participantDetails.displayName}${userAdditionalInfo}`;

  return (
    <ParticipantRowContainer $isExpanded={isExpanded}>
      <ParticipantDetailsContainer $isOnline={isUserOnline} data-private>
        <AvatarWithIcon
          src={participantDetails.avatarUrls["48x48"]}
          size="medium"
          borderColor={isDisplayedUserGameAdmin ? ADMIN_BORDER_COLOR : undefined}
          icon={isDisplayedUserGameAdmin && !hasUserVoted ? <AdminCrownIcon /> : undefined}
          status={hasUserVoted ? "approved" : undefined}
          tooltipContent={tooltipContent}
        />
        <TransitionWrapper $isExpanded={isExpanded}>
          {isExpanded && <span>{participantDetails.displayName}</span>}
        </TransitionWrapper>
      </ParticipantDetailsContainer>
      {isExpanded && (
        <AdminActionsContainer>
          {isDisplayedUserGameAdmin && <Lozenge appearance="new">ADMIN</Lozenge>}
          {isCurrentUserGameAdmin && (
            <DropdownMenu<HTMLButtonElement>
              trigger={({ triggerRef, ...props }) => (
                <IconButton {...props} icon={MoreIcon} label="admin actions" ref={triggerRef} appearance="subtle" />
              )}
              shouldRenderToParent
            >
              <DropdownItemGroup>
                <DropdownItem onClick={onSpectatorSwitch}>
                  Switch to {participant.isSpectator ? "estimator" : "spectator"}
                </DropdownItem>
                {game.creator !== participant.accountId && (
                  <DropdownItem onClick={onRemoveClick}>Remove from game</DropdownItem>
                )}
              </DropdownItemGroup>
            </DropdownMenu>
          )}
        </AdminActionsContainer>
      )}
    </ParticipantRowContainer>
  );
}
