import React, { ReactElement, useMemo } from "react";

import { Box } from "@fuegokit/react";

import { GameParticipant, JiraUser } from "../../../types";
import { Divider } from "../../shared/Divider";
import { GroupHeader } from "./GroupHeader";
import { ParticipantsList } from "./ParticipantsList";

interface EstimatorsProps {
  isExpanded: boolean;
  usersDetails: Record<string, JiraUser>;
  participants: GameParticipant[];
  groupIcon: ReactElement;
  groupName: string;
  actionButton?: ReactElement;
}

export function ParticipantsGroup({
  isExpanded,
  usersDetails,
  participants,
  groupIcon,
  groupName,
  actionButton,
}: Readonly<EstimatorsProps>) {
  const onlineParticipantsCount = useMemo(
    () => participants.filter((participant) => participant.instances).length,
    [participants],
  );

  return (
    <Box paddingX="8px">
      <GroupHeader
        isExpanded={isExpanded}
        groupCount={onlineParticipantsCount}
        groupName={groupName}
        icon={groupIcon}
      />
      <Divider />
      <Box width="100%" display="flex" justifyContent={isExpanded ? "flex-start" : "center"}>
        {actionButton}
      </Box>
      <ParticipantsList participants={participants} usersDetails={usersDetails} isExpanded={isExpanded} />
    </Box>
  );
}
