import React, { Component, ErrorInfo } from "react";
import { NavigateFunction } from "react-router-dom";

import { showFlag } from "../../services/jira-api";

interface Props {
  children: React.ReactNode;
  navigate: NavigateFunction;
}

export const clearFirebaseCache = () => {
  const reactFirePreloadedObservables = window._reactFirePreloadedObservables;
  if (reactFirePreloadedObservables) {
    Array.from(reactFirePreloadedObservables.keys()).forEach(
      (key) => key.includes("database") && reactFirePreloadedObservables.delete(key),
    );
  }
};

export class ParticipantsPresenceErrorBoundary extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("ErrorBoundary caught an error: ", error, errorInfo);
    if (error.message.includes("Client doesn't have permission to access the desired data")) {
      this.props.navigate("/");
      showFlag(
        "You've been disconnected",
        "Either Planning Poker game was deleted, or you were removed from the participants list by the game admin",
        "error",
      );
      clearFirebaseCache(); // Mitigates problem with cached permission errors - https://github.com/FirebaseExtended/reactfire/discussions/228#discussioncomment-182830
    } else {
      throw error;
    }
  }

  render() {
    return this.props.children;
  }
}
