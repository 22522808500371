import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { AccessDeniedMessage } from "../components/shared/messages/AccessDeniedMessage";
import { getGamesForUser } from "../services/api";
import { getGamesForAdmin, useGamesMapRef } from "../services/firebase";
import { Game, GameWithId } from "../types";
import { findUserLastGame, getErrorStatus } from "../utils";
import { useApplicationContext } from "./ApplicationContextProvider";

interface FirebaseDataContextData {
  games: GameWithId[];
  isLoading: boolean;
  lastUserGame?: Game;
  getGames: () => Promise<void>;
}

const FirebaseDataContext = createContext<FirebaseDataContextData | null>(null);

interface FirebaseDataContextProviderProps {
  children?: React.ReactNode;
}

export const FirebaseDataContextProvider = ({ children }: FirebaseDataContextProviderProps) => {
  const { userAccountId, isJiraAdmin } = useApplicationContext();
  const [games, setGames] = useState<GameWithId[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const gamesRef = useGamesMapRef();
  const [error, setError] = useState<unknown>();

  const getGames = useCallback(async () => {
    try {
      let games = [];
      if (isJiraAdmin) {
        games = await getGamesForAdmin(gamesRef);
      } else {
        games = await getGamesForUser();
      }
      setGames(games);
    } catch (error) {
      setError(error);
    }
  }, [isJiraAdmin, gamesRef]);

  useEffect(() => {
    void (async () => {
      try {
        setIsLoading(true);
        await getGames();
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [getGames]);

  const value = useMemo(
    () => ({
      games,
      isLoading: isLoading,
      lastUserGame: findUserLastGame(games, userAccountId),
      getGames,
    }),
    [games, isLoading, userAccountId, getGames],
  );

  if (error && getErrorStatus(error) === 403) {
    return <AccessDeniedMessage />;
  }

  return <FirebaseDataContext.Provider value={value}>{children}</FirebaseDataContext.Provider>;
};

export function useFirebaseData() {
  const context = useContext(FirebaseDataContext);
  if (!context) {
    throw new Error("Firebase data context not initialized, probably context provider is missing");
  }
  return context;
}
