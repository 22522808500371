import React from "react";

import Tooltip from "@atlaskit/tooltip";
import { Box } from "@fuegokit/react";

interface IconCellProps {
  url?: string;
  tooltip?: string;
}

export const IconCellMemoized = React.memo(
  function IconCell({ url, tooltip }: Readonly<IconCellProps>) {
    if (!url) return null;
    return (
      <Tooltip content={tooltip ? <span data-private>{tooltip}</span> : undefined}>
        <Box height="100%" display="flex" alignItems="center">
          <img src={url} width="16px" alt="" data-private />
        </Box>
      </Tooltip>
    );
  },
  function (prev, next) {
    return prev.url === next.url;
  },
);
