import { JiraIssue } from "../types";

export const getEpicColorAndBackgroundColor = (epicColor: string) => {
  switch (epicColor) {
    case "dark_purple":
    case "color_8":
      return ["var(--ds-text-inverse, #FFFFFF)", "var(--ds-background-accent-purple-bolder, #5243AA)"];
    case "blue":
    case "color_10":
      return ["var(--ds-text-accent-blue, #0747A6)", "var(--ds-background-accent-blue-subtler, #DEEBFF)"];
    case "dark_blue":
    case "color_4":
      return ["var(--ds-text-inverse, #FFFFFF)", "var(--ds-background-accent-blue-bolder, #0052CC)"];
    case "green":
    case "color_6":
      return ["var(--ds-text-accent-green, #006644)", "var(--ds-background-accent-green-subtler, #57D9A3)"];
    case "dark_green":
    case "color_13":
      return ["var(--ds-text-inverse, #FFFFFF)", "var(--ds-background-accent-green-bolder, #00875A)"];
    case "teal":
    case "color_11":
      return ["var(--ds-text-accent-teal, #008DA6)", "var(--ds-background-accent-teal-subtler, #E6FCFF)"];
    case "dark_teal":
    case "color_5":
      return ["var(--ds-text-inverse, #FFFFFF)", "var(--ds-background-accent-teal-bolder, #00A3BF)"];
    case "yellow":
    case "color_3":
      return ["var(--ds-text-accent-yellow, #FF8B00)", "var(--ds-background-accent-yellow-subtler, #FFFAE6)"];
    case "dark_yellow":
    case "color_2":
      return ["var(--ds-text-inverse, #FFFFFF)", "var(--ds-background-accent-orange-bolder, #FF991F)"];
    case "orange":
    case "color_9":
      return ["var(--ds-text-accent-red, #BF2600)", "var(--ds-background-accent-red-subtler, #FF7452)"];
    case "dark_orange":
    case "color_14":
      return ["var(--ds-text-inverse, #FFFFFF)", "var(--ds-background-accent-red-bolder, #DE350B)"];
    case "grey":
    case "color_12":
      return ["var(--ds-text, #172B4D)", "var(--ds-background-accent-gray-subtler, #EBECF0)"];
    case "dark_grey":
    case "color_1":
      return ["var(--ds-text-inverse, #FFFFFF)", "var(--ds-background-accent-gray-bolder, #253858)"];
    default:
      return ["var(--ds-text-accent-purple, #403294)", "var(--ds-background-accent-purple-subtler, #8777D9)"];
  }
};

export const getIssueStatusColorAndBackgroundColor = (issue: JiraIssue) => {
  switch (issue.fields.status?.statusCategory.key) {
    case "indeterminate":
      return ["var(--ds-text-information,#0747A6)", "var(--ds-background-information,#DEEBFF)"];
    case "done":
      return ["var(--ds-text-success,#006644)", "var(--ds-background-success,#E3FCEF)"];
    default:
      return ["var(--ds-text,#42526E)", "var(--ds-background-neutral,#DFE1E6)"];
  }
};
