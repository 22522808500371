import { useCallback, useEffect, useMemo, useState } from "react";

import { useBacklogStore } from "../../providers/BacklogStoreContextProvider";
import { getIssues } from "../../services/jira-api";
import { JiraIssue } from "../../types";

const BATCH_SIZE = 100;

export function useQueryItemsByJQL(columnsIds: string[], jql?: string) {
  const [issues, setIssues] = useState<JiraIssue[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const { backlogIssues } = useBacklogStore();

  useEffect(() => {
    setIssues([]);
    setPage(0);
  }, [jql]);

  useEffect(() => {
    void (async () => {
      if (jql) {
        try {
          setLoading(true);
          const { issues, total } = await getIssues(
            `jql=${encodeURIComponent(jql)}&maxResults=${BATCH_SIZE}&startAt=${BATCH_SIZE * page}&fields=${columnsIds.join(",")}`,
          );
          setTotal(total);
          setIssues((prev) => (page === 0 ? issues : [...prev, ...issues]));
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [page, jql, columnsIds]);

  const loadNextPage = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const updatedIssues = useMemo(() => {
    return issues.map((issue) => backlogIssues[issue.key] || issue);
  }, [issues, backlogIssues]);

  return {
    issues: updatedIssues,
    loading,
    loadNextPage,
    hasMore: updatedIssues.length < 1000 && updatedIssues.length < total,
    setIssues,
  };
}
