import { useCallback } from "react";

import { GameState } from "@appfire/poker-core";

import { useGetGameById } from "../../../services/firebase";
import { getErrorMessage } from "../../../utils";
import { GameRemoved } from "../../ampli";
import { useRecordEvent } from "./useRecordEvent";

export function useGameRemovedEvent() {
  const recordEvent = useRecordEvent(GameRemoved);
  const getGameById = useGetGameById();

  return useCallback(
    async (gameId: string) => {
      try {
        const game = await getGameById(gameId);
        recordEvent({
          game_id: gameId,
          game_state: game.state === GameState.ACTIVE ? "Active" : "Finished",
        });
      } catch (error) {
        console.error("Failed to send GameRemoved event " + getErrorMessage(error));
      }
    },
    [getGameById, recordEvent],
  );
}
