import React from "react";

import Tooltip from "@atlaskit/tooltip";
import { AfInfoIcon } from "@fuegokit/fuegoicons-react";

interface InfoTooltipProps {
  message: string;
}

export function InfoTooltip({ message }: Readonly<InfoTooltipProps>) {
  return (
    <Tooltip content={message}>
      <AfInfoIcon size={"small"} />
    </Tooltip>
  );
}
